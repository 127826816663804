import { userActionsTypes } from './actions';

const INITIAL_STATE = {
  user: null,
  countries: [],
  fromdData: [], //TODO  used for signup form. ADD new action for signup error, this action will store the form data to not write all over again
  specialities: [],
  medications: [],
  allergies: [],
  diseases: [],
  mpPreference: null,
  error: null,
  success: null,
  loading: false,
  patients: [],
  messageActiveDoctor: '',
  patient_anonymous: {},
  client: {},
};

const SESSION_INITIAL_STATE = {
  email: null,
  name: null,
  id: null,
  userTypes: null,
  availableUserTypes: null,
  accessToken: null,
  refreshToken: null,
  profilePic: null,
  slot: null,
  language: 'es',
};

const sessionReducer = (state = SESSION_INITIAL_STATE, actions) => {
  let new_state;
  switch (actions.type) {
    case userActionsTypes.SUCCESS_USER_LOGIN:
      new_state = {
        ...state,
        error: null,
        email: actions.payload.user.email,
        name: actions.payload.user.full_name,
        id: actions.payload.user.id,
        userType: actions.payload.user.user_types[0],
        slot: actions.payload.user.slot,
        availableUserTypes: actions.payload.user.user_types,
        accessToken: actions.payload.access,
        refreshToken: actions.payload.refresh,
        profilePic: actions.payload.user.profile_picture
          ? actions.payload.user.profile_picture.url
          : null,
      };
      break;
    case userActionsTypes.SUCCESS_USER_TOKEN_LOGIN:
      new_state = {
        ...state,
        error: null,
        email: actions.payload.user.email,
        name: actions.payload.user.full_name,
        id: actions.payload.user.id,
        userType: actions.payload.user.user_types[0],
        slot: actions.payload.user.slot,
        availableUserTypes: actions.payload.user.user_types,
        accessToken: actions.payload.access,
        refreshToken: actions.payload.refresh,
        profilePic: actions.payload.user.profile_picture
          ? actions.payload.user.profile_picture.url
          : null,
      };
      break;
    case userActionsTypes.SELECT_TYPE:
      new_state = {
        ...state,
        userType: actions.payload,
      };
      break;

    case userActionsTypes.SUCCESS_TOKEN_REFRESH:
      new_state = {
        ...state,
        accessToken: actions.payload.token.access,
      };
      break;
    case userActionsTypes.LOGOUT:
      new_state = SESSION_INITIAL_STATE;
      break;
    case userActionsTypes.SUCCESS_UPDATE_USER:
      new_state = {
        ...state,
        slot: actions.payload.data.slot,
      };
      break;
    case userActionsTypes.SUCCESS_UPDATE_USER_PIC:
      new_state = {
        ...state,
        profilePic: actions.payload.url,
      };
      break;
    case userActionsTypes.SET_LANGUAGE:
      new_state = {
        ...state,
        language: actions.payload.language,
      };
      break;
    default:
      new_state = state;
  }
  return new_state;
};

const userReducer = (state = INITIAL_STATE, actions) => {
  let new_state;
  switch (actions.type) {
    case userActionsTypes.SUCCESS_USER_LOGIN:
      new_state = {
        ...state,
        error: null,
        user: actions.payload.user,
      };
      break;
    // SUCCESS_USER_TOKEN_LOGIN
    case userActionsTypes.SUCCESS_USER_TOKEN_LOGIN:
      new_state = {
        ...state,
        error: null,
        user: actions.payload.user,
      };
      break;
    case userActionsTypes.SUCCESS_SIGNUP_DATA:
      new_state = {
        ...state,
        error: null,
        countries: actions.payload.countries,
        specialities: actions.payload.specialities,
      };
      break;
    case userActionsTypes.SUCCESS_SIGNUP_PATIENT:
      new_state = {
        ...state,
        error: null,
        success: actions.payload,
      };
      break;
    case userActionsTypes.SUCCESS_CREATE_PROFESSIONAL:
      new_state = {
        ...state,
        error: null,
        success: actions.payload,
      };
      break;
    case userActionsTypes.SUCCESS_UPDATE_USER:
      new_state = {
        ...state,
        error: null,
        success: actions.payload.message,
        user: actions.payload.data,
      };
      break;
    case userActionsTypes.SUCCESS_UPDATE_PASSWORD:
      new_state = {
        ...state,
        error: null,
        success: actions.payload,
      };
      break;
    case userActionsTypes.SUCCESS_CONFIRM_RESET_PASSWORD:
      new_state = {
        ...state,
        error: null,
        success: actions.payload,
      };
      break;
    case userActionsTypes.SUCCESS_GET_PASSWORD_RESET_TOKEN:
      new_state = {
        ...state,
        error: null,
        success: actions.payload,
      };
      break;
    case userActionsTypes.SUCCESS_CREATE_INFORMATION:
      new_state = {
        ...state,
        error: null,
        [actions.payload.type]: state[actions.payload.type].concat(
          actions.payload.value
        ),
        user: {
          ...state.user,
          [actions.payload.type]: state.user[actions.payload.type].concat(
            actions.payload.value
          ),
        },
      };
      break;
    case userActionsTypes.SUCCESS_ADD_INFORMATION:
      new_state = {
        ...state,
        error: null,
        user: {
          ...state.user,
          [actions.payload.type]: state.user[actions.payload.type].concat(
            actions.payload.value
          ),
        },
      };
      break;
    case userActionsTypes.SUCCESS_DELETE_INFORMATION:
      new_state = {
        ...state,
        error: null,
        user: {
          ...state.user,
          [actions.payload.type]: state.user[actions.payload.type].filter(
            (val) => val.id !== actions.payload.id
          ),
        },
      };
      break;
    case userActionsTypes.SUCCESS_CREATE_ADDRESS:
      new_state = {
        ...state,
        error: null,
        user: {
          ...state.user,
          address: state.user.address.concat(actions.payload.address),
        },
        success: actions.payload.message,
      };
      break;
    case userActionsTypes.SUCCESS_GET_USER_MP_PREFERENCE:
      new_state = {
        ...state,
        error: null,
        mpPreference: actions.payload,
      };
      break;
    case userActionsTypes.SUCCESS_DELETE_ADDRESS:
      new_state = {
        ...state,
        error: null,
        success: actions.payload.message,
        user: {
          ...state.user,
          address: state.user.address.filter(
            (val) => val.id !== actions.payload.address
          ),
        },
      };
      break;
    case userActionsTypes.SUCCESS_GET_USER:
      new_state = {
        ...state,
        error: null,
        countries: actions.payload.countries,
        specialities: actions.payload.specialities,
        medications: actions.payload.medications,
        allergies: actions.payload.allergies,
        diseases: actions.payload.diseases,
        user: {
          ...actions.payload.user,
          address: actions.payload.user.address || [],
        },
      };
      break;
    case userActionsTypes.SUCCESS_GET_USER_INFO:
      new_state = {
        ...state,
        error: null,
        medications: actions.payload.medications,
        allergies: actions.payload.allergies,
        diseases: actions.payload.diseases,
      };
      break;
    case userActionsTypes.DELETE_MESSAGES:
      new_state = {
        ...state,
        error: null,
        success: null,
        messageActiveDoctor: '',
      };
      break;
    case userActionsTypes.LOGOUT:
      new_state = INITIAL_STATE;
      break;
    case userActionsTypes.LOADING:
      new_state = {
        ...state,
        loading: actions.payload,
        error: actions.payload ? null : state.error,
      };
      break;
    case userActionsTypes.ERROR:
      new_state = {
        ...state,
        error: actions.payload,
      };
      break;
    case userActionsTypes.SUCCESS_TOKEN_REFRESH:
      new_state = {
        ...state,
        success: actions.payload.message,
      };
      break;
    case userActionsTypes.SUCCESS_GET_PATIENTS:
      let numOfPages = Math.ceil(actions.payload.count / 10);
      new_state = {
        ...state,
        patients: actions.payload.results,
        numOfPages: numOfPages,
      };
      break;
    case userActionsTypes.SUCCESS_UPDATE_ACTIVE_DOCTOR:
      new_state = {
        ...state,
        messageActiveDoctor: actions.payload,
      };
      break;
    case userActionsTypes.SUCCESS_GET_PATIENT_ANONYMOUS:
      new_state = {
        ...state,
        patient_anonymous: actions.payload.results[0],
      };
      break;
    case userActionsTypes.SUCCESS_CREATE_PATIENT_ANONYMOUS:
      new_state = {
        ...state,
        error: null,
        success: actions.payload.message,
        patient_anonymous: actions.payload.patient,
      };
      break;
    case userActionsTypes.SUCCESS_CREATE_INFORMATION_APPOINTMENT:
      new_state = {
        ...state,
        error: null,
        [actions.payload.type]: state[actions.payload.type].concat(
          actions.payload.value
        ),
      };
      break;
    case userActionsTypes.CLEAR_PATIENT_ANONYMOUS:
      new_state = {
        ...state,
        patient_anonymous: null,
      };
      break;
    case userActionsTypes.SUCCESS_GET_CLIENT:
      new_state = {
        ...state,
        client: actions.payload.results[0],
      };
      break;
    case userActionsTypes.SUCCESS_CREATE_CLIENT:
      new_state = {
        ...state,
        error: null,
        success: actions.payload.message,
        client: actions.payload.client,
      };
      break;
    case userActionsTypes.SUCCESS_GET_PETS_PATIENT:
      new_state = {
        ...state,
        petsPatient: actions.payload.results, // TODO check datas format
      };
      break;
    case userActionsTypes.CLEAR_CLIENT:
      new_state = {
        ...state,
        client: null,
      };
      break;
    case userActionsTypes.SUCCESS_UPDATE_USER_PIC:
      new_state = {
        ...state,
        user: {
          ...state.user,
          profile_picture: {
            ...state.user.profile_picture,
            url: actions.payload.url,
          },
        },
        success: actions.payload.message,
      };
      break;
    case userActionsTypes.SUCCESS_UPDATE_USER_SIGNATURE:
      new_state = {
        ...state,
        user: {
          ...state.user,
          signature_picture: {
            ...state.user.signature_picture,
            url: actions.payload.url,
          },
        },
        success: actions.payload.message,
      };
      break;
    case userActionsTypes.SUCCESS_UPDATE_DOCTORS_OPERATOR_ASSISTANT:
      new_state = {
        ...state,
        user: {
          ...state.user,
          doctors: state.user.doctors.map((doctor) =>
            doctor.id == actions.payload.doctor
              ? { ...doctor, address: [...doctor.address, actions.payload] }
              : doctor
          ),
        },
      };
      break;
    case userActionsTypes.SUCCESS_UPDATE_DOCTOR_ASSISTANT:
      new_state = {
        ...state,
        user: {
          ...state.user,
          doctors: state.user.doctors.map((doctor) =>
            doctor.id == actions.payload.data.id ? actions.payload.data : doctor
          ),
        },
      };
      break;
    case userActionsTypes.SUCCESS_DELETE_ADDRESS_DOCTOR:
      new_state = {
        ...state,
        user: {
          ...state.user,
          doctors: state.user.doctors.map((doctor) =>
            doctor.id == actions.payload.doctorId
              ? {
                  ...doctor,
                  address: doctor.address.filter(
                    (val) => val.id !== actions.payload.address
                  ),
                }
              : doctor
          ),
        },
      };
      break;
    default:
      new_state = state;
  }
  return new_state;
};

export { userReducer, sessionReducer };
