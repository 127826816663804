import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Grid, Box, Tabs, Tab } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PersonalData from './PersonalData';
import AccountData from './AccountData';
import { useTranslation } from 'react-i18next';

function AssistantProfile({
  updateUser,
  countries,
  specialities,
  createAddress,
  deleteAddress,
  updateProfilePic,
  changeUserPassword,
  user,
  validateEmail,
  isOlderThan18,
  classes,
  isProfileDoctor,
  session,
  doctors,
  isAssistant,
  deleteMessages,
}) {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const [t, i18n] = useTranslation('global');

  const TabPanel = ({ children, value, index, ...other }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={1}>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Grid item xs={12} md={12}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          style={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            variant="fullWidth"
            aria-label="full width tabs example"
            className={classes.profileTabs}
          >
            <Tab label={t('Profile.personal-data')} {...a11yProps(0)} />
            <Tab label={t('Profile.account-data')} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
          className={classes.swipeableContainer}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <PersonalData
              classes={classes}
              validateEmail={validateEmail}
              isOlderThan18={isOlderThan18}
              user={user}
              countries={countries}
              specialities={specialities}
              updateUser={updateUser}
              createAddress={createAddress}
              deleteAddress={deleteAddress}
              updateProfilePic={updateProfilePic}
              isProfileDoctor={isProfileDoctor}
              session={session}
              doctors={doctors}
              isPatient={session.userType == 'patient'}
              deleteMessages={deleteMessages}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <AccountData
              classes={classes}
              user={user}
              changeUserPassword={changeUserPassword}
              session={session}
              doctors={doctors}
              isProfileDoctor={isProfileDoctor}
              isAssistant={isAssistant}
            />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </Grid>
  );
}

export default AssistantProfile;
