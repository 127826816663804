import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Avatar } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { StarBorder } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  avatar: {
    border: `solid 2px ${theme.palette.lightPrimary.main}`,
    marginRight: '1rem',
    width: '5rem',
    height: '5rem',
  },
  name: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '15px',
    color: theme.palette.primary.main,
  },
  description: {
    color: 'grey',
    marginTop: '5px',
    fontWeight: 300,
    fontSize: '14px',
  },
}));

function DoctorInfo({ doctor }) {
  const classes = useStyles();
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Avatar
        alt='Foto de perfil'
        src={doctor.profile_picture ? doctor.profile_picture.url : null}
        className={classes.avatar}
      />
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Rating
          name='customized-empty'
          value={doctor.score || 0}
          precision={0.1}
          emptyIcon={<StarBorder fontSize='inherit' />}
          readOnly
          style={{ marginLeft: '-4px' }}
        />
        <span className={classes.name}>{doctor.full_name}</span>
        {doctor.specialty && (
          <span
            className={classes.description}
            style={{ fontWeight: 'bold', color: 'black' }}
          >
            {doctor.specialty.name}
          </span>
        )}
        {doctor.city && doctor.country && (
          <span
            className={classes.description}
          >{`${doctor.city}, ${doctor.country.name}`}</span>
        )}
      </Box>
    </Box>
  );
}

export default DoctorInfo;
