import moment from 'moment';

const isValidPassword = (password) => {
  let passError = false;
  const strongRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

  if (strongRegex.test(password)) {
    passError = true;
  }
  return passError;
};

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const isOlderThan18 = (dateOfBirth) => {
  const today = moment();
  const diff = today.diff(moment(dateOfBirth), 'years');
  return diff >= 18;
};

export { isValidPassword, validateEmail, isOlderThan18 };
