export const userActionsTypes = {
  USER_LOGIN: 'USER_LOGIN',
  USER_TOKEN_LOGIN: 'USER_TOKEN_LOGIN',
  SELECT_TYPE: 'SELECT_TYPE',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  GET_SIGNUP_DATA: 'GET_SIGNUP_DATA',
  USER_SIGNUP: 'USER_SIGNUP',
  GET_USER: 'GET_USER',
  GET_USER_INFO: 'GET_USER_INFO',
  GET_PATIENTS: 'GET_PATIENTS',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_PIC: 'UPDATE_USER_PIC',
  UPDATE_ADDRESS: 'UPDATE_ADDRESS',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  ADD_INFO_USER: 'ADD_INFO_USER',
  DELETE_INFO_USER: 'DELETE_INFO_USER',
  CREATE_INFORMATION: 'CREATE_INFORMATION',
  DELETE_MESSAGES: 'DELETE_MESSAGES',
  GET_USER_MP_PREFERENCE: 'GET_USER_MP_PREFERENCE',
  CONFIRM_RESET_PASSWORD: 'CONFIRM_RESET_PASSWORD',
  GET_PASSWORD_RESET_TOKEN: 'GET_PASSWORD_RESET_TOKEN',
  SUCCESS_USER_LOGIN: 'SUCCESS_USER_LOGIN',
  SUCCESS_USER_TOKEN_LOGIN: 'SUCCESS_USER_TOKEN_LOGIN',
  SUCCESS_SIGNUP_DATA: 'SUCCESS_SIGNUP_DATA',
  SUCCESS_CREATE_PROFESSIONAL: 'SUCCESS_CREATE_PROFESSIONAL',
  SUCCESS_CREATE_INFORMATION: 'SUCCESS_CREATE_INFORMATION',
  SUCCESS_ADD_INFORMATION: 'SUCCESS_ADD_INFORMATION',
  SUCCESS_DELETE_INFORMATION: 'SUCCESS_DELETE_INFORMATION',
  SUCCESS_CREATE_ADDRESS: 'SUCCESS_CREATE_ADDRESS',
  SUCCESS_DELETE_ADDRESS: 'SUCCESS_DELETE_ADDRESS',
  SUCCESS_SIGNUP_PATIENT: 'SUCCESS_SIGNUP_PATIENT',
  SUCCESS_GET_USER: 'SUCCESS_GET_USER',
  SUCCESS_GET_USER_INFO: 'SUCCESS_GET_USER_INFO',
  SUCCESS_UPDATE_USER: 'SUCCESS_UPDATE_USER',
  SUCCESS_UPDATE_PASSWORD: 'SUCCESS_UPDATE_PASSWORD',
  SUCCESS_TOKEN_REFRESH: 'SUCCESS_TOKEN_REFRESH',
  SUCCESS_GET_USER_MP_PREFERENCE: 'SUCCESS_GET_USER_MP_PREFERENCE',
  SUCCESS_GET_PATIENTS: 'SUCCESS_GET_PATIENTS',
  SUCCESS_GET_PASSWORD_RESET_TOKEN: 'SUCCESS_GET_PASSWORD_RESET_TOKEN',
  SUCCESS_CONFIRM_RESET_PASSWORD: 'SUCCESS_CONFIRM_RESET_PASSWORD',
  LOGOUT: 'LOGOUT',
  ERROR: 'ERROR_USER',
  LOADING: 'LOADING_USER',
  CLEAR_USER: 'CLEAR_USER',
  SUCCESS_UPDATE_ACTIVE_DOCTOR: 'SUCCESS_UPDATE_ACTIVE_DOCTOR',
  GET_PATIENT_ANONYMOUS: 'GET_PATIENT_ANONYMOUS',
  SUCCESS_GET_PATIENT_ANONYMOUS: 'SUCCESS_GET_PATIENT_ANONYMOUS',
  CREATE_PATIENT_ANONYMOUS: 'CREATE_PATIENT_ANONYMOUS',
  SUCCESS_CREATE_PATIENT_ANONYMOUS: 'SUCCESS_CREATE_PATIENT_ANONYMOUS',
  SUCCESS_CREATE_INFORMATION_APPOINTMENT:
    'SUCCESS_CREATE_INFORMATION_APPOINTMENT',
  CLEAR_PATIENT_ANONYMOUS: 'CLEAR_PATIENT_ANONYMOUS',
  GET_CLIENT: 'GET_CLIENT',
  SUCCESS_GET_CLIENT: 'SUCCESS_GET_CLIENT',
  CREATE_CLIENT: 'CREATE_CLIENT',
  SUCCESS_CREATE_CLIENT: 'SUCCESS_CREATE_CLIENT',
  GET_PETS_PATIENT: 'GET_PETS_PATIENT',
  SUCCESS_GET_PETS_PATIENT: 'SUCCESS_GET_PETS_PATIENT',
  CREATE_PETS_PATIENT: 'CREATE_PETS_PATIENT',
  UPDATE_PATIENT_ANONYMOUS: 'UPDATE_PATIENT_ANONYMOUS',
  UPDATE_CLIENT: 'UPDATE_CLIENT',
  CLEAR_CLIENT: 'CLEAR_CLIENT',
  UPDATE_USER_SIGNATURE: 'UPDATE_USER_SIGNATURE',
  SUCCESS_UPDATE_USER_PIC: 'SUCCESS_UPDATE_USER_PIC',
  SUCCESS_UPDATE_USER_SIGNATURE: 'SUCCESS_UPDATE_USER_SIGNATURE',
  SUCCESS_UPDATE_DOCTORS_OPERATOR_ASSISTANT:
    'SUCCESS_UPDATE_DOCTORS_OPERATOR_ASSISTANT',
  SUCCESS_UPDATE_DOCTOR_ASSISTANT: 'SUCCESS_UPDATE_DOCTOR_ASSISTANT',
  SUCCESS_DELETE_ADDRESS_DOCTOR: 'SUCCESS_DELETE_ADDRESS_DOCTOR',
};

export const userActions = {
  successLogin: (user) => ({
    type: userActionsTypes.SUCCESS_USER_LOGIN,
    payload: user,
  }),
  successTokenLogin: (user) => ({
    type: userActionsTypes.SUCCESS_USER_TOKEN_LOGIN,
    payload: user,
  }),
  successSignupPatient: (user) => ({
    type: userActionsTypes.SUCCESS_SIGNUP_PATIENT,
    payload: user,
  }),
  successCreateProfessional: (user) => ({
    type: userActionsTypes.SUCCESS_CREATE_PROFESSIONAL,
    payload: user,
  }),
  successCreateInformation: (data) => ({
    type: userActionsTypes.SUCCESS_CREATE_INFORMATION,
    payload: data,
  }),
  successAddInformation: (data) => ({
    type: userActionsTypes.SUCCESS_ADD_INFORMATION,
    payload: data,
  }),
  successDeleteInformation: (data) => ({
    type: userActionsTypes.SUCCESS_DELETE_INFORMATION,
    payload: data,
  }),
  successCreateAddress: (data) => ({
    type: userActionsTypes.SUCCESS_CREATE_ADDRESS,
    payload: data,
  }),
  successDeleteAddress: (data) => ({
    type: userActionsTypes.SUCCESS_DELETE_ADDRESS,
    payload: data,
  }),
  successGetSignupData: (data) => ({
    type: userActionsTypes.SUCCESS_SIGNUP_DATA,
    payload: data,
  }),
  successGetUser: (data) => ({
    type: userActionsTypes.SUCCESS_GET_USER,
    payload: data,
  }),
  successGetUserInfo: (data) => ({
    type: userActionsTypes.SUCCESS_GET_USER_INFO,
    payload: data,
  }),
  successUpdateUser: (body) => ({
    type: userActionsTypes.SUCCESS_UPDATE_USER,
    payload: body,
  }),
  successUpdatePassword: (message) => ({
    type: userActionsTypes.SUCCESS_UPDATE_PASSWORD,
    payload: message,
  }),
  successGetUserMpPreference: (data) => ({
    type: userActionsTypes.SUCCESS_GET_USER_MP_PREFERENCE,
    payload: data,
  }),
  successGetPasswordResetToken: (data) => ({
    type: userActionsTypes.SUCCESS_GET_PASSWORD_RESET_TOKEN,
    payload: data,
  }),
  successConfirmResetPassword: (data) => ({
    type: userActionsTypes.SUCCESS_CONFIRM_RESET_PASSWORD,
    payload: data,
  }),
  successTokenRefresh: (data) => ({
    type: userActionsTypes.SUCCESS_TOKEN_REFRESH,
    payload: data,
  }),

  logoutUser: () => ({
    type: userActionsTypes.LOGOUT,
  }),

  error: (message) => ({
    type: userActionsTypes.ERROR,
    payload: message,
  }),
  loading: (value) => ({
    type: userActionsTypes.LOADING,
    payload: value,
  }),
  successGetPatients: (patients) => ({
    type: userActionsTypes.SUCCESS_GET_PATIENTS,
    payload: patients,
  }),
  successUpdateActiveDoctor: (message) => ({
    type: userActionsTypes.SUCCESS_UPDATE_ACTIVE_DOCTOR,
    payload: message,
  }),
  successGetPatientAnonymous: (data) => ({
    type: userActionsTypes.SUCCESS_GET_PATIENT_ANONYMOUS,
    payload: data,
  }),
  successCreatePatientAnonymous: (data) => ({
    type: userActionsTypes.SUCCESS_CREATE_PATIENT_ANONYMOUS,
    payload: data,
  }),
  successCreateInformationAppointment: (data) => ({
    type: userActionsTypes.SUCCESS_CREATE_INFORMATION_APPOINTMENT,
    payload: data,
  }),
  successGetClient: (data) => ({
    type: userActionsTypes.SUCCESS_GET_CLIENT,
    payload: data,
  }),
  successCreateClient: (data) => ({
    type: userActionsTypes.SUCCESS_CREATE_CLIENT,
    payload: data,
  }),
  successGetPets: (data) => ({
    type: userActionsTypes.SUCCESS_GET_PETS_PATIENT,
    payload: data,
  }),
  successUpdateUserPic: (body) => ({
    type: userActionsTypes.SUCCESS_UPDATE_USER_PIC,
    payload: body,
  }),
  successUpdateUserSignature: (body) => ({
    type: userActionsTypes.SUCCESS_UPDATE_USER_SIGNATURE,
    payload: body,
  }),
  successUpdateDoctorsOperatorAssistant: (body) => ({
    type: userActionsTypes.SUCCESS_UPDATE_DOCTORS_OPERATOR_ASSISTANT,
    payload: body,
  }),
  successUpdateDoctorAssistant: (body) => ({
    type: userActionsTypes.SUCCESS_UPDATE_DOCTOR_ASSISTANT,
    payload: body,
  }),
};
