export const proficientsActionsTypes = {
  GET_PROFICIENTS: 'GET_PROFICIENTS',
  GET_PROFICIENT: 'GET_PROFICIENT',
  SUCCESS_GET_PROFICIENTS: 'SUCCESS_GET_PROFICIENTS',
  SUCCESS_GET_PROFICIENT: 'SUCCESS_GET_PROFICIENT',
  ERROR: 'ERROR_PROFICIENTS',
  LOADING: 'LOADING_PROFICIENTS',
  LOGOUT: 'LOGOUT',
  SUCCESS_UPDATE_PROFICIENT_ASSISTANT: 'SUCCESS_UPDATE_PROFICIENT_ASSISTANT',
  CLEAR_SUCCESS_MESSAGE: 'CLEAR_SUCCESS_MESSAGE',
  SET_PROFICIENT_BODY: 'SET_PROFICIENT_BODY',
  CLEAR_SELECT_PROFICIENT: 'CLEAR_SELECT_PROFICIENT',
};

export const proficientsActions = {
  successGetDoctors: (data) => ({
    type: proficientsActionsTypes.SUCCESS_GET_PROFICIENTS,
    payload: data,
  }),
  successGetDoctor: (data) => ({
    type: proficientsActionsTypes.SUCCESS_GET_PROFICIENT,
    payload: data,
  }),
  error: (message) => ({
    type: proficientsActionsTypes.ERROR,
    payload: message,
  }),
  loading: (value) => ({
    type: proficientsActionsTypes.LOADING,
    payload: value,
  }),
  successUpdateDoctorAssistant: (data) => ({
    type: proficientsActionsTypes.SUCCESS_UPDATE_PROFICIENT_ASSISTANT,
    payload: data,
  }),
};
