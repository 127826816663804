import axiosInstance from './axiosConfig';

// * * * Login / Sign Up * * *
const getCountries = () => {
  const url = `country/`;
  const response = axiosInstance({
    method: 'GET',
    url,
  });

  return response;
};

const getSpecilities = () => {
  const url = `specility/`;
  const response = axiosInstance({
    method: 'GET',
    url,
  });

  return response;
};

const login = (credentials) => {
  const url = `login/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    data: credentials,
  });

  return response;
};

const tokenLogin = (token) => {
  const url = `token_login/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    data: token,
  });

  return response;
};

const refreshToken = (token) => {
  const url = `token/refresh/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    data: token,
  });
  return response;
};

const signup = (type, body) => {
  const url = `${type}/account/signup/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    data: body,
  });

  return response;
};

const getUser = (type, id) => {
  const url = `${type}/${id}/`;
  const response = axiosInstance({
    method: 'GET',
    url,
  });

  return response;
};

const updateUser = (type, id, body, token) => {
  const url = `${type}/${id}/`;
  const response = axiosInstance({
    method: 'PATCH',
    url,
    data: body,
    needToken: token,
  });

  return response;
};

const uploadeProfilePicture = (body, token) => {
  const url = `user/upload_profile_picture/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    data: body,
    contentType: true,
    needToken: token,
  });

  return response;
};
const uploadeAppointmenFiles = (body, token) => {
  const url = `appointment/upload_files/?appointment=${body.appointmentId}`;
  const response = axiosInstance({
    method: 'POST',
    url,
    data: body.files,
    contentType: true,
    needToken: token,
  });

  return response;
};

const changePassword = (body, token) => {
  const url = `change_password/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    data: body,
    needToken: token,
  });

  return response;
};

const getInformation = (type, token) => {
  const url = `${type}/`;
  const response = axiosInstance({
    method: 'GET',
    needToken: token,
    url,
  });

  return response;
};

const createInformation = (type, body, token) => {
  const url = `${type}/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    needToken: token,
    data: body,
  });

  return response;
};

const addUserInformation = (type, body, token) => {
  const url = `patient/information/${type}/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    needToken: token,
    data: body,
  });

  return response;
};

const deleteUserInformation = (type, body, token) => {
  const url = `patient/information/${type}/`;
  const response = axiosInstance({
    method: 'DELETE',
    url,
    needToken: token,
    data: body,
  });

  return response;
};

const getDoctors = (urlParams, token) => {
  const url = urlParams ? `doctor/?${urlParams}` : `doctor/`;
  const response = axiosInstance({
    method: 'GET',
    needToken: token,
    url,
  });

  return response;
};

const getAppointments = (urlParams, token) => {
  const url = `appointment/full/?${urlParams}`;
  const response = axiosInstance({
    method: 'GET',
    needToken: token,
    url,
  });

  return response;
};
const getAppointmentsLight = (urlParams, token) => {
  const url = `appointment/light/?${urlParams}`;
  const response = axiosInstance({
    method: 'GET',
    needToken: token,
    url,
  });

  return response;
};

const getAppointmentsProficient = (urlParams, token) => {
  const url = `expertise/appointment/proficient/?${urlParams}`;
  const response = axiosInstance({
    method: 'GET',
    needToken: token,
    url,
  });

  return response;
};

const createAppointmentFull = (body, token) => {
  const url = `appointment/full/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    data: body,
    needToken: token,
  });

  return response;
};

const updateAppointmentFull = (body, appointmentId, token) => {
  const url = `appointment/full/${appointmentId}/`;
  const response = axiosInstance({
    method: 'PATCH',
    url,
    data: body,
    needToken: token,
  });

  return response;
};

const deleteAppointmentFull = (appointmentId, token) => {
  const url = `appointment/full/${appointmentId}`;
  const response = axiosInstance({
    method: 'DELETE',
    url,
    needToken: token,
  });

  return response;
};

const deleteAppointmentFile = (fileId, token) => {
  const url = `appointment/upload_files/${fileId}`;
  const response = axiosInstance({
    method: 'DELETE',
    url,
    needToken: token,
  });

  return response;
};

const manageAddresses = (id, type, body, token) => {
  let url = `information/address/`;
  if (id) {
    url += `${id}/`;
  }
  const response = axiosInstance({
    method: type,
    needToken: token,
    data: body,
    url,
  });

  return response;
};

const getScheduleRequests = (queryId, token) => {
  const url = `schedulerequest/${queryId}`;
  const response = axiosInstance({
    method: 'GET',
    needToken: token,
    url,
  });

  return response;
};

const getSchedulesByDate = (start, end, urlParams, token) => {
  const url = `schedule/?start__gt=${start}&start__lt=${end}${urlParams}`;
  const response = axiosInstance({
    method: 'GET',
    needToken: token,
    url,
  });

  return response;
};

const createScheduleRequests = (body, token) => {
  const url = `schedulerequest/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    needToken: token,
    data: body,
  });

  return response;
};

const updateScheduleRequests = (id, body, token) => {
  const url = `schedulerequest/${id}/`;
  const response = axiosInstance({
    method: 'PATCH',
    url,
    needToken: token,
    data: body,
  });

  return response;
};

const acceptScheduleRequest = (body, token) => {
  const url = `schedulerequest/accept_suggested/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    needToken: token,
    data: body,
  });

  return response;
};

const createSchedule = (body, multiple, token) => {
  const url = `schedule${multiple ? '/create_multiple_schedules/' : '/'}`;
  const response = axiosInstance({
    method: 'POST',
    url,
    needToken: token,
    data: body,
  });

  return response;
};

const deleteSchedule = (token, scheduleId, multiple) => {
  const url = `schedule/${!multiple ? scheduleId : 'delete_group'}/`;
  let response_body = null;
  if (multiple) {
    response_body = {
      method: 'DELETE',
      url,
      data: { schedule_group: scheduleId },
      needToken: token,
    };
  } else {
    response_body = {
      method: 'DELETE',
      url,
      needToken: token,
    };
  }

  const response = axiosInstance(response_body);

  return response;
};

const getMpPreference = (body, token) => {
  const url = `payments/process_payment?price=${body.price}&title=${body.title}&appointment=${body.appointment}`;
  const response = axiosInstance({
    method: 'GET',
    needToken: token,
    url,
  });
  return response;
};

const getPasswordResetToken = (body) => {
  // send you an email (to email specified in body) with a redirection link to change your password
  const url = `password-reset/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    needToken: false,
    data: body,
  });

  return response;
};

const confirmResetPassword = (body) => {
  // change your password to the new one you send it in body
  const url = `password-reset/confirm/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    needToken: false,
    data: body,
  });

  return response;
};

const getPatients = (urlParams, token) => {
  const url = `patient/${urlParams}`;
  const response = axiosInstance({
    method: 'GET',
    url,
    needToken: token,
  });

  return response;
};

const getPatientAnonymous = (id, token) => {
  const url = `patientanonymous/?identity_document=${id}`;
  const response = axiosInstance({
    method: 'GET',
    url,
    needToken: token,
  });

  return response;
};

const createPatientAnonymous = (body, token) => {
  const url = `patientanonymous/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    data: body,
    needToken: token,
  });

  return response;
};

const createAppointmentLight = (body, token) => {
  const url = `appointment/light/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    data: body,
    needToken: token,
  });

  return response;
};

const updateAppointmentLight = (id, body, token) => {
  const url = `appointment/light/${id}/`;
  const response = axiosInstance({
    method: 'PATCH',
    url,
    needToken: token,
    data: body,
  });

  return response;
};

const getClient = (id, token) => {
  const url = `expertise/client/?identity_document=${id}`;
  const response = axiosInstance({
    method: 'GET',
    url,
    needToken: token,
  });

  return response;
};

const createClient = (body, token) => {
  const url = `expertise/client/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    data: body,
    needToken: token,
  });

  return response;
};

const createAppointmentProficient = (type, body, token) => {
  const url = `expertise/appointment/${type}/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    data: body,
    needToken: token,
  });

  return response;
};

const getProficientDate = (start, end, proficientId, token) => {
  const url = `expertise/appointment/all/?date__gt=${start}&date__lt=${end}&proficient=${proficientId}`; //TODO add expertise in the beginning url
  const response = axiosInstance({
    method: 'GET',
    needToken: token,
    url,
  });

  return response;
};

const updateAppointmentProficient = (id, body, token) => {
  const url = `expertise/appointment/proficient/${id}/`;
  const response = axiosInstance({
    method: 'PATCH',
    url,
    needToken: token,
    data: body,
  });

  return response;
};

const getPetsPatient = (id, token) => {
  const url = `veterinarian/pet/?owner=${id}`;
  const response = axiosInstance({
    method: 'GET',
    url,
    needToken: token,
  });

  return response;
};

const createPetsPatient = (body, token) => {
  const url = `veterinarian/pet/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    data: body,
    needToken: token,
  });

  return response;
};

const resendCode = (id, token) => {
  const url = `appointment/resend_video_code/${id}/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    needToken: token,
  });

  return response;
};

const updatePatientAnonymous = (id, body, token) => {
  const url = `patientanonymous/${id}/`;
  const response = axiosInstance({
    method: 'PATCH',
    url,
    needToken: token,
    data: body,
  });

  return response;
};

const updateSignature = (body, token) => {
  const url = `doctor/upload_signature_picture/`;
  const response = axiosInstance({
    method: 'POST',
    url,
    data: body,
    contentType: true,
    needToken: token,
  });

  return response;
};

const manageAddressesByAssistant = (id, type, body, token) => {
  let url = `information/address/`;
  if (id) {
    url += `${id}/`;
  }
  const response = axiosInstance({
    method: type,
    needToken: token,
    data: body,
    url,
  });

  return response;
};

const updateClient = (id, body, token) => {
  const url = `expertise/client/${id}/`;
  const response = axiosInstance({
    method: 'PATCH',
    url,
    needToken: token,
    data: body,
  });

  return response;
};

const getProficients = (token) => {
  const url = `proficient/`;
  const response = axiosInstance({
    method: 'GET',
    url,
    needToken: token,
  });

  return response;
};
const getAppointmentsAll = (urlParams, token) => {
  const url = `appointment/all/?${urlParams}`;
  const response = axiosInstance({
    method: 'GET',
    needToken: token,
    url,
  });

  return response;
};

export default {
  login,
  tokenLogin,
  refreshToken,
  signup,
  updateUser,
  uploadeProfilePicture,
  changePassword,
  getCountries,
  getSpecilities,
  getUser,
  getInformation,
  createInformation,
  addUserInformation,
  deleteUserInformation,
  getDoctors,
  getAppointments,
  createAppointmentFull,
  manageAddresses,
  getScheduleRequests,
  createScheduleRequests,
  updateScheduleRequests,
  acceptScheduleRequest,
  createSchedule,
  getSchedulesByDate,
  deleteSchedule,
  updateAppointmentFull,
  deleteAppointmentFull,
  uploadeAppointmenFiles,
  deleteAppointmentFile,
  getMpPreference,
  getPatients,
  confirmResetPassword,
  getPasswordResetToken,
  getPatientAnonymous,
  getAppointmentsLight,
  createPatientAnonymous,
  createAppointmentLight,
  updateAppointmentLight,
  getClient,
  createClient,
  createAppointmentProficient,
  getAppointmentsProficient,
  getProficientDate,
  updateAppointmentProficient,
  getPetsPatient,
  createPetsPatient,
  resendCode,
  updatePatientAnonymous,
  updateSignature,
  manageAddressesByAssistant,
  updateClient,
  getProficients,
  getAppointmentsAll,
};
