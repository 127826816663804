import { Button, Grid, Paper } from '@material-ui/core';
import Header from '../../components/Header';
import Webcam from 'react-webcam';
import { useStyles } from './style';
import { connect } from 'react-redux';
import useMobile from '../../hooks/useMobile';
import { AudioVisualizer } from '../../components/AudioVisualizer';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/helpers';
import queryString from 'query-string';

const CallInstructions = ({ classes }) => {
  const [t, i18n] = useTranslation('global');

  return (
    <>
      <h2 className={classes.title}>{`${capitalize(t('word.test'))} ${t(
        'word.your'
      )} ${t('word.camera')} ${t('word.and')} ${t('word.audio')}`}</h2>
      <p>{t('sentence.videoCallTest')}</p>
      <p>{t('sentence.videoCallTest2')}</p>
    </>
  );
};

const VideoCallTest = ({ session }) => {
  const classes = useStyles();
  const isMobile = useMobile();
  const { appointmentId, expertise } = queryString.parse(
    window.location.search
  );
  return (
    <>
      <Header />
      {!isMobile ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.gridContainer}
        >
          <Grid item className={classes.gridItem}>
            <Paper>
              <div className={classes.paperContainer}>
                <div className={classes.videoAudioContainer}>
                  <div className={classes.videoContainer}>
                    <Webcam className={classes.video} audio={false} />

                    <AudioVisualizer />
                  </div>
                </div>

                <div className={classes.textContainer}>
                  <CallInstructions classes={classes} />

                  {!session.id && appointmentId && (
                    <div className={classes.buttonContainer}>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={() => {
                          let pageName =
                            process.env.REACT_APP_VIDEO_URL +
                            'submit_code/' +
                            appointmentId +
                            `${expertise ? '?expertise=' + expertise : ''}`;
                          window.location.href = pageName;
                        }}
                      >
                        IR A LA LLAMADA
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.gridContainer}
        >
          <div className={classes.containerMobile}>
            <div className={classes.paperContainerMobile}>
              <div className={classes.videoContainerMobile}>
                <Webcam className={classes.video} width={'100%'} />
              </div>
              <AudioVisualizer />
            </div>

            <div className={classes.textContainerMobile}>
              <CallInstructions classes={classes} />
            </div>
          </div>
        </Grid>
      )}
    </>
  );
};
const mapStateToProps = ({ session }) => {
  return {
    session,
  };
};

export default connect(mapStateToProps)(VideoCallTest);
