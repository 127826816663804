import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import HistoricAppointmentCard from '../../components/Cards/HistoricAppointmentCard';
import { appointmentActionsTypes } from '../../redux/appointment/actions';
import { isProfessional } from '../../utils/helpers';
import { useStyles } from './style';
import moment from 'moment';
import { SearchOutlined, SettingsBackupRestore } from '@material-ui/icons';
import DoctariSpinner from '../../components/Spinner';
import DoctariModal from '../../components/Modal';
import { useTranslation } from 'react-i18next';

function HistoricAppointments({
  session,
  appointmentsData,
  getAppointments,
  getAppointmentsLight,
  clearAppointments,
  getAppointmentsProficient,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [t, i18n] = useTranslation('global');
  const [state, setState] = useState({
    fromDate: null,
    toDate: null,
    personName: null,
    doctorName: null,
    currentPage: 1,
  });
  const { currentPage, toDate, fromDate, personName, doctorName } = state;
  const { appointments, numOfPages } = appointmentsData;
  const [appointmentsLoading, setAppointmentsLoading] = useState(true);
  const [prescriptionModal, setPrescriptionModal] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState({});

  const isProficient = session.userType == 'proficient';

  useEffect(() => {
    if (toDate || fromDate || personName || doctorName || currentPage) {
      searchAppointments();
    }
    return () => clearAppointments();
  }, [toDate, fromDate, personName, doctorName, currentPage]);

  useEffect(() => {
    if (appointments && appointments.length > 0) {
      setAppointmentsLoading(false);
    }
  }, [appointments]);

  const searchAppointments = () => {
    setAppointmentsLoading(true);
    let queryParams = [];
    let queryValues = [];
    if (currentPage > 1) {
      queryParams.push('page');
      queryValues.push(currentPage);
    }
    if (fromDate) {
      queryParams.push('date__gt');
      let fromDateFormatted = moment(fromDate)
        .set('hour', '00')
        .set('minute', '00')
        .tz('America/Montevideo')
        .format('YYYY-MM-DD HH:mm');
      queryValues.push(fromDateFormatted);
    }
    if (toDate) {
      queryParams.push('date__lt');
      let toDateFormatted = moment(toDate)
        .set('hour', '23')
        .set('minute', '59')
        .tz('America/Montevideo')
        .format('YYYY-MM-DD HH:mm');
      queryValues.push(toDateFormatted);
    }
    if (doctorName) {
      queryParams.push('doctor__full_name__icontains');
      queryValues.push(doctorName);
    }
    if (personName) {
      if (isProficient) {
        queryParams.push('client__full_name__icontains');
      } else {
        queryParams.push('patient__full_name__icontains');
      }
      queryValues.push(personName);
    }
    if (isProficient) {
      getAppointmentsProficient(
        session.userType,
        session.id,
        true,
        queryParams,
        queryValues
      );
    } else {
      getAppointmentsLight(
        session.userType,
        session.id,
        true, //isDone,
        queryParams,
        queryValues
      );
    }
  };

  return (
    <>
      <DoctariModal
        open={prescriptionModal}
        setOpen={(val) => setPrescriptionModal(val)}
        title={'Receta'}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ padding: '2rem', width: '100%' }}
        >
          <Grid item xs={12} md={12} className={classes.widthFieldModal}>
            <TextField
              id="standard-basic"
              label="Prescripción por Genérico"
              variant="standard"
              style={{ width: '100%' }}
              // className={styles.paperGridItems}
              InputProps={{
                readOnly: true,
              }}
              defaultValue={selectedPrescription.generic_drug || ''}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.widthFieldModal}>
            <TextField
              id="standard-basic"
              label="Forma Farmacéutica"
              variant="standard"
              style={{ width: '100%' }}
              // className={styles.paperGridItems}
              InputProps={{
                readOnly: true,
              }}
              defaultValue={selectedPrescription.pharmaceutical_form || ''}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.widthFieldModal}>
            <TextField
              id="standard-basic"
              label="Posología"
              variant="standard"
              style={{ width: '100%' }}
              // className={styles.paperGridItems}
              InputProps={{
                readOnly: true,
              }}
              defaultValue={selectedPrescription.posology || ''}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.widthFieldModal}>
            <TextField
              id="standard-basic"
              label="Vía de Administración"
              variant="standard"
              style={{ width: '100%' }}
              // className={styles.paperGridItems}
              InputProps={{
                readOnly: true,
              }}
              defaultValue={selectedPrescription.delivery_route || ''}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.widthFieldModal}>
            <TextField
              id="standard-basic"
              label="Concentración"
              variant="standard"
              style={{ width: '100%' }}
              // className={styles.paperGridItems}
              InputProps={{
                readOnly: true,
              }}
              defaultValue={selectedPrescription.concentration || ''}
            />
          </Grid>
        </Grid>
        {/* <Box
          style={{ display: 'flex', justifyContent: 'end', padding: '2rem' }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePatchAppointmentLight()}
          >
            {appointmentsData.loading ? 'Cargando...' : 'Aceptar'}
          </Button>
        </Box> */}
      </DoctariModal>
      <Grid container style={{ display: 'block', marginTop: '2rem' }}>
        <div className={classes.generalContainer}>
          <Grid className={classes.formContainer}>
            <span style={{ fontWeight: 400, fontSize: '24px' }}>
              {t('HistoricAppointments.history')}
            </span>
            {isProfessional(session.userType) || isProficient ? (
              <TextField
                className={classes.search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchOutlined />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    height: '46px',
                  },
                  classes: { underline: classes.underline },
                }}
                id="my-input"
                placeholder={
                  'Nombre de ' +
                  `${isProficient ? `${t('word.client')}` : 'paciente'}`
                }
                variant="standard"
                value={personName}
                autoComplete="off"
                onChange={(e) =>
                  setState({
                    ...state,
                    personName: e.target.value,
                    currentPage: 1,
                  })
                }
              />
            ) : (
              <TextField
                className={classes.search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchOutlined />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    height: '46px',
                  },
                  classes: { underline: classes.underline },
                }}
                id="my-input"
                placeholder={t('HistoricAppointments.doctor-name')}
                variant="standard"
                autoComplete="off"
                value={doctorName}
                onChange={(e) =>
                  setState({
                    ...state,
                    doctorName: e.target.value,
                    currentPage: 1,
                  })
                }
              />
            )}
            <Grid item className={classes.gridItem}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="fromDate"
                  label="Desde"
                  type="date"
                  value={fromDate}
                  onChange={(e) =>
                    setState({ ...state, fromDate: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item className={classes.gridItem}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="toDate"
                  label={t('HistoricAppointments.to')}
                  type="date"
                  value={toDate}
                  onChange={(e) =>
                    setState({
                      ...state,
                      toDate: e.target.value,
                      currentPage: 1,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid className={classes.buttonContainer}>
              <Button
                variant="contained"
                onClick={() =>
                  setState({
                    ...state,
                    toDate: '',
                    fromDate: '',
                    personName: '',
                    doctorName: '',
                    currentPage: 1,
                  })
                }
                style={{
                  color: 'white',
                  backgroundColor: '#666666',
                }}
              >
                <SettingsBackupRestore />
                Reiniciar
              </Button>
              <Button
                variant="contained"
                onClick={() => searchAppointments()}
                style={{
                  color: 'white',
                  backgroundColor: theme.palette.primary.main,
                  marginLeft: 'auto',
                }}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
          <Grid className={classes.cardContainer}>
            {
              <>
                {appointmentsData.loading ? (
                  <DoctariSpinner />
                ) : appointments.length > 0 ? (
                  appointments.map((appointment) => {
                    return (
                      <HistoricAppointmentCard
                        appointment={appointment}
                        isProfessional={isProfessional(session.userType)}
                        setPrescriptionModal={setPrescriptionModal}
                        setSelectedPrescription={setSelectedPrescription}
                      />
                    );
                  })
                ) : (
                  <h3>No hay consultas</h3>
                )}
              </>
            }

            {true && (
              <Pagination
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '2rem',
                  marginBottom: '2rem',
                }}
                page={currentPage}
                count={numOfPages}
                onChange={(_, page) => {
                  if (currentPage !== page) {
                    setState({ ...state, currentPage: page });
                  }
                }}
                variant="outlined"
                color="primary"
              />
            )}
          </Grid>
        </div>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ session, appointments }) => {
  return {
    session,
    appointmentsData: appointments,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAppointments: (userType, id, isDone, queryParams, queryValues) =>
    dispatch({
      type: appointmentActionsTypes.GET_APPOINTMENTS,
      payload: {
        userType,
        id,
        isDone,
        queryParams,
        queryValues,
      },
    }),

  getAppointmentsLight: (userType, id, isDone, queryParams, queryValues) =>
    dispatch({
      type: appointmentActionsTypes.GET_APPOINTMENTS_LIGHT,
      payload: {
        userType,
        id,
        isDone,
        queryParams,
        queryValues,
      },
    }),

  clearAppointments: () =>
    dispatch({ type: appointmentActionsTypes.CLEAR_APPOINTMENTS }),

  getAppointmentsProficient: (userType, id, isDone, queryParams, queryValues) =>
    dispatch({
      type: appointmentActionsTypes.GET_APPOINTMENTS_PROFICIENT, //TODO cambiar el formato de fechas en el back para no tener que hacer todo este juego con format
      payload: {
        userType,
        id,
        isDone,
        queryParams,
        queryValues,
      },
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoricAppointments);
