import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Box,
  useTheme,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Menu as MenuIcon,
  ArrowBack,
  ArrowDropDown,
  ArrowDropUp,
} from '@material-ui/icons';
import PatientAgendaRequest from './PatientAgendaRequest';
import { AvatarMenu } from './RightMenu';
import DoctariModal from '../Modal';
import { userActionsTypes } from '../../redux/user/actions';
import { appointmentActionsTypes } from '../../redux/appointment/actions';
import {
  PROFILE,
  HOMEPAGE,
  SCHEDULE_REQUESTS,
  APPOINTMENTS,
  HISTORIC_APPOINTMENTS,
  VIDEO_CALL_TEST,
} from '../../utils/urls';
import { capitalize, isProfessional } from '../../utils/helpers';
import doctariLogo from '../../assets/doctari_logo.svg';
import {
  desktopTabs,
  mobileOptions,
  mobileOptionsMenuAssistant,
} from './constants';
import { MenuAssistant } from './DropMenuAssistant';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  header: {
    height: '5rem',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    height: '3rem',
    '@media (max-width: 900px)': {
      height: '2.5rem',
    },
    '@media (max-width: 412px)': {
      // height: '2.5rem',
      width: '100%',
    },
  },
  headerLogin: {
    backgroundColor: theme.palette.primary.main,
    '@media (max-width: 900px)': {
      paddingLeft: 0,
    },
  },
  menuButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    marginRight: '2rem',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerContainer: {
    padding: '10px 20px',
    paddingRight: '45px',
  },
  menuContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  //Avatar:
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    cursor: 'pointer',
  },
  avatar: {
    border: 'solid 2px #b8f3f3',
    marginRight: '1rem',
  },
  containerLanguageButtons: {
    position: 'absolute',
    top: '18px',
    border: '2px solid white',
    borderRadius: '10px',
    right: '10px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  buttonLanguage: {
    // color: 'white',
    borderRadius: '7px',
    '&:hover': {
      backgroundColor: 'rgba(11, 143, 231, 0.63)',
    },
  },
}));

const LanguageButtons = ({ i18n, classes, theme, languageType }) => {
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        className={classes.buttonLanguage}
        style={{
          color: languageType == 'es' ? theme.palette.primary.main : 'white',
          backgroundColor: languageType == 'es' ? 'white' : '',
          borderColor: languageType == 'es' ? 'white' : '',
        }}
        onClick={() => {
          i18n.changeLanguage('es');
          // setSelectedLanguageButton(0);
          localStorage.setItem('language', 'es');
          // setLanguage('es');
        }}
      >
        🇪🇸 ES
      </Button>
      <Button
        variant="outlined"
        color="primary"
        className={classes.buttonLanguage}
        style={{
          color: languageType == 'por' ? theme.palette.primary.main : 'white',
          backgroundColor: languageType == 'por' ? 'white' : '',
          borderColor: languageType == 'por' ? 'white' : '',
        }}
        onClick={() => {
          i18n.changeLanguage('por');
          localStorage.setItem('language', 'por');
          // setLanguage('pt-br');
        }}
      >
        🇵🇹 POR
      </Button>
    </>
  );
};

const LanguageDesktopHeader = ({
  i18n,
  classes,
  theme,
  selectedLanguageButton,
  setSelectedLanguageButton,
  DoctariLogo,
  languageType,
}) => {
  return (
    <Box>
      <div className={classes.containerLanguageButtons}>
        <LanguageButtons
          i18n={i18n}
          classes={classes}
          theme={theme}
          languageType={languageType}
        />
      </div>

      <div className={classes.header}>
        <DoctariLogo />
      </div>
    </Box>
  );
};

function Header({
  session,
  logoutUser,
  style,
  appointmentsData,
  getScheduleRequests,
  updateScheduleRequests,
  acceptScheduleRequest,
  setLanguage,
}) {
  const [t, i18n] = useTranslation('global');
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const isMainPage = location.pathname == APPOINTMENTS;
  const isProfile = location.pathname == PROFILE;

  const { headerLogin, menuButton, toolbar, drawerContainer } = classes;
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
    open: false,
    modalOpen: false,
  });
  const { mobileView, drawerOpen, modalOpen } = state;
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedLanguageButton, setSelectedLanguageButton] = useState(0);
  const theme = useTheme();
  const languageType = localStorage.getItem('language');

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  const DrawerChoices = ({ choices }) => {
    return choices.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: 'inherit',
            style: { textDecoration: 'none' },
            key: label,
          }}
          onClick={() => {
            if (label === 'Solicitudes de agenda') {
              if (isProfessional(session.userType)) {
                history.push(SCHEDULE_REQUESTS);
              } else {
                setState({ ...state, drawerOpen: null, modalOpen: true });
              }
            }
          }}
        >
          <MenuItem>{capitalize(t(`header.${label.toLowerCase()}`))}</MenuItem>
        </Link>
      );
    });
  };

  const GetHeaderTabs = ({ choices }) => {
    return choices.map(({ label, href }) => {
      return (
        <Button
          {...{
            key: label,
            color: 'inherit',
            className: menuButton,
          }}
          onClick={() => {
            if (label.toLowerCase() === 'solicitudes de agenda') {
              if (isProfessional(session.userType)) {
                history.push(SCHEDULE_REQUESTS);
              } else {
                setState({ ...state, modalOpen: true });
              }
            } else if (label.toLowerCase() === 'histórico de consulta') {
              history.push(HISTORIC_APPOINTMENTS);
            } else if (label.toLowerCase() === 'mis consultas') {
              history.push(APPOINTMENTS);
            } else if (label.toLowerCase() === 'probar video') {
              history.push(VIDEO_CALL_TEST);
            }
          }}
        >
          {/*  {label} */}
          {t(`header.${label.toLowerCase()}`)}
        </Button>
      );
    });
  };

  const GetHeaderAssistantOperator = ({ isProfile }) => {
    return (
      <>
        {!isProfile && (
          <>
            <MenuAssistant
              classes={classes}
              openModal={() => setState({ ...state, modalOpen: true })}
              logout={logoutUser}
            />
          </>
        )}
      </>
    );
  };

  const HeaderDesktop = () => {
    if (!session || !session.email) {
      return (
        <LanguageDesktopHeader
          i18n={i18n}
          classes={classes}
          theme={theme}
          setLanguage={setLanguage}
          selectedLanguageButton={selectedLanguageButton}
          setSelectedLanguageButton={setSelectedLanguageButton}
          DoctariLogo={DoctariLogo}
          languageType={languageType}
        />
      );
    }
    return (
      <Toolbar className={toolbar}>
        {/* <div>
          <button
            onClick={() => {
              i18n.changeLanguage('es');
              setLanguage('es');
            }}
          >
            ES
          </button>
          <button
            onClick={() => {
              i18n.changeLanguage('por');
              setLanguage('pt');
            }}
          >
            POR
          </button>
        </div> */}
        {!isMainPage && (
          <IconButton
            style={{ color: 'white' }}
            onClick={() => {
              history.push(HOMEPAGE);
            }}
          >
            <ArrowBack />
            <span style={{ marginLeft: '0.5rem' }}>{t('header.back')} </span>
          </IconButton>
        )}
        {isMainPage && <DoctariLogo />}
        <div className={classes.menuContainer}>
          {session.userType == 'assistant' || session.userType == 'operator' ? (
            <GetHeaderAssistantOperator isProfile={isProfile} />
          ) : (
            <GetHeaderTabs choices={isProfile ? [] : desktopTabs} />
          )}
          <AvatarMenu
            classes={classes}
            openModal={() => setState({ ...state, modalOpen: true })}
            logout={logoutUser}
            goProfile={() =>
              history.push({ pathname: PROFILE, state: { profileType: '' } })
            }
            userName={session.name}
            profilePic={session.profilePic}
          />
        </div>
      </Toolbar>
    );
  };

  const HeaderMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    if (!session || !session.email) {
      return (
        <Toolbar style={{ justifyContent: 'center', height: '15vh' }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{ gap: '10px' }}
          >
            <Grid item>
              <DoctariLogo />
            </Grid>
            <Grid
              item
              style={{ border: '2px solid white', borderRadius: '10px' }}
            >
              <LanguageButtons
                i18n={i18n}
                classes={classes}
                theme={theme}
                languageType={languageType}
              />
            </Grid>
          </Grid>
        </Toolbar>
      );
    }
    return (
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <DoctariLogo />
          </Grid>
          <Grid container item xs={2} justifyContent="flex-end">
            <IconButton
              {...{
                edge: 'end',
                color: 'inherit',
                'aria-label': 'menu',
                'aria-haspopup': 'true',
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon style={{ fontSize: '2rem' }} />
            </IconButton>
          </Grid>
          {session.userType == 'assistant' || session.userType == 'operator' ? (
            <Drawer
              {...{
                anchor: 'right',
                open: drawerOpen,
                onClose: handleDrawerClose,
              }}
            >
              <List style={{ width: '12rem' }}>
                <ListItem button onClick={() => setOpenMenu(!openMenu)}>
                  <ListItemText primary="Menú" />

                  {openMenu ? <ArrowDropUp /> : <ArrowDropDown />}
                </ListItem>
                <Collapse in={openMenu} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {mobileOptionsMenuAssistant.map(({ label, href }) => {
                      if (label == 'Lista de doctores') {
                        return (
                          <ListItem
                            button
                            onClick={() =>
                              history.push({
                                pathname: href,
                                state: {
                                  list: 'doctors',
                                },
                              })
                            }
                            disablePadding
                          >
                            <ListItemText primary={t('header.doctors-list')} />
                          </ListItem>
                        );
                      } else if (label == 'Perfil Doctor') {
                        return (
                          <ListItem
                            button
                            onClick={() =>
                              history.push({
                                pathname: href,
                                state: {
                                  profileType: 'profileDoctor',
                                },
                              })
                            }
                            disablePadding
                          >
                            <ListItemText
                              primary={t('header.doctor-profile')}
                            />
                          </ListItem>
                        );
                      } else {
                        return (
                          <ListItem
                            button
                            onClick={() =>
                              history.push({
                                pathname: href,
                              })
                            }
                            disablePadding
                          >
                            <ListItemText primary={label} />
                          </ListItem>
                        );
                      }
                    })}
                  </List>
                </Collapse>

                <div style={{ width: '100%', border: 'solid 1px #8080803b' }} />
                <MenuItem
                  onClick={() =>
                    history.push({
                      pathname: PROFILE,
                      state: { profileType: '' },
                    })
                  }
                >
                  {t('header.profile')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    logoutUser();
                  }}
                >
                  {t('header.exit')}
                </MenuItem>
              </List>
            </Drawer>
          ) : (
            <Drawer
              {...{
                anchor: 'right',
                open: drawerOpen,
                onClose: handleDrawerClose,
              }}
            >
              <div className={drawerContainer}>
                <DrawerChoices choices={mobileOptions} />
                <div style={{ width: '100%', border: 'solid 1px #8080803b' }} />
                <MenuItem
                  onClick={() =>
                    history.push({
                      pathname: PROFILE,
                      state: { profileType: '' },
                    })
                  }
                >
                  {t('header.profile')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    logoutUser();
                  }}
                >
                  {t('header.exit')}
                </MenuItem>
              </div>
            </Drawer>
          )}
        </Grid>
      </Toolbar>
    );
  };

  const DoctariLogo = () => (
    <img
      alt="logo"
      src={doctariLogo}
      className={classes.img}
      style={{ cursor: session.email ? 'pointer' : 'initial' }}
      onClick={() => session.email && history.push(HOMEPAGE)}
    />
  );

  if (isProfile) {
    return (
      <header>
        <AppBar
          className={headerLogin}
          style={{ position: 'relative', boxShadow: '0 0 0 0' }}
        >
          {mobileView ? <HeaderMobile /> : <HeaderDesktop />}
        </AppBar>
      </header>
    );
  }
  return (
    <header>
      <DoctariModal
        open={modalOpen}
        setOpen={(val) => setState({ ...state, modalOpen: val })}
        title={t('header.awaiting-requests')}
        hideCross
      >
        <PatientAgendaRequest
          closeModal={() => setState({ ...state, modalOpen: false })}
          scheduleRequests={appointmentsData.scheduleRequests}
          appointmentsData={appointmentsData}
          loading={appointmentsData.loading}
          getScheduleRequests={() =>
            getScheduleRequests(
              `?${session.userType}=${session.id}&status__in=waiting_doctor, waiting_patient`,
              session.accessToken
            )
          }
          updateScheduleRequests={updateScheduleRequests}
          acceptScheduleRequest={acceptScheduleRequest}
          userId={session.id}
          token={session.accessToken}
        />
      </DoctariModal>
      <AppBar className={headerLogin} style={{ ...style, paddingRight: '0px' }}>
        {mobileView ? <HeaderMobile /> : <HeaderDesktop />}
      </AppBar>
    </header>
  );
}

const mapStateToProps = ({ session, appointments }) => {
  return {
    session,
    appointmentsData: appointments,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch({ type: userActionsTypes.LOGOUT }),
  getScheduleRequests: (queryId, token) =>
    dispatch({
      type: appointmentActionsTypes.GET_SCHEDULE_REQUESTS,
      payload: { queryId, token },
    }),
  updateScheduleRequests: (queryId, id, body, token) =>
    dispatch({
      type: appointmentActionsTypes.UPDATE_SCHEDULE_REQUESTS,
      payload: { queryId, id, body, token },
    }),
  acceptScheduleRequest: (body, token) =>
    dispatch({
      type: appointmentActionsTypes.ACCEPT_SCHEDULE_REQUESTS,
      payload: { body, token },
    }),
  setLanguage: (language) =>
    dispatch({ type: userActionsTypes.SET_LANGUAGE, payload: { language } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
