import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Backdrop,
  Fade,
  Grid,
  Box,
  IconButton,
  Avatar,
  Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import getCroppedImg from './cropImage';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: theme.typography.fontFamily,
  },
  input: {
    display: 'none',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    overflow: 'hidden',
    boxShadow: theme.shadows[5],
  },
  modalTitle: {
    fontSize: '125%',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '64px',
    background: theme.palette.primary.main,
  },
  body: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cropContainer: {
    marginTop: '2rem',
    position: 'relative',
    height: '18rem',
    width: '80%',
  },
  crop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  currentPhotoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '1rem',
    color: theme.palette.primary.main,
  },
  avatar: {
    cursor: 'pointer',
    border: `solid 4px ${theme.palette.primary.main}`,
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem',
    padding: '1rem',
    '@media (max-width: 900px)': {
      justifyContent: 'center',
    },
  },
}));

function ProfilePicture({ open, setOpen, updateProfilePic, currentPhoto }) {
  const classes = useStyles();

  const [state, setState] = useState({
    uploadedImage: null,
    imageAsFile: null,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1,
  });
  const { uploadedImage, imageAsFile } = state;

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  //Handle the uploaded img
  const onSelectFile = (e) => {
    const allowedImages = ['image/png', 'image/jpeg'];
    if (
      e.target.files &&
      e.target.files.length > 0 &&
      allowedImages.indexOf(e.target.files[0].type) >= 0
    ) {
      const uploadedURL = URL.createObjectURL(e.target.files[0]);
      setState({
        ...state,
        uploadedImage: uploadedURL,
        imageAsFile: e.target.files[0],
      });
    }
  };

  const onCropChange = (crop) => {
    setState({ ...state, crop });
  };

  const onZoomChange = (zoom) => {
    setState({ ...state, zoom });
  };

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  //Crop the image and send it to backend
  const setCroppedImage = useCallback(async () => {
    if (imageAsFile) {
      const croppedImage = await getCroppedImg(
        uploadedImage,
        croppedAreaPixels,
        imageAsFile.type,
        imageAsFile.name
      );
      const formDataUser = new FormData();
      formDataUser.append('file', croppedImage);
      updateProfilePic(formDataUser);
    }

    setOpen(false);
  }, [
    croppedAreaPixels,
    setOpen,
    imageAsFile,
    updateProfilePic,
    uploadedImage,
  ]);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Grid item xs={11} md={4} className={classes.paper}>
          <Box className={classes.modalTitle}>
            <span style={{ marginLeft: '2rem' }}>Imágen de perfil</span>
            <IconButton
              style={{ color: 'white' }}
              aria-label="upload picture"
              component="span"
              onClick={() => setOpen(false)}
            >
              <Close />
            </IconButton>
          </Box>
          <Box className={classes.body}>
            <Box className={classes.currentPhotoContainer}>
              <h4 style={{ marginRight: '1rem' }}> Foto actual: </h4>
              {currentPhoto && (
                <Avatar
                  alt="Remy Sharp"
                  src={currentPhoto}
                  className={classes.avatar}
                />
              )}
            </Box>
            {uploadedImage && (
              <Box className={classes.cropContainer}>
                <Box className={classes.crop}>
                  <Cropper
                    image={uploadedImage}
                    crop={state.crop}
                    zoom={state.zoom}
                    aspect={state.aspect}
                    cropShape="round"
                    showGrid={false}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}
                  />
                </Box>
              </Box>
            )}
            <Box>
              <input
                accept="image/png,image/jpeg"
                onChange={onSelectFile}
                className={classes.input}
                id="contained-button-file"
                type="file"
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="outlined"
                  color="primary"
                  component="span"
                  style={{ marginTop: '1rem' }}
                >
                  Examinar
                </Button>
              </label>
            </Box>
          </Box>
          <Grid item xs={12} md={12} className={classes.buttons}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpen(false)}
              style={{ marginRight: '1rem' }}
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={setCroppedImage}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
}

export default ProfilePicture;
