import { useState } from 'react';
import moment from 'moment-timezone';
import 'moment/locale/pt';
import 'moment/locale/es';
import {
  Grid,
  Button,
  Select,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
  RadioGroup,
  Radio,
  Box,
  capitalize,
  Typography,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

function CreateScheduleModal({
  availabilityStart,
  availabilityEnd,
  createSchedule,
  classes,
  addresses,
  token,
  professionalId,
  setAvailability,
  isSelfManaged,
  openModalAvailability,
  setIsCreateAppointment,
  slot,
  isProficient,
  setAppointmentDate,
  language,
  isAssistant,
  isOperator,
  isDoctorSelected,
  isProficientSelected,
}) {
  const [showSecondPage, setShowSecondPage] = useState(false);
  const [actionSelectPage, setActionSelectPage] = useState(isSelfManaged);
  const dateRange = ` ${moment(availabilityStart)
    .locale(language)
    .format('dddd [de] HH:mm [a] ')}${
    availabilityEnd
      ? moment(availabilityEnd).tz(moment.tz.guess()).format('HH:mm')
      : moment(availabilityStart)
          .add(slot, 'minute')
          .tz(moment.tz.guess())
          .format('HH:mm')
  } `;

  const [state, setState] = useState({
    online: false,
    f2f: false,
    interconsultation: false,
    address: null,
  });
  const { online, f2f, interconsultation, address } = state;
  const [t, i18n] = useTranslation('global');

  const weeksDay = (day) => {
    let dayName = day.toLowerCase();

    if (dayName === 'lun') {
      dayName = t('calendarDays.monday');
    } else if (dayName === 'mar') {
      dayName = t('calendarDays.tuesday');
    } else if (dayName === 'mié') {
      dayName = t('calendarDays.wednesday');
    } else if (dayName === 'jue') {
      dayName = t('calendarDays.thursday');
    } else if (dayName === 'vie') {
      dayName = t('calendarDays.friday');
    } else if (dayName === 'sab') {
      dayName = t('calendarDays.saturday');
    } else if (dayName === 'dom') {
      dayName = t('calendarDays.sunday');
    }

    return dayName;
  };

  const ScheduleType = () => {
    const [actionType, setActionType] = useState(null);

    const handleAction = () => {
      if (actionType == 'availability') {
        setAvailability((availability) => {
          return {
            ...availability,
            openModalAvailability: true,
          };
        });
        setActionSelectPage(false);
      } else {
        if (!isProficient) {
          let body = {
            start: moment(availabilityStart).tz(moment.tz.guess()).format(),
            doctor: professionalId,
            types: [2],
            address,
          };
          createSchedule(body, false, token);
        }
        setAppointmentDate(availabilityStart);
        setIsCreateAppointment(true);
        setAvailability((availability) => {
          return {
            ...availability,
            createModalOpen: false,
            start: null,
            end: null,
            openModalAvailability: false,
          };
        });
      }
    };

    return (
      <>
        {/* <Box
        style={{
          padding: '2rem',
          overflow: 'auto',
        }}
      > */}
        <Box>
          <RadioGroup
            onChange={(e) => setActionType(e.target.value)}
            //   value={repeat}
            row
            className={classes.radioGroupContainer}
          >
            {!isProficient && !isAssistant && (
              <FormControlLabel
                value="availability"
                control={<Radio color="primary" />}
                label={`Agendar ${t('word.availability')}`}
              />
            )}
            <FormControlLabel
              value="appointment"
              control={<Radio color="primary" />}
              label="Agendar consulta"
            />
          </RadioGroup>
        </Box>
        {(isAssistant || isOperator) && !(isDoctorSelected || isProficientSelected) && (
          <Typography variant="subtitle2" color="error">
            {t('Assistant.select-doctor')}
          </Typography>
        )}
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={
              !actionType || ((isAssistant || isOperator) && !(isDoctorSelected || isProficientSelected))
            }
            style={{ width: '200px' }}
            onClick={() => {
              handleAction();
            }}
          >
            {`${capitalize(t('word.accept'))}`}
          </Button>
        </Grid>
        {/* </Box> */}
      </>
    );
  };

  const AppointmentType = () => {
    const isEnableNext = () => {
      let isEnable = false;
      let obj = null;
      for (const prop in state) {
        if (prop !== 'address') {
          obj = state[prop];
          if (prop === 'f2f' && obj) {
            //TODO: Ver si puso address
            isEnable = isEnable || address;
          } else {
            isEnable = isEnable || obj;
          }
        }
      }
      return isEnable;
    };

    const handleChange = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
      <>
        <Box marginBottom="2rem" textAlign="center">
          <span>{`Agendar consulta para ${t('prep.the-1')} ${t(
            'word.day'
          )}`}</span>
          <span style={{ fontWeight: 'bold' }}>{dateRange}</span>
        </Box>
        <Box>
          <p>Tipo de consulta:</p>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={online}
                  onClick={handleChange}
                  name="online"
                  color="primary"
                />
              }
              label={`Consulta ${t('word.via')} web`}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={f2f}
                  onClick={handleChange}
                  name="f2f"
                  color="primary"
                />
              }
              label="Consulta presencial"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={interconsultation}
                  onClick={handleChange}
                  name="interconsultation"
                  color="primary"
                />
              }
              label="Interconsulta"
            />
          </FormGroup>
        </Box>
        {f2f && (
          <FormControl
            className={classes.formControl}
            style={{ width: '100%' }}
          >
            <InputLabel>{`${capitalize(
              t('word.address')
            )} de la consulta`}</InputLabel>
            <Select
              native
              value={address}
              onChange={(e) => setState({ ...state, address: e.target.value })}
            >
              <option aria-label="None" value="" />
              {addresses &&
                addresses.map((address) => {
                  return (
                    <option
                      value={address.id}
                    >{`${address.street} ${address.street_number} - ${address.city}, ${address.country.name}`}</option>
                  );
                })}
            </Select>
          </FormControl>
        )}
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={!isEnableNext()}
            style={{ width: '200px' }}
            onClick={() => setShowSecondPage(!showSecondPage)}
          >
            {`${capitalize(t('word.next'))}`}
          </Button>
        </Grid>
      </>
    );
  };

  const AppointmentSchedule = () => {
    const [state, setState] = useState({
      endDate: null, //moment().format('YYYY-MM-DD HH:mm'),
      repeat: false,
    });
    const { endDate, repeat } = state;

    const daysLabel = [
      { value: 'monday', label: 'Lun' },
      { value: 'tuesday', label: 'Mar' },
      { value: 'wednesday', label: 'Mié' },
      { value: 'thursday', label: 'Jue' },
      { value: 'fryday', label: 'Vie' },
      { value: 'saturday', label: 'Sab' },
      { value: 'sunday', label: 'Dom' },
    ];
    let selected_day = moment(availabilityStart).day(); // Tomamos el día seleccionado comenzando por domingo = 0 para marcar con el tick al mmomento de ver los días
    const [daysSelected, setDaysSelected] = useState({
      sunday: selected_day == 0,
      monday: selected_day == 1,
      tuesday: selected_day == 2,
      wednesday: selected_day == 3,
      thursday: selected_day == 4,
      fryday: selected_day == 5,
      saturday: selected_day == 6,
    });

    const handleChangeDays = (event) => {
      setDaysSelected({
        ...daysSelected,
        [event.target.name]: event.target.checked,
      });
    };

    const createDocSchedule = () => {
      let body = {};
      let types = [2]; // 2 = online
      types = [
        ...(interconsultation ? [1] : []),
        ...(online ? [2] : []),
        ...(f2f ? [3] : []),
      ];

      if (availabilityEnd || repeat == 'repeat') {
        // usamos el mismo formato de días que python weekday
        const dayNumber = {
          monday: 0,
          tuesday: 1,
          wednesday: 2,
          thursday: 3,
          fryday: 4,
          saturday: 5,
          sunday: 6,
        };
        const days = [];

        for (const dayName in daysSelected) {
          if (daysSelected[dayName]) {
            days.push(dayNumber[dayName]);
          }
        }

        body = {
          date_start: availabilityStart,
          date_finish: availabilityEnd ? availabilityEnd : availabilityStart,
          ...(endDate && { date_finish_repeat: endDate }),
          types,
          doctor: professionalId,
          days,
        };
      } else {
        body = {
          start: moment(availabilityStart).tz(moment.tz.guess()).format(),
          doctor: professionalId,
          types,
          address,
        };
      }

      createSchedule(body, availabilityEnd || repeat == 'repeat', token);
    };

    return (
      <>
        <Box marginBottom="2rem" textAlign="center">
          <span>Marcar</span>
          <span style={{ fontWeight: 'bold' }}>{dateRange}</span>
          <span>como</span>
        </Box>

        <Box>
          <RadioGroup
            onChange={(e) => setState({ ...state, repeat: e.target.value })}
            value={repeat}
            row
          >
            <FormControlLabel
              value="only today"
              control={<Radio color="primary" />}
              label={`${capitalize(t('word.available'))} ${t('word.only')} ${t(
                'word.this'
              )} dia`}
            />
            <FormControlLabel
              value="repeat"
              control={<Radio color="primary" />}
              label="Repetir"
            />
          </RadioGroup>
          {repeat === 'repeat' && (
            <FormGroup row>
              {daysLabel.map((day) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={daysSelected[day.value]}
                        onClick={handleChangeDays}
                        name={day.value}
                        color="primary"
                      />
                    }
                    label={`${capitalize(weeksDay(day.label))}`}
                  />
                );
              })}
            </FormGroup>
          )}
        </Box>
        {repeat === 'repeat' && (
          <FormControl
            className={classes.formControl}
            style={{ alignItems: 'center', width: '100%' }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label={`${capitalize(t('word.ends'))} ${t('prep.in')}`}
                value={endDate}
                onChange={(date) => setState({ ...state, endDate: date })}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                disablePast
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        )}
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            // disabled={!isEnable()}
            style={{ width: '200px' }}
            onClick={() => {
              createDocSchedule();
              setAvailability((availability) => {
                return {
                  ...availability,
                  createModalOpen: false,
                  start: null,
                  end: null,
                  openModalAvailability: false,
                };
              });
            }}
          >
            {`${capitalize(t('word.accept'))}`}
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <Box
      style={{
        padding: '2rem',
        overflow: 'auto',
      }}
    >
      {actionSelectPage && !openModalAvailability ? (
        <ScheduleType />
      ) : showSecondPage ? (
        <AppointmentSchedule />
      ) : (
        <AppointmentType />
      )}
    </Box>
  );
}

export default CreateScheduleModal;
