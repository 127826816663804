import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom/cjs/react-router-dom.min';
import { DoctariRoutes } from './DoctariRoutes';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import Login from '../screens/login';
import VideoCallTest from '../screens/video-call-test';

const AppRouter = ({ session }) => {
  return (
    <Router>
      <div>
        <Switch>
          <PublicRoute
            exact
            path="/login"
            component={Login}
            isAuthenticated={session && session.email}
          />
          <PublicRoute
            exact
            path="/video-test"
            component={VideoCallTest}
            isAuthenticated={session && session.email}
          />

          <PrivateRoute
            path="/"
            component={DoctariRoutes}
            isAuthenticated={session && session.email}
          />
        </Switch>
      </div>
    </Router>
  );
};

const mapStateToProps = ({ session }) => {
  return {
    session,
  };
};

export default connect(mapStateToProps)(AppRouter);
