import { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import {
  Grid,
  Box,
  Tabs,
  Tab,
  FormControl,
  TextField,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PersonalData from './PersonalData';
import ProfessionalData from './ProfessionalData';
import AccountData from './AccountData';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';

function DoctorProfile({
  updateUser,
  countries,
  specialities,
  createAddress,
  deleteAddress,
  updateProfilePic,
  changeUserPassword,
  user,
  validateEmail,
  isOlderThan18,
  classes,
  isProfileDoctor,
  session,
  doctors,
  setSelectedDoctor,
  clearSelectedDoctor,
  getUserData,
  userData,
  isAssistant,
  clearMessage,
  updateSignature,
  deleteMessages,
  updateSelectedDoctor,
  createAddressByAssistant,
  deleteAddressByAssistant,
}) {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const [t, i18n] = useTranslation('global');
  const [userLoad, setUserLoad] = useState(false);

  /* const { countries, specialities, numOfPages, doctors, currentPage } =
    doctors */

  const [state, setState] = useState({
    /*  dateOfAppointment: null,
    doctorName: null,
    specialty: null,
    country: null,
    type: null,
    availability: true,
    score: false, */
    doctorId: null,
    doctorFullData: null,
    // userLoad: false,
  });

  const {
    dateOfAppointment,
    doctorName,
    specialty,
    country,
    type,
    availability,
    score,
    doctorId,
    doctorFullData,
    // userLoad,
  } = state;

  useEffect(() => {
    // getDoctorsInfo([], []);
    getUserData(session.userType, session.id);
    if (doctors.selectedDoctor != null) {
      setState({
        ...state,
        doctorId: doctors.selectedDoctor.id,
        doctorFullData: doctors.selectedDoctor,
      });
    }
  }, []);

  useEffect(() => {
    if (doctorId) {
      setSelectedDoctor(doctorFullData);
    }
    return () => {
      clearSelectedDoctor();
    };
  }, [doctorId]);

  useEffect(() => {
    if (userData.user) {
      if (userData.user.doctors) {
        setUserLoad(true);
      } else if (session.userType === 'doctor') {
        setSelectedDoctor(userData.user);
      }
    }
  }, [userData.user]);

  const handleChangeDoctorId = (e, value) => {
    if (value == null) {
      clearSelectedDoctor();
      setState({
        ...state,
        doctorId: null,
        doctorFullData: null,
      });
    } else {
      setState({
        ...state,
        doctorId: value.id,
        doctorFullData: value,
      });
    }
  };

  const TabPanel = ({ children, value, index, ...other }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={1}>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Grid item xs={12} md={12}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          style={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            variant="fullWidth"
            aria-label="full width tabs example"
            className={classes.profileTabs}
          >
            <Tab label={t('Profile.personal-data')} {...a11yProps(0)} />
            <Tab label={t('Profile.academic-data')} {...a11yProps(1)} />{' '}
            {/* ver excel */}
            <Tab label={t('Profile.account-data')} {...a11yProps(2)} />
          </Tabs>
        </Box>
        {isProfileDoctor ? (
          <FormControl className={classes.selectDoctor}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={
                userLoad && userData.user.doctors
                  ? userData.user.doctors.filter((doctor) => {
                      return doctor.is_active;
                    })
                  : []
              }
              getOptionLabel={(option) => option.full_name}
              sx={{ width: 300 }}
              value={doctors.selectedDoctor}
              onChange={handleChangeDoctorId}
              renderInput={(params) => {
                const inputProps = params.inputProps;
                inputProps.autoComplete = 'new-password';
                return (
                  <TextField
                    {...params}
                    label={t('Assistant.select-doctor')}
                    inputProps={inputProps}
                  />
                );
              }}
            />
          </FormControl>
        ) : (
          ''
        )}
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
          className={classes.swipeableContainer}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <PersonalData
              classes={classes}
              validateEmail={validateEmail}
              isOlderThan18={isOlderThan18}
              user={user}
              countries={countries}
              specialities={specialities}
              updateUser={updateUser}
              createAddress={createAddress}
              deleteAddress={deleteAddress}
              updateProfilePic={updateProfilePic}
              isProfileDoctor={isProfileDoctor}
              session={session}
              doctors={doctors}
              isPatient={session.userType == 'patient'}
              isDoctor={session.userType == 'doctor'}
              createAddressByAssistant={createAddressByAssistant}
              deleteAddressByAssistant={deleteAddressByAssistant}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <ProfessionalData
              classes={classes}
              user={user}
              specialities={specialities}
              doctors={doctors}
              session={session}
              updateUser={updateUser}
              isProfileDoctor={isProfileDoctor}
            />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <AccountData
              classes={classes}
              user={user}
              changeUserPassword={changeUserPassword}
              session={session}
              doctors={doctors}
              isProfileDoctor={isProfileDoctor}
              isAssistant={isAssistant}
              updateSignature={updateSignature}
            />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </Grid>
  );
}

export default DoctorProfile;
