import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, TextField, Box, Container } from '@material-ui/core';
import DoctariSpinner from '../../components/Spinner';
import AgendaRequestCard from '../../components/Cards/AgendaRequestCard';
import { doctorsActionsTypes } from '../../redux/doctor/actions';
import { appointmentActionsTypes } from '../../redux/appointment/actions';
import { useStyles } from './style';
import Notification from '../../components/Notification';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { userActionsTypes } from '../../redux/user/actions';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/helpers';

function ScheduleRequests({
  session,
  appointmentsData,
  deleteMessages,
  updateScheduleRequests,
  userData,
  getUser,
  setSelectedDoctor,
  getSchedulesByDate,
  clearSchedules,
  clearSelectedDoctor,
  clearScheduleRequests,
}) {
  const [t, i18n] = useTranslation('global');
  const [doctorCompleteData, setDoctorCompleteData] = useState(null);
  const classes = useStyles();
  const { scheduleRequests, loading } = appointmentsData;
  const isProfileDoctor =
    session.userType === 'assistant' || session.userType === 'operator';

  const [state, setState] = useState({
    dateOfAppointment: null,
    doctorName: null,
    specialty: null,
    country: null,
    type: null,
    availability: true,
    score: false,
    doctorId: null,
    doctorFullData: null,
    // userLoad: false,
  });
  const {
    doctorFullData,
    // userLoad,
  } = state;

  useEffect(() => {
    if (doctorCompleteData && doctorCompleteData.id) {
      setSelectedDoctor(doctorFullData);
      getSchedulesByDate(
        moment()
          .day(1)
          .set('hour', '00')
          .set('minute', '00')
          .add(0, 'days')
          .tz('America/Montevideo')
          .format('YYYY-MM-DD HH:mm'),
        moment()
          .day(1)
          .add(7, 'days')
          .tz('America/Montevideo')
          .format('YYYY-MM-DD HH:mm'),
        ['doctor'],
        [doctorCompleteData.id]
      );
    } else {
      clearSchedules();
      clearSelectedDoctor();
      clearScheduleRequests();
    }
  }, [doctorCompleteData]);

  useEffect(() => {
    getUser(session.userType, session.id);
  }, []);

  const scheduleRequestsWaitingDoctor = scheduleRequests
    ? scheduleRequests.filter(
        (scheduleRequest) => scheduleRequest.status === 'waiting_doctor'
      )
    : '';
  const scheduleRequestsWaitingPatient = scheduleRequests
    ? scheduleRequests.filter(
        (scheduleRequest) => scheduleRequest.status === 'waiting_patient'
      )
    : '';

  const handleChangeDoctor = (e, value) => {
    if (value == null) {
      clearSelectedDoctor();
      clearSchedules();
      setDoctorCompleteData(null);
    } else {
      setDoctorCompleteData(value);
    }
  };

  return (
    <>
      {isProfileDoctor ? (
        <Container>
          <Box style={{ width: '20%' }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={
                userData.user && userData.user.doctors
                  ? userData.user.doctors.filter((doctor) => {
                      return doctor.is_active;
                    })
                  : []
              }
              getOptionLabel={(option) => option.full_name}
              sx={{ width: 300 }}
              value={doctorCompleteData}
              onChange={handleChangeDoctor}
              renderInput={(params) => (
                <TextField {...params} label={t('Assistant.select-doctor')} />
              )}
            />
          </Box>
        </Container>
      ) : (
        ''
      )}

      {appointmentsData.success.genericMessage ===
        'Schedule request actualizada' && (
        <Notification
          type="success"
          message={`${capitalize(t('word.request'))} ${t('word.updated')}`}
          onCloseCallback={deleteMessages}
        />
      )}

      <Grid container justifyContent="center">
        <Grid item xs={11} md={7}>
          <h1 className={classes.title}>
            {t('ScheduleRequests.new-requests')}
          </h1>
          <Box display="flex" flexDirection="column">
            {scheduleRequests && scheduleRequestsWaitingDoctor.length > 0 ? (
              <>
                {scheduleRequests.map((scheduleRequest) => {
                  return (
                    scheduleRequest.status === 'waiting_doctor' &&
                    (loading ? (
                      <Box style={{ marginTop: '5rem' }}>
                        <DoctariSpinner />
                      </Box>
                    ) : (
                      <AgendaRequestCard
                        scheduleRequest={scheduleRequest}
                        updateScheduleRequest={(body) =>
                          updateScheduleRequests(
                            `?doctor=${session.id}`,
                            scheduleRequest.id,
                            body,
                            session.accessToken
                          )
                        }
                        appointmentsData={appointmentsData}
                      />
                    ))
                  );
                })}
              </>
            ) : (
              <h4
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {t('ScheduleRequests.no-new-requests')}
              </h4>
            )}
          </Box>
        </Grid>
        <Grid item xs={11} md={7} style={{ marginBottom: '2rem' }}>
          <h1 className={classes.title}>
            {t('ScheduleRequests.answered-requests')}
          </h1>
          <Box display="flex" flexDirection="column">
            {scheduleRequests && scheduleRequestsWaitingPatient.length > 0 ? (
              <>
                {scheduleRequests.map((scheduleRequest) => {
                  return (
                    scheduleRequest.status === 'waiting_patient' &&
                    (loading ? (
                      <Box style={{ marginTop: '5rem' }}>
                        <DoctariSpinner />
                      </Box>
                    ) : (
                      <AgendaRequestCard
                        scheduleRequest={scheduleRequest}
                        appointmentsData={appointmentsData}
                      />
                    ))
                  );
                })}
              </>
            ) : (
              <h4
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {t('ScheduleRequests.no-requests')}
              </h4>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ session, doctors, appointments, user }) => {
  return {
    doctorsInfo: doctors,
    session,
    appointmentsData: appointments,
    userData: user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDoctorsInfo: (queryParams, paramValues, page, token) =>
    dispatch({
      type: doctorsActionsTypes.GET_DOCTORS,
      payload: { queryParams, paramValues, page, token },
    }),
  getScheduleRequests: (queryId, token) =>
    dispatch({
      type: appointmentActionsTypes.GET_SCHEDULE_REQUESTS,
      payload: { queryId, token },
    }),
  createScheduleRequests: (body, token) =>
    dispatch({
      type: appointmentActionsTypes.CREATE_SCHEDULE_REQUESTS,
      payload: { body, token },
    }),
  updateScheduleRequests: (queryId, id, body, token) =>
    dispatch({
      type: appointmentActionsTypes.UPDATE_SCHEDULE_REQUESTS,
      payload: { queryId, id, body, token },
    }),
  deleteMessages: () =>
    dispatch({
      type: appointmentActionsTypes.DELETE_MESSAGES,
    }),
  getUser: (type, id) =>
    dispatch({
      type: userActionsTypes.GET_USER,
      payload: { type, id },
    }),
  setSelectedDoctor: (doctorBody) =>
    dispatch({
      type: doctorsActionsTypes.SET_DOCTOR_BODY,
      payload: doctorBody,
    }),
  getSchedulesByDate: (start, end, queryParams, queryValues) =>
    dispatch({
      type: appointmentActionsTypes.GET_SCHEDULES_BY_DATE, //TODO cambiar el formato de fechas en el back para no tener que hacer todo este juego con format
      payload: {
        start,
        end,
        queryParams,
        queryValues,
      },
    }),
  clearSchedules: () =>
    dispatch({ type: appointmentActionsTypes.CLEAR_SHEDULES }),
  clearSelectedDoctor: () =>
    dispatch({ type: doctorsActionsTypes.CLEAR_SELECT_DOCTOR }),
  clearScheduleRequests: () =>
    dispatch({ type: appointmentActionsTypes.CLEAR_SCHEDULE_REQUESTS }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleRequests);
