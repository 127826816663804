import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  titleSearchBarContainer: {
    margin: '0 3rem',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    color: theme.palette.lightPrimary.main,
    textAlign: 'center',
  },
  MuiPaper: {
    boxShadow: 'none',
    borderBottom: '1px solid lightgrey',
    borderRadius: '0',
    margin: '0 3rem',
    padding: '1.5rem 0',
    display: 'flex',
  },
  avatar: {
    width: '60px',
    height: '60px',
  },
  info: {
    fontFamily: theme.typography.fontFamily,
    marginLeft: '.5rem',
  },
  grey: {
    color: 'grey',
  },
  linkButtonContainer: {
    display: 'inline-flex',
    float: 'right',
    height: '100%',
    alignItems: 'center',
  },
  doctorLink: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.lightPrimary.main,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  link: {
    color: 'grey',
    fontFamily: theme.typography.fontFamily,
  },
  userButton: {
    textTransform: 'uppercase',
    marginLeft: '.5rem',
    borderRadius: '0',
  },
  formControl: {
    fontFamily: theme.typography.fontFamily,
    marginBottom: '1rem',
    width: '100%',
  },
}));
