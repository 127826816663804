import { useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  Paper,
  Grid,
  Avatar,
  Input,
  TextField,
  FormControlLabel,
  Checkbox,
  CardHeader,
  FormControl,
  InputLabel,
  FormHelperText,
  InputAdornment,
  Box,
} from '@material-ui/core';
import { useStyles } from './style';
import DoctariModal from '../../components/Modal';
import { DropzoneArea } from 'material-ui-dropzone';
import { connect } from 'react-redux';
import { APPOINTMENTS, BASE_PAYMENT, AGENDA } from '../../utils/urls';
import { userActionsTypes } from '../../redux/user/actions';
import { appointmentActionsTypes } from '../../redux/appointment/actions';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment-timezone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SearchOutlined } from '@material-ui/icons';
import { capitalize } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';

const SERVICES = [
  { id: 1, name: 'Hogar' },
  { id: 2, name: 'Vehículo' },
];

function AppointmentProficient({
  getUser,
  appointmentsData,
  session,
  userData,
  addUserInfo,
  deleteUserInfo,
  createInfo,
  uploadAppointmentFiles,
  deleteAttachmentMessages,
  clearInformationAppointment,
  getClient,
  createClient,
  createAppointmentProficient,
  clearClient,
  updateClient,
}) {
  const history = useHistory();
  const location = useLocation();
  const styles = useStyles();
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [createAppointmentModalOpen, setCreateAppointmentModalOpen] =
    useState(false);
  const [checked, setChecked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [reason, setReason] = useState('');
  const isAssistant = session.userType == 'assistant';
  const isOperator = session.userType == 'operator';
  const [identity_document, setIdentityDocument] = useState(null);
  const [isExistClient, setIsExistClient] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dataClient, setDataClient] = useState({
    id: null,
    name: null,
    lastName: null,
    country: null,
    document: null,
    email: null,
    phone: null,
    password: null,
    password2: null,
    dateOfBirth: moment().add(-18, 'years').format('YYYY-MM-DD'),
    termsAndConditions: false,
    city: null,
    gender: null,
    petName: null,
    petDateOfBirth: null,
  });

  const {
    name,
    lastName,

    phone,
    document,
    email,
  } = dataClient;

  const [patientSelected, setPatient] = useState({
    patient: null,
    medications: null,
    allergies: null,
    diseases: null,
  });

  const [patientAllergies, setPatientAllergies] = useState([]);
  const [appointmentType, setAppointmentType] = useState({});
  const [t, i18n] = useTranslation('global');

  const goPayment = () => {
    history.push({
      pathname: `${BASE_PAYMENT}${appointmentsData.appointmentCreated.id}`,
      state: {
        appointmentDate: location.state.schedule.start,
        doctorName: location.state.schedule.doctor.full_name,
      },
    });
    return () => deleteAttachmentMessages();
  };
  // when the appointment files are added  we go to payment page and pass the appointment id
  useEffect(() => {
    // if (appointmentsData.success.uploadFiles && buttonClicked && !isAssistant) {
    //   goPayment();
    // } else if (isAssistant && buttonClicked) {
    //
    // }
    if (appointmentsData.success.uploadFiles && buttonClicked) {
      history.push(AGENDA);
    }
  }, [appointmentsData.success.uploadFiles]);
  // when the appointment is created we upload the attachments
  useEffect(() => {
    if (
      appointmentsData.success.genericMessage ==
        'Appointment creado con exito' &&
      buttonClicked
    ) {
      if (attachments.length > 0) {
        const formDataAppointment = new FormData();
        for (let i = 0; i < attachments.length; i++) {
          formDataAppointment.append(`file${i}`, attachments[i]);
        }
        const body = {
          files: formDataAppointment,
          appointmentId: appointmentsData.appointmentCreated.id,
        };
        uploadAppointmentFiles(body);
      }
      setLoading(false);
      // setCreateAppointmentModalOpen(true);
      history.push(APPOINTMENTS);
    }
  }, [appointmentsData.success.genericMessage]);

  useEffect(() => {
    getUser(session.userType, session.id);
    // if (isAssistant) {
    //   getPatients();
    // }

    return () => {
      clearInformationAppointment();
      clearClient();
    };
  }, []);

  useEffect(() => {
    if (!isAssistant && userData.user) {
      setPatient({
        patient: userData.user,
        medications: userData.user.medications,
        allergies: userData.user.allergies,
        diseases: userData.user.diseases,
      });
    }
  }, [userData.user]);

  useEffect(() => {
    if (userData.client) {
      setIsExistClient(true);
      setDataClient({
        name: userData.client.first_name,
        lastName: userData.client.last_name,
        phone: userData.client.telephone,
        email: userData.client.email,
        id: userData.client.id,
      });
    } else if (userData.client == null) {
      setIsExistClient(false);
    }
  }, [userData.client]);

  useEffect(() => {
    if (userData.success == 'Cliente creado con exito') {
      handleCreateAppointmentProficient();
    }
  }, [userData.success]);

  const { user, medications, allergies, diseases } = userData;

  const getUrlAndId = (type, isForDelete) => {
    let dataType = {
      id: 'medication_id',
      url: isForDelete ? 'delete_medication' : 'patient_medication',
    };
    if (type === 'diseases') {
      dataType = {
        id: 'disease_id',
        url: isForDelete ? 'delete_disease' : 'patient_disease',
      };
    } else if (type === 'allergies') {
      dataType = {
        id: 'allergie_id',
        url: isForDelete ? 'delete_allergie' : 'patient_allergie',
      };
    }
    return dataType;
  };

  const handleUserMedication = (isNew, med, isForDelete = false) => {
    const infoToUpdate = 'medications';
    const data = getUrlAndId(infoToUpdate, isForDelete);
    if (!isForDelete) {
      if (isNew) {
        createInfo(infoToUpdate, { name: med.name }, data, isAssistant);
      } else {
        addUserInfo(infoToUpdate, med);
      }
    } else {
      deleteUserInfo(infoToUpdate, med.id);
    }
  };

  const handleUserDisease = (isNew, disease, isForDelete = false) => {
    const infoToUpdate = 'diseases';
    const data = getUrlAndId(infoToUpdate, isForDelete);
    if (!isForDelete) {
      if (isNew) {
        createInfo(infoToUpdate, { name: disease.name }, data, isAssistant);
      } else {
        addUserInfo(infoToUpdate, disease);
      }
    } else {
      deleteUserInfo(infoToUpdate, disease.id);
    }
  };

  const handleUserAllergie = (isNew, allergie, isForDelete = false) => {
    const infoToUpdate = 'allergies';
    const data = getUrlAndId(infoToUpdate, isForDelete);
    if (!isForDelete) {
      if (isNew) {
        createInfo(infoToUpdate, { name: allergie.name }, data, isAssistant);
      } else {
        addUserInfo(infoToUpdate, allergie);
      }
    } else {
      deleteUserInfo(infoToUpdate, allergie.id);
    }
  };

  const addInformation = {
    handleUserMedication,
    handleUserAllergie,
    handleUserDisease,
  };

  const handleAttachmentsChange = (files) => {
    setAttachments(files);
  };

  const handleInputCHange = (e) => {
    setReason(e.target.value);
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleCreateAppointmentProficient = () => {
    setButtonClicked(true);
    let type;
    if (appointmentType.id == 1) {
      type = 'home';
    } else {
      type = 'vehicle';
    }
    let body = {
      proficient: location.state.schedule.proficient.id,
      date: moment(location.state.schedule.date).tz(moment.tz.guess()).format(),
      client: userData.client.id,
      client_reason_text: reason,
    };
    createAppointmentProficient(type, body);
  };

  const handleSetPatientInfo = (value) => {
    if (value == null) {
      setPatient({
        patient: null,
        medications: null,
        allergies: null,
        diseases: null,
      });
    } else {
      setPatient({
        patient: value,
        medications: value.medications,
        allergies: value.allergies,
        diseases: value.diseases,
      });
    }
  };

  const handleCancelAppointment = () => {
    if (isAssistant || isOperator) {
      history.push({
        pathname: AGENDA,
        state: { specialtyType: location.state.appointmentType },
      });
    } else {
      history.push(APPOINTMENTS);
    }
  };

  const handleGetClient = (e) => {
    e.preventDefault();
    setDataClient({
      name: null,
      lastName: null,
      phone: null,
      email: null,
      id: null,
    });
    if (identity_document) {
      getClient(identity_document);
    }
  };

  const isOlderThan18 = (dateOfBirth) => {
    const today = moment();
    const diff = today.diff(moment(dateOfBirth), 'years');
    return diff >= 18;
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleClient = (update = false) => {
    const body = {
      email,
      first_name: name,
      last_name: lastName,
      identity_document,
      telephone: phone,
      reason: appointmentType.id,
    };

    if (update) {
      updateClient(dataClient.id, body);
    } else {
      createClient(body);
    }
  };

  const ClipboardCopy = ({ copyText }) => {
    const [isCopied, setIsCopied] = useState(false);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }

    // onClick handler function for the copy button
    const handleCopyClick = () => {
      // Asynchronously call copyTextToClipboard
      copyTextToClipboard(copyText)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <TextField
          id="my-input"
          aria-describedby="my-helper-text"
          // onChange={(e) =>
          //   setDataPatient({ ...dataPatient, name: e.target.value })
          // }
          // type="text"
          style={{ width: '80%' }}
          variant="outlined"
          value={copyText}
          size="small"
          readOnly
        />
        {/* <input type="text" value={copyText} readOnly /> */}
        {/* Bind our handler function to the onClick button property */}
        <Button
          style={{ float: 'right', margin: '10px' }}
          variant="text"
          color="primary"
          //disabled={!attachments.length}
          onClick={handleCopyClick}
        >
          <span>{isCopied ? 'Copiado!' : 'Copiar'}</span>
        </Button>
        {/* <button onClick={handleCopyClick}>
          <span>{isCopied ? 'Copied!' : 'Copy'}</span>
        </button> */}
      </Box>
    );
  };

  return (
    <>
      <DoctariModal
        open={attachmentModalOpen}
        setOpen={(val) => setAttachmentModalOpen(val)}
        title={`${capitalize(t('word.attach'))} ${t('word.studies')}`}
      >
        <DropzoneArea
          useChipsForPreview={true}
          dropzoneText={`${t('sentence.attachedFileModal')}`}
          onChange={(files) => handleAttachmentsChange(files)}
          onDelete={(file) => console.log(file)}
          clearOnUnmount={false}
          filesLimit={3}
          initialFiles={attachments}
          getFileAddedMessage={(file) => `${file} añadido`}
          getFileRemovedMessage={(file) => `${file} eliminado`}
          getFileLimitExceedMessage={(limit) =>
            `${t('sentence.attachedFileModal2')} ${limit} ${t('word.files')}`
          }
        />
        <Button
          style={{ float: 'right', margin: '10px' }}
          variant="contained"
          color="primary"
          disabled={!attachments.length}
          onClick={() => setAttachmentModalOpen(false)}
        >
          {`${capitalize(t('word.accept'))}`}
        </Button>
        <Button
          style={{ float: 'right', margin: '10px' }}
          onClick={() => {
            setAttachments([]);
            setAttachmentModalOpen(false);
          }}
          variant="outlined"
          color="primary"
        >
          Cancelar
        </Button>
      </DoctariModal>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          md={12}
          justifyContent="space-between"
          className={styles.menuContainer}
        >
          <h2 className={styles.title}>{`${capitalize(
            t('word.create')
          )} consulta`}</h2>
          <CardHeader
            avatar={
              <Avatar
                alt="Foto de perfil"
                src={
                  location.state.schedule.proficient.profile_picture
                    ? location.state.schedule.proficient.profile_picture.url
                    : null
                }
                className={styles.avatar}
              />
            }
            title={location.state.schedule.proficient.full_name}
            // subheader={location.state.schedule.proficient.specialty.name}
          />
        </Grid>
        <Grid item className={styles.formConteiner}>
          <Paper
            elevation={8}
            style={{ padding: '40px 40px', marginBottom: '5rem' }}
          >
            <Grid item container spacing={2} className={styles.testContainer}>
              <Grid className={styles.firstPaperContainer}>
                <p>
                  <b>{`${t('Appointment.appointment-date')}`}</b>
                  {` ${moment(location.state.schedule.date).format(
                    'DD-MM-YYYY HH:mm [hs]'
                  )}`}
                </p>
                {/* <p>
                  {`${t('Appointment.value')}`} <b>$200</b>{' '}
                </p> */}
              </Grid>
              <Grid item md={12} xs={12} style={{ height: '4rem' }}>
                <form onSubmit={handleGetClient}>
                  <Grid item md={6} xs={12} className={styles.paperGridItems}>
                    <FormControl
                      className={styles.formControl}
                      error={identity_document === ''}
                    >
                      <TextField
                        id="my-input"
                        // label="Ingrese documento del paciente *"
                        // type="date"
                        placeholder={`${capitalize(
                          t('word.enter')
                        )} documento ${t('prep.of-1')} ${t('word.client')} *`}
                        variant="standard"
                        defaultValue={identity_document}
                        onChange={(e) => setIdentityDocument(e.target.value)}
                        // InputProps={{
                        //   endAdornment: (
                        //     <InputAdornment position="start">
                        //       <SearchIcon />
                        //     </InputAdornment>
                        //   ),
                        // }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton
                                className={styles.iconSearchButton}
                                onClick={handleGetClient}
                              >
                                <SearchOutlined />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        // error={!isOlderThan18(dateOfBirth)}
                      />
                    </FormControl>
                  </Grid>
                </form>
              </Grid>
              {/* <Grid item md={12} xs={12} className={styles.paperGridItems}>
                <h2>Datos del dueño</h2>
              </Grid> */}
              <Grid item md={6} xs={12} className={styles.paperGridItems}>
                <FormControl
                  className={styles.formControl}
                  error={name === ''}
                  // disabled={isExistClient}
                >
                  <InputLabel htmlFor="my-input">
                    {`${t('PersonalData.name')}`}
                  </InputLabel>
                  <Input
                    id="my-input"
                    aria-describedby="my-helper-text"
                    onChange={(e) =>
                      setDataClient({ ...dataClient, name: e.target.value })
                    }
                    value={name || ''}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12} className={styles.paperGridItems}>
                <FormControl
                  className={styles.formControl}
                  error={lastName === ''}
                  // disabled={isExistClient}
                >
                  <InputLabel htmlFor="my-input">
                    {`${t('PersonalData.last-name')}`}
                  </InputLabel>
                  <Input
                    id="my-input"
                    aria-describedby="my-helper-text"
                    onChange={(e) =>
                      setDataClient({
                        ...dataClient,
                        lastName: e.target.value,
                      })
                    }
                    value={lastName || ''}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12} className={styles.paperGridItems}>
                <FormControl
                  className={styles.formControl}
                  // disabled={isExistClient}
                >
                  <InputLabel htmlFor="my-input-phone">
                    {`${t('PersonalData.phone')}`}
                  </InputLabel>
                  <Input
                    id="my-input-phone"
                    aria-describedby="my-helper-text"
                    onChange={(e) =>
                      setDataClient({ ...dataClient, phone: e.target.value })
                    }
                    value={phone || ''}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12} className={styles.paperGridItems}>
                <FormControl
                  className={styles.formControl}
                  error={email && !validateEmail(email)}
                  // disabled={isExistClient}
                >
                  <InputLabel htmlFor="my-input">Email *</InputLabel>
                  <Input
                    id="my-input"
                    aria-describedby="my-helper-text"
                    onChange={(e) =>
                      setDataClient({ ...dataClient, email: e.target.value })
                    }
                    value={email || ''}
                    required
                  />
                  {email && !validateEmail(email) && (
                    <FormHelperText id="standard-adornment-password-text" error>
                      Formato inválido
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  id="standard-basic"
                  label={`${t('Appointment.appointment-reason')} / ${capitalize(
                    t('word.description')
                  )} ${t('prep.of-the')} ${t('word.failure')} `}
                  variant="standard"
                  style={{ width: '100%' }}
                  className={styles.paperGridItems}
                  onChange={handleInputCHange}
                  placeholder={`${t('sentence.placeholderAppointmentReason')}`}
                />
              </Grid>
              <Grid item md={6} xs={12} className={styles.paperGridItems}>
                <FormControl
                  className={styles.formControl}
                  error={appointmentType == null}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={SERVICES}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: 300 }}
                    onChange={(event, value) => setAppointmentType(value)}
                    value={appointmentType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`Tipo de ${capitalize(t('word.service'))} *`}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <div className={styles.forthtPaperContainer}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={checked}
                        onChange={handleCheckChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={`${t('Appointment.agreement')}`}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    className={styles.buttons}
                    color="primary"
                    onClick={() => handleCancelAppointment()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    className={styles.buttons}
                    color="primary"
                    disabled={
                      !name ||
                      !lastName ||
                      !validateEmail(email) ||
                      !checked ||
                      !identity_document ||
                      !appointmentType
                    }
                    onClick={() => {
                      setLoading(true);
                      handleClient();
                    }}
                  >
                    {loading
                      ? `${t('word.loading')}`
                      : `${t('word.create')} consulta`}
                  </Button>
                </Grid>
              </div>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ session, user, appointments, doctors }) => {
  return {
    session,
    userData: user,
    appointmentsData: appointments,
    doctors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUser: (type, id, token) =>
    dispatch({
      type: userActionsTypes.GET_USER,
      payload: { type, id },
    }),
  addUserInfo: (type, newValue) =>
    dispatch({
      type: appointmentActionsTypes.ADD_INFORMATION_APPOINTMENT,
      payload: { type, newValue },
    }),
  createInfo: (type, body, data, isAssistant) =>
    dispatch({
      type: userActionsTypes.CREATE_INFORMATION,
      payload: { type, body, data, isAssistant },
    }),
  deleteUserInfo: (type, id) =>
    dispatch({
      type: appointmentActionsTypes.DELETE_INFORMATION_APPOINTMENT,
      payload: { type, id },
    }),
  createAppointmentLight: (
    scheduleId,
    reason,
    doctor,
    patient,
    token,
    allergiesObjects,
    medicationsObjects,
    diseasesObjects
  ) =>
    dispatch({
      type: appointmentActionsTypes.CREATE_APPOINTMENT_LIGHT,
      payload: {
        scheduleId,
        reason,
        doctor,
        patient,
        token,
        allergiesObjects,
        medicationsObjects,
        diseasesObjects,
      },
    }),
  uploadAppointmentFiles: (body) =>
    dispatch({
      type: appointmentActionsTypes.UPLOAD_APPOINTMENT_FILES,
      payload: { body },
    }),
  deleteAttachmentMessages: () =>
    dispatch({
      type: appointmentActionsTypes.DELETE_ATTACHMENT_MESSAGES,
    }),
  getPatients: (currentPage) =>
    dispatch({ type: userActionsTypes.GET_PATIENTS, payload: { currentPage } }),
  deleteSchedule: (token, scheduleId, isMultiple) =>
    dispatch({
      type: appointmentActionsTypes.DELETE_SCHEDULE,
      payload: {
        token,
        scheduleId,
        isMultiple,
      },
    }),
  getPatientAnonymous: (id) =>
    dispatch({
      type: userActionsTypes.GET_PATIENT_ANONYMOUS,
      payload: { id },
    }),
  createPatientAnonymous: (body) =>
    dispatch({
      type: userActionsTypes.CREATE_PATIENT_ANONYMOUS,
      payload: { body },
    }),
  clearInformationAppointment: () =>
    dispatch({
      type: appointmentActionsTypes.CLEAR_INFORMATIONS_APPOINTMENT,
    }),
  clearPatientAnonymous: () =>
    dispatch({
      type: userActionsTypes.CLEAR_PATIENT_ANONYMOUS,
    }),
  getClient: (id) =>
    dispatch({
      type: userActionsTypes.GET_CLIENT,
      payload: { id },
    }),
  createClient: (body) =>
    dispatch({
      type: userActionsTypes.CREATE_CLIENT,
      payload: { body },
    }),
  createAppointmentProficient: (appointmentType, body) =>
    dispatch({
      type: appointmentActionsTypes.CREATE_APPOINTMENT_PROFICIENT,
      payload: { appointmentType, body },
    }),
  clearClient: () =>
    dispatch({
      type: userActionsTypes.CLEAR_CLIENT,
    }),
  updateClient: (id, body) => {
    dispatch({
      type: userActionsTypes.UPDATE_CLIENT,
      payload: { id, body },
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentProficient);
