import { Paper } from '@material-ui/core';
import React from 'react';
import AppointmentCard from './appointmentCard';
import DoctariSpinner from '../../components/Spinner';

import { useTranslation } from 'react-i18next';

export const MyAppointments = ({
  appointmentData,
  theme,
  isProfessional,
  classes,
  session,
  getNewAccessToken,
  userState,
  appointmentLoading,
}) => {
  const [t, i18n] = useTranslation('global');
  return (
    <Paper className={classes.doctorAppointments} elevation={4}>
      {/* //TODO check appointmentData.loading and show the spinner, we need to have
      more than one loading (schedule, scheduleRequest and appointment) */}
      <>
        <h1
          className={classes.title}
          style={{ fontSize: '33px', marginBottom: 0 }}
        >
          {t('MyAppointments.my-appointments')}
        </h1>
        {appointmentData.loading ? (
          <DoctariSpinner />
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                padding:
                  appointmentData.appointments &&
                  appointmentData.appointments.length > 0
                    ? '3rem'
                    : '0',
              }}
            >
              {appointmentData.appointments &&
                appointmentData.appointments.length > 0 &&
                appointmentData.appointments.map((appointment) => (
                  <AppointmentCard
                    key={appointment.id}
                    appointment={appointment}
                    isProfessional={isProfessional}
                    theme={theme}
                    session={session}
                    getNewAccessToken={getNewAccessToken}
                    userState={userState}
                  />
                ))}
            </div>
            {appointmentData.appointments &&
              appointmentData.appointments.length == 0 && (
                <p style={{ fontWeight: 300 }}>
                  {t('MyAppointments.no-appointments')}
                </p>
              )}
          </>
        )}
      </>
    </Paper>
  );
};
