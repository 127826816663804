import { call, put, takeEvery, all, retry } from 'redux-saga/effects';
import { store } from '../store';
import { userActionsTypes, userActions } from './actions';
import API from '../../API/endpoints';
import { isProfessional } from '../../utils/helpers';
import { doctorsActions } from '../doctor/actions';

// Worker saga will be fired on USER_LOGIN actions
function* loginUser(action) {
  yield put(userActions.loading(true));
  try {
    const user = yield call(API.login, action.payload);
    yield put(userActions.successLogin(user.data));
  } catch (e) {
    yield put(userActions.error('Nombre de usuario o contraseña inválidos'));
  }
  yield put(userActions.loading(false));
}

function* tokenUserLogin(action) {
  // logs the user with a token passed in url
  yield put(userActions.loading(true));
  try {
    const user = yield call(API.tokenLogin, action.payload);
    yield put(userActions.successTokenLogin(user.data));
  } catch (e) {
    yield put(userActions.error('Token inváido'));
  }
  yield put(userActions.loading(false));
}

function* createUser(action) {
  yield put(userActions.loading(true));
  try {
    const user = yield call(
      API.signup,
      action.payload.type,
      action.payload.body
    );
    if (!isProfessional(action.payload.type)) {
      yield put(
        userActions.successSignupPatient(
          'Se ha enviado un mail para verificar su cuenta'
        )
      );
    } else {
      yield put(
        userActions.successCreateProfessional(
          'Se ha enviado un mail para verificar su cuenta'
        )
      );
    }
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    if (
      e.response.data.email[0] == 'Person with this Email already exists.' ||
      e.response.data.identity_document[0] ==
        'Person with this Identity document already exists.'
    ) {
      yield put(
        userActions.error(
          'Error: usuario con este email y/o documento ya existe'
        )
      );
    } else {
      yield put(userActions.error('Error al crear el usuario'));
    }
  }
  yield put(userActions.loading(false));
}

function* getInitialData() {
  yield put(userActions.loading(true));
  try {
    const [countries, specialities] = yield all([
      call(API.getCountries),
      call(API.getSpecilities),
    ]);

    const data = {
      countries: countries.data,
      specialities: specialities.data,
    };

    yield put(userActions.successGetSignupData(data));
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('Error al traer los datos'));
  }
  yield put(userActions.loading(false));
}

function* getUserData(action) {
  yield put(userActions.loading(true));
  try {
    let data = {};

    if (!isProfessional(action.payload.type)) {
      const [countries, specialities, medications, allergies, diseases, user] =
        yield all([
          call(API.getCountries),
          call(API.getSpecilities),
          call(API.getInformation, 'medications', action.payload.token),
          call(API.getInformation, 'allergies', action.payload.token),
          call(API.getInformation, 'diseases', action.payload.token),
          call(API.getUser, action.payload.type, action.payload.id),
        ]);
      data = {
        countries: countries.data,
        specialities: specialities.data,
        user: user.data,
        medications: medications.data,
        allergies: allergies.data,
        diseases: diseases.data,
      };
    } else {
      const [countries, specialities, user] = yield all([
        call(API.getCountries),
        call(API.getSpecilities),
        call(API.getUser, action.payload.type, action.payload.id),
      ]);
      data = {
        countries: countries.data,
        specialities: specialities.data,
        user: user.data,
      };
    }
    if (action.payload.type == 'operator') {
      const { token, urlParams } = action.payload;

      const [proficient] = yield all([
        // call(API.getDoctors, urlParams, token),
        call(API.getProficients, token),
      ]);
      const doctor = yield call(
        API.getDoctors,
        'paginated=false&is_active=true',
        token
      );
      data.user['doctors'] = doctor.data;

      // data.user['doctors'] = doctor.data.results;
      data.user['proficients'] = proficient.data.results;
    }

    yield put(userActions.successGetUser(data));
  } catch (err) {
    if (err.response.status === 401) {
      throw err;
    }
    yield put(userActions.error('Error al traer los datos'));
  }
  yield put(userActions.loading(false));
}

function* getUserInformation(action) {
  yield put(userActions.loading(true));
  try {
    let data = {};
    if (!isProfessional(action.payload.type)) {
      const [medications, allergies, diseases] = yield all([
        call(API.getInformation, 'medications', action.payload.token),
        call(API.getInformation, 'allergies', action.payload.token),
        call(API.getInformation, 'diseases', action.payload.token),
      ]);

      data = {
        medications: medications.data,
        allergies: allergies.data,
        diseases: diseases.data,
      };
    }
    yield put(userActions.successGetUserInfo(data));
  } catch (err) {
    if (err.response.status === 401) {
      throw err;
    }
    yield put(userActions.error('Error al traer la informacion del usuario'));
  }
}

function* updateUserData(action) {
  yield put(userActions.loading(true));
  try {
    const {
      type,
      id,
      body,
      isAssistant = false,
      isProfileDoctor = false,
      token,
    } = action.payload;
    const response = yield call(API.updateUser, type, id, body, token);
    if (isAssistant) {
      yield put(
        userActions.successUpdateActiveDoctor('Active actualizado con exito')
      );
    } else if (isProfileDoctor) {
      yield put(
        doctorsActions.successUpdateDoctorAssistant({
          message: 'Datos del doctor actualizado',
          data: response.data,
        })
      );
    } else {
      if (type == 'operator') {
        const doctors = yield call(API.getDoctors, []);
        response.data['doctors'] = doctors.data.results;
      }
      yield put(
        userActions.successUpdateUser({
          message: 'Datos actualizado con éxito',
          data: response.data,
        })
      );
    }
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('Error al traer los datos'));
  }
  yield put(userActions.loading(false));
}

function* updateUserPasswd(action) {
  yield put(userActions.loading(true));
  try {
    const { body, token } = action.payload;

    yield call(API.changePassword, body, token);

    yield put(
      userActions.successUpdatePassword('Contraseña modificada con éxito')
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('Error al actualizar contraseña'));
  }
  yield put(userActions.loading(false));
}

function* updateUserPic(action) {
  yield put(userActions.loading(true));
  try {
    const { body, token } = action.payload;

    const response = yield call(API.uploadeProfilePicture, body, token);

    yield put(
      userActions.successUpdateUserPic({
        message:
          'Se ha actualizado su perfil, el cambio ya ha sido aplicado pero puede tardar en verse reflejado',
        url: response.data.url,
      })
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('Error al subir la foto de perfil'));
  }
  yield put(userActions.loading(false));
}

function* manageAddresses(action) {
  yield put(userActions.loading(true));
  try {
    const { type, id, body, token } = action.payload;
    const response = yield call(API.manageAddresses, id, type, body, token);
    if (type === 'POST') {
      const resp = {
        address: response.data,
        message: 'La nueva dirección se ha creado con éxito',
      };
      yield put(userActions.successCreateAddress(resp));
    } else {
      const resp = {
        address: id,
        message: 'La dirección se ha eliminado con éxito',
      };
      yield put(userActions.successDeleteAddress(resp));
    }
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('Error al traer los datos'));
  }
  yield put(userActions.loading(false));
}

// Dynamically manage on create new medication/disease/allergie
// First the information is created and then add it to the patient
function* createInformation(action) {
  yield put(userActions.loading(true));
  try {
    const { type, body, data, isAssistant = false, token } = action.payload;

    const response = yield retry(
      2,
      5000,
      API.createInformation,
      type,
      body,
      token
    );
    if (!isAssistant) {
      yield retry(
        2,
        5000,
        API.addUserInformation,
        data.url,
        { [data.id]: response.data.id },
        token
      );

      yield put(
        userActions.successCreateInformation({ type, value: response.data })
      );
    } else {
      yield put(
        userActions.successCreateInformationAppointment({
          type,
          value: response.data,
        })
      );
    }
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('No se pudo agregar la info al paciente'));
  }
  yield put(userActions.loading(false));
}

//Only add the information (medication/disease/allergie) to the user
function* addInformationToUser(action) {
  yield put(userActions.loading(true));
  try {
    const { type, data, newValue, token } = action.payload;

    yield retry(
      2,
      5000,
      API.addUserInformation,
      data.url,
      { [data.id]: newValue.id },
      token
    );

    yield put(userActions.successAddInformation({ type, value: newValue }));
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('Error al traer los datos'));
  }
  yield put(userActions.loading(false));
}

function* deleteInformationToUser(action) {
  yield put(userActions.loading(true));
  try {
    const { type, data, valueId, token } = action.payload;
    yield retry(
      2,
      5000,
      API.deleteUserInformation,
      data.url,
      { [data.id]: valueId },
      token
    );

    yield put(userActions.successDeleteInformation({ type, id: valueId }));
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('Error al traer los datos'));
  }
  yield put(userActions.loading(false));
}

function* getMpPreference(action) {
  yield put(userActions.loading(true));
  try {
    const { body, token } = action.payload;

    const response = yield call(API.getMpPreference, body, token);

    yield put(userActions.successGetUserMpPreference(response.data));
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('Error al traer los datos'));
  }
  yield put(userActions.loading(false));
}

function* getPasswordResetToken(action) {
  yield put(userActions.loading(true));
  try {
    const response = yield call(API.getPasswordResetToken, action.payload);

    yield put(
      userActions.successGetPasswordResetToken(
        'Fue enviado un mail con el link para resetear su contraseña'
      )
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(
      userActions.error('Error al solocitar token de reseteo de contraseña')
    );
  }
  yield put(userActions.loading(false));
}

function* confirmResetPassword(action) {
  yield put(userActions.loading(true));
  try {
    const response = yield call(API.confirmResetPassword, action.payload);

    yield put(
      userActions.successConfirmResetPassword(
        'La contraseña se reseteo con éxito'
      )
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('Error al resetear la contraseña'));
  }
  yield put(userActions.loading(false));
}

function* getNewAccessToken(action) {
  yield put(userActions.loading(true));
  try {
    const response = yield call(API.refreshToken, {
      refresh: action.payload,
    });

    yield put(
      userActions.successTokenRefresh({
        message: 'Nuevo token obtenido con éxito',
        token: response.data,
      })
    );
  } catch (e) {
    yield put(userActions.error('Token inválido'));
  }
  yield put(userActions.loading(false));
}

function* getPatients(action) {
  yield put(userActions.loading(true));
  try {
    const { token, queryParams, queryValues } = action.payload;
    let urlParams = ``;
    if (queryParams.length > 0) {
      for (let i = 0; i < queryParams.length; i++) {
        i == 0
          ? (urlParams += `?${queryParams[i]}=${queryValues[i]}`)
          : (urlParams += `&${queryParams[i]}=${queryValues[i]}`);
      }
    }

    const response = yield call(API.getPatients, urlParams, token);

    yield put(userActions.successGetPatients(response.data));
  } catch (err) {
    if (err.response.status === 401) {
      throw err;
    }
    yield put(userActions.error('Error al traer los pacientes'));
  }
}

function* getPatientAnonymous(action) {
  yield put(userActions.loading(true));
  try {
    const response = yield call(
      API.getPatientAnonymous,
      action.payload.id,
      action.payload.token
    );

    yield put(userActions.successGetPatientAnonymous(response.data));
  } catch (err) {
    if (err.response.status === 401) {
      throw err;
    }
    yield put(userActions.error('Error al traer los pacientes'));
  }
}

function* createPatientAnonymous(action) {
  yield put(userActions.loading(true));
  try {
    const user = yield call(
      API.createPatientAnonymous,
      action.payload.body,
      action.payload.token
    );

    yield put(
      userActions.successCreatePatientAnonymous({
        message: 'Paciente anonimo creado con exito',
        patient: user.data,
      })
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('Error al crear el usuario'));
  }
  yield put(userActions.loading(false));
}

function* getClient(action) {
  yield put(userActions.loading(true));
  try {
    const { token, id } = action.payload;
    const response = yield call(API.getClient, id, token);

    yield put(userActions.successGetClient(response.data));
  } catch (err) {
    if (err.response.status === 401) {
      throw err;
    }
    yield put(userActions.error('Error al traer los datos'));
  }
}

function* createClient(action) {
  yield put(userActions.loading(true));
  try {
    const { token, body } = action.payload;
    const user = yield call(API.createClient, body, token);

    yield put(
      userActions.successCreateClient({
        message: 'Cliente creado con exito',
        client: user.data,
      })
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('Error al crear el usuario'));
  }
  yield put(userActions.loading(false));
}

function* getPetsPatient(action) {
  yield put(userActions.loading(true));
  try {
    const { token, idPatient } = action.payload;
    const response = yield call(API.getPetsPatient, idPatient, token);

    yield put(userActions.successGetPets(response.data));
  } catch (err) {
    if (err.response.status === 401) {
      throw err;
    }
    yield put(userActions.error('Error al traer los datos'));
  }
}

function* createPetsPatient(action) {
  yield put(userActions.loading(true));
  try {
    const { token, body } = action.payload;
    const response = yield call(API.createPetsPatient, body, token);

    // yield put(userActions.successGetPets(response.data));
  } catch (err) {
    if (err.response.status === 401) {
      throw err;
    }
    yield put(userActions.error('Error al crear la mascota'));
  }
}

function* updatePatientAnonymous(action) {
  yield put(userActions.loading(true));
  try {
    const { id, body, token } = action.payload;
    const user = yield call(API.updatePatientAnonymous, id, body, token);
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('Error al realizar la operación'));
  }
  yield put(userActions.loading(false));
}

function* updateClient(action) {
  yield put(userActions.loading(true));
  try {
    const { id, body, token } = action.payload;
    const user = yield call(API.updateClient, id, body, token);
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('Error al realizar la operación'));
  }
  yield put(userActions.loading(false));
}

function* updateSignature(action) {
  yield put(userActions.loading(true));
  try {
    const { body, token } = action.payload;

    const response = yield call(API.updateSignature, body, token);

    yield put(
      userActions.successUpdateUserSignature({
        message:
          'Se ha actualizado la firma, el cambio ya ha sido aplicado pero puede tardar en verse reflejado',
        url: response.data.url,
      })
    );
  } catch (e) {
    if (e.response.status === 401) {
      throw e;
    }
    yield put(userActions.error('Error al subir la firma'));
  }
  yield put(userActions.loading(false));
}

export function refreshToken(saga) {
  return function* (action) {
    yield put(userActions.loading(true));
    const actionToken = { payload: { ...action.payload, token: true } };
    try {
      yield* saga(actionToken);
    } catch (error) {
      if (
        error.response.status === 401 &&
        error.response.data.code === 'token_not_valid'
      ) {
        const reduxStore = store;
        try {
          //Try refresh token
          const token = yield call(API.refreshToken, {
            refresh: reduxStore.getState().session.refreshToken,
          });
          yield put(
            userActions.successTokenRefresh({
              message: 'Nuevo token obtenido con éxito',
              token: token.data,
            })
          );
          yield* saga(actionToken);
        } catch (error) {
          if (
            error.response.status === 401 &&
            error.response.data.code === 'token_not_valid'
          ) {
            yield put(userActions.logoutUser()); //Refresh token expired
          }
        }
      }
    }
    yield put(userActions.loading(false));
  };
}

export default function* userSaga() {
  yield takeEvery(userActionsTypes.USER_LOGIN, loginUser);
  yield takeEvery(userActionsTypes.USER_TOKEN_LOGIN, tokenUserLogin);
  yield takeEvery(userActionsTypes.GET_SIGNUP_DATA, getInitialData);
  yield takeEvery(userActionsTypes.USER_SIGNUP, createUser);
  yield takeEvery(
    userActionsTypes.CONFIRM_RESET_PASSWORD,
    confirmResetPassword
  );
  yield takeEvery(
    userActionsTypes.GET_PASSWORD_RESET_TOKEN,
    getPasswordResetToken
  );

  yield takeEvery(userActionsTypes.GET_USER, refreshToken(getUserData));
  yield takeEvery(
    userActionsTypes.GET_USER_INFO,
    refreshToken(getUserInformation)
  );
  yield takeEvery(userActionsTypes.UPDATE_USER, refreshToken(updateUserData));
  yield takeEvery(
    userActionsTypes.CREATE_INFORMATION,
    refreshToken(createInformation)
  );
  yield takeEvery(
    userActionsTypes.ADD_INFO_USER,
    refreshToken(addInformationToUser)
  );
  yield takeEvery(
    userActionsTypes.DELETE_INFO_USER,
    refreshToken(deleteInformationToUser)
  );
  yield takeEvery(
    userActionsTypes.UPDATE_ADDRESS,
    refreshToken(manageAddresses)
  );
  yield takeEvery(
    userActionsTypes.UPDATE_PASSWORD,
    refreshToken(updateUserPasswd)
  );
  yield takeEvery(
    userActionsTypes.UPDATE_USER_PIC,
    refreshToken(updateUserPic)
  );
  yield takeEvery(
    userActionsTypes.GET_USER_MP_PREFERENCE,
    refreshToken(getMpPreference)
  );
  yield takeEvery(userActionsTypes.REFRESH_TOKEN, getNewAccessToken);
  yield takeEvery(userActionsTypes.GET_PATIENTS, refreshToken(getPatients));
  yield takeEvery(
    userActionsTypes.GET_PATIENT_ANONYMOUS,
    refreshToken(getPatientAnonymous)
  );
  yield takeEvery(
    userActionsTypes.CREATE_PATIENT_ANONYMOUS,
    refreshToken(createPatientAnonymous)
  );
  yield takeEvery(
    userActionsTypes.UPDATE_PATIENT_ANONYMOUS,
    refreshToken(updatePatientAnonymous)
  );
  yield takeEvery(userActionsTypes.GET_CLIENT, refreshToken(getClient));
  yield takeEvery(userActionsTypes.CREATE_CLIENT, refreshToken(createClient));
  yield takeEvery(
    userActionsTypes.GET_PETS_PATIENT,
    refreshToken(getPetsPatient)
  );
  yield takeEvery(
    userActionsTypes.CREATE_PETS_PATIENT,
    refreshToken(createPetsPatient)
  );
  yield takeEvery(userActionsTypes.UPDATE_CLIENT, refreshToken(updateClient));
  yield takeEvery(
    userActionsTypes.UPDATE_USER_SIGNATURE,
    refreshToken(updateSignature)
  );
}
