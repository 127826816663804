import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  formContainer: {
    fontFamily: theme.typography.fontFamily,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '75%',
    '@media (max-width: 900px)': {
      width: '95%',
      flexDirection: 'column',
      justifyContent: 'initial',
      alignItems: 'initial',
      pading: '1rem',
      boxShadow: 'none',
    },
  },
  formControl: {
    fontFamily: theme.typography.fontFamily,
    marginBottom: '1rem',
    width: '14%',
    '@media (max-width: 900px)': {
      width: '100%',
    },
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.lightPrimary.main,
    fontSize: '42px',
    fontWeight: '400',
  },
  sortButtons: {
    fontFamily: theme.typography.fontFamily,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '70%',
    flexWrap: 'wrap',
    marginTop: '1rem',
    '@media (max-width: 900px)': {
      width: '95%',
      fontWeight: 200,
    },
  },
  availabilityContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    '@media (max-width: 900px)': {
      marginTop: '1rem',
      justifyContent: 'start',
    },
  },
  typeAvailability: {
    margin: '0 0.5rem 0 0.5rem',
    fontWeight: 300,
    fontSize: '15px',
  },
  doctorAppointments: {
    backgroundColor: '#f1f1f1',
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    padding: '30px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    border: `solid 2px ${theme.palette.lightPrimary.main}`,
    marginRight: '1rem',
    width: '5rem',
    height: '5rem',
  },
}));
