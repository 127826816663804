export const HOMEPAGE = '/home';
export const LOGIN = '/login';
export const PROFILE = '/profile';
export const APPOINTMENTS = '/appointments/list';
export const AGENDA = '/agenda';
export const HISTORIC_APPOINTMENTS = '/historic_appointments';
export const SCHEDULE_REQUESTS = '/schedule_requests';
export const DOCTOR = '/doctor';
export const DOCTOR_BASE = '/doctor';
export const APPOINTMENT = '/appointment';
export const PAYMENT = '/payment/:appointmentId';
export const BASE_PAYMENT = '/payment/';
export const APPOINTMENT_DETAILS = '/appointment_details';
export const USER_LIST = '/assistant/list';
export const ASSISTANTS_DOCTORS_APPOINTMENTS =
  '/assistant/doctors_appointments';
export const APPOINTMENT_LIGHT = '/appointment_light';
export const APPOINTMENT_VETERINARY = '/appointment_veterinary';
export const APPOINTMENT_PROFICIENT = '/appointment_proficient';
export const VIDEO_CALL_TEST = '/video-call-test';
