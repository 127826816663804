import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    height: '5rem',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    height: '3rem',
    '@media (max-width: 900px)': {
      height: '2.5rem',
    },
  },
  headerLogin: {
    backgroundColor: theme.palette.primary.main,
    '@media (max-width: 900px)': {
      paddingLeft: 0,
    },
  },
  menuButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    marginRight: '2rem',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerContainer: {
    padding: '10px 20px',
  },
  menuContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '60%',
    fontFamily: theme.typography.fontFamily,
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '2%',
    ['@media (max-width: 900px)']: {
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'column',
      marginBottom: '2%',
    },
  },

  formConteiner: {
    width: '60%',
    ['@media (max-width: 900px)']: {
      width: '100%',
    },
  },
  testContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    marginBottom: '5%',
  },
  firstPaperContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    flexWrap: 'wrap',
  },
  secondPaperContainer: {
    width: '100%',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    marginBottom: '5%',
  },
  thirdPaperContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    marginBottom: '5%',
  },
  thirdPaperContainerInputs: {
    // ver de poner este tipo de inputs como inline style
    width: '45%',
    fontFamily: theme.typography.fontFamily,
    marginRight: '5%',
    marginBottom: '5%',
  },
  forthtPaperContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    flexWrap: 'wrap',
    width: '100%',
  },

  //Avatar:
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    cursor: 'pointer',
  },
  avatar: {
    border: 'solid 2px #b8f3f3',
    // marginRight: '1rem',
  },
  avatarText: {
    flexDirection: 'column',
    margin: '0',
  },
  title: {
    color: theme.palette.primary.main,
    marginTop: '40px',
  },
  paperGridItems: {
    marginBottom: '2%',
  },
  buttons: {
    marginRight: '10px',
    ['@media (max-width: 900px)']: {
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: '5%',
    },
  },
}));
