import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import DoctariCalendar from '../../components/DoctariCalendar';
import LinearLoader from '../../components/LinearLoader';
import Notification from '../../components/Notification';
import { AGENDA } from '../../utils/urls';
import { MyAppointments } from './MyAppointments';

import { useTranslation } from 'react-i18next';

function Doctor({
  doctor,
  classes,
  doctorsInfo,
  appointmentsData,
  deleteMessages,
  getSchedulesByDate,
  getAppointments,
  clearAppointments,
  getNewAccessToken,
  userState,
}) {
  const theme = useTheme();
  const history = useHistory();
  const [t, i18n] = useTranslation('global');

  const [state, setState] = useState({
    dateOfAppointment: null,
    doctorName: null,
    specialty: null,
    country: null,
    type: null,
    availability: true,
    score: false,
  });

  const [appointmentLoading, setAppointmentLoading] = useState(true);

  useEffect(() => {
    if (doctor && doctor.id) {
      getSchedulesByDate(
        moment()
          .day(1)
          .set('hour', '00')
          .set('minute', '00')
          .add(0, 'days')
          .tz('America/Montevideo')
          .format('YYYY-MM-DD HH:mm'),
        moment()
          .day(1)
          .add(7, 'days')
          .tz('America/Montevideo')
          .format('YYYY-MM-DD HH:mm'),
        ['doctor'],
        [doctor.id]
      );
    }
  }, []);

  useEffect(() => {
    if (doctor && doctor.id) {
      let queryParams = ['date__gt'];
      let queryValues = [
        moment().subtract(30, 'minutes').format('YYYY-MM-DD HH:mm'),
      ];

      getAppointments(
        'doctor',
        doctor.id,
        false, //isDone
        queryParams,
        queryValues
      );
    }
    return () => clearAppointments();
  }, []);

  useEffect(() => {
    if (
      appointmentsData.appointments &&
      appointmentsData.appointments.length >= 0
    ) {
      setAppointmentLoading(false);
    }
  }, [appointmentsData.appointments]);

  return (
    <>
      {doctorsInfo.loading && <LinearLoader />}

      {appointmentsData.success.genericMessage ==
        'Appointment eliminado con exito' && (
        <Notification
          type="error"
          message={'Consulta cancelada'}
          onCloseCallback={deleteMessages}
        />
      )}
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ flexWrap: 'initial', fontFamily: theme.typography.fontFamily }}
      >
        <MyAppointments
          appointmentData={appointmentsData}
          theme={theme}
          isProfessional={true}
          classes={classes}
          session={doctor}
          getNewAccessToken={getNewAccessToken}
          userState={userState}
          appointmentLoading={appointmentLoading}
        ></MyAppointments>
        <Grid item xs={11} md={12}>
          <h1
            className={classes.title}
            style={{ fontSize: '40px', textAlign: 'center' }}
          >
            {t('Calendar.my-agenda')}
          </h1>
          <DoctariCalendar
            currentWeek={0}
            schedules={appointmentsData.schedules}
            slot={doctor.slot}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ width: '206px' }}
              onClick={() =>
                history.push({
                  pathname: AGENDA,
                  state: {
                    specialtyType: userState.user.specialty.name,
                  },
                })
              }
            >
              {t('Calendar.edit-calendar')}
            </Button>
          </DoctariCalendar>
        </Grid>
      </Grid>
    </>
  );
}

export default Doctor;
