import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Box, Avatar, CardHeader } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useLocation } from 'react-router';

import AppointmentCard from './AppointmentCard';
import DoctariCalendar from '../../components/DoctariCalendar';
import { appointmentActionsTypes } from '../../redux/appointment/actions';
import { useStyles } from './style';
import DoctariSpinner from '../../components/Spinner';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DateControl from '../agenda/DateControl';

function DoctorAgendaProfile({
  session,
  appointmentsData,
  getSchedulesByDate,
  getAppointments,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const doctor = location.state.doctor;
  const [t, i18n] = useTranslation('global');

  const [dateHandler, setDateHandler] = useState({
    currentWeek: 0,
    currentMonth: 0,
    showByWeek: true,
  });
  const { currentWeek, currentMonth, showByWeek } = dateHandler;

  const [availability, setAvailability] = useState({
    start: null,
    end: null,
    isEditable: false,
    modalOpen: false,
  });
  const { start, end, isEditable, modalOpen } = availability;
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    getSchedulesByDate(
      moment()
        .day(1)
        .set('hour', '00')
        .set('minute', '00')
        .add(currentWeek * 7, 'days')
        .tz('America/Montevideo')
        .format('YYYY-MM-DD HH:mm'),
      moment()
        .day(1)
        .add(currentWeek * 7 + 7, 'days')
        .tz('America/Montevideo')
        .format('YYYY-MM-DD HH:mm'),
      ['doctor'],
      [doctor.id]
    );
  }, [currentWeek]);

  useEffect(() => {
    if (session) {
      getAppointments(
        session.userType,
        session.id,
        true,
        ['doctor'],
        [doctor.id]
      );
    }
  }, []);

  useEffect(() => {
    if (appointmentsData.appointments) {
      setAppointments(appointmentsData.appointments);
    }
  }, [appointmentsData.appointments]);

  return (
    <>
      <Grid container className={classes.agendaContainer}>
        <Grid item xs={12} md={7} className={classes.daysContainer}>
          <CardHeader
            avatar={
              <Avatar
                alt="Foto de perfil"
                src={doctor.profile_picture ? doctor.profile_picture.url : null}
                // className={styles.avatar}
              />
            }
            title={doctor.full_name}
            subheader={doctor.specialty.name}
          />
        </Grid>
        <Grid item xs={12} md={7} className={classes.daysContainerDateControl}>
          <DateControl
            showingByWeek={showByWeek}
            setCurrentWeek={(week) =>
              setDateHandler({ ...dateHandler, currentWeek: week })
            }
            currentWeek={currentWeek}
            setCurrentMonth={(month) =>
              setDateHandler({ ...dateHandler, currentMonth: month })
            }
            currentMonth={currentMonth}
            language={localStorage.getItem('language')}
          />
        </Grid>
        <Grid item xs={12} md={7} className={classes.daysContainer}>
          <DoctariCalendar
            currentWeek={currentWeek}
            creatingAppointment
            availability={availability}
            schedules={appointmentsData.schedules}
            slot={
              session.userType == 'doctor'
                ? session.slot
                : doctor.specialty.duration.minutes
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            backgroundColor: '#f1f1f1',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Grid
            container
            item
            xs={12}
            md={12}
            justifyContent="center"
            alignItems="center"
            direction="column"
            style={{ marginBottom: '5rem' }}
          >
            <p style={{ fontSize: '18px', color: '#4a4a4a' }}>
              {t('DoctorAgendaProfile.past-appointments')}
            </p>
            <Box display="flex" flexWrap="wrap">
              {/* <AppointmentCard theme={theme} />
              <AppointmentCard theme={theme} />
              <AppointmentCard theme={theme} /> */}
              {appointmentsData.loading ? (
                <Box>
                  <DoctariSpinner />
                </Box>
              ) : appointments.length > 0 ? (
                appointmentsData.appointments.map((appointment) => {
                  return (
                    <AppointmentCard theme={theme} appointment={appointment} />
                  );
                })
              ) : (
                <p style={{ fontSize: '16px', color: '#4a4a4a' }}>
                  {t('DoctorAgendaProfile.no-past-appointments')}
                </p>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ session, appointments }) => {
  return {
    session: session,
    appointmentsData: appointments,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSchedulesByDate: (start, end, queryParams, queryValues) =>
    dispatch({
      type: appointmentActionsTypes.GET_SCHEDULES_BY_DATE,
      payload: {
        start,
        end,
        queryParams,
        queryValues,
      },
    }),
  getAppointments: (userType, id, isDone, queryParams, queryValues) =>
    dispatch({
      type: appointmentActionsTypes.GET_APPOINTMENTS, //TODO cambiar el formato de fechas en el back para no tener que hacer todo este juego con format
      payload: {
        userType,
        id,
        isDone,
        queryParams,
        queryValues,
      },
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoctorAgendaProfile);
