import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Main from '../screens/mainpage';
import Profile from '../screens/profile';
import HistoricAppointments from '../screens/historic-appointments';
import Appointments from '../screens/appointments';
import ScheduleRequests from '../screens/schedule-requests';
import Agenda from '../screens/agenda';
import DoctorAgendaProfile from '../screens/doctor-agenda-profile';
import Appointment from '../screens/appointment';
import Payment from '../screens/payment';
import Header from '../components/Header';
import appointmentDetails from '../screens/appointment-details';
import UserList from '../screens/user-list';
import AssistantDoctorsAppointment from '../screens/assistant-doctors-appointments';
import AppointmentLight from '../screens/appointment-light';
import AppointmentVeterinary from '../screens/appointment-veterinary';
import AppointmentProficient from '../screens/appointment-proficient';

import {
  HOMEPAGE,
  PROFILE,
  HISTORIC_APPOINTMENTS,
  APPOINTMENTS,
  SCHEDULE_REQUESTS,
  AGENDA,
  DOCTOR,
  APPOINTMENT,
  PAYMENT,
  APPOINTMENT_DETAILS,
  USER_LIST,
  ASSISTANTS_DOCTORS_APPOINTMENTS,
  APPOINTMENT_LIGHT,
  APPOINTMENT_VETERINARY,
  APPOINTMENT_PROFICIENT,
  VIDEO_CALL_TEST,
} from '../utils/urls';
import VideoCallTest from '../screens/video-call-test';

export const DoctariRoutes = () => {
  return (
    <>
      <Header style={{ position: 'relative', boxShadow: '0 0 0 0' }} />
      <Switch>
        <Route path={HOMEPAGE} component={Main} exact />
        <Route path={PROFILE} component={Profile} exact />
        <Route path={APPOINTMENTS} component={Appointments} exact />
        <Route path={AGENDA} component={Agenda} exact />
        <Route path={SCHEDULE_REQUESTS} component={ScheduleRequests} exact />
        <Route path={DOCTOR} component={DoctorAgendaProfile} exact />
        <Route
          path={HISTORIC_APPOINTMENTS}
          component={HistoricAppointments}
          exact
        />
        <Route path={APPOINTMENT} component={Appointment} exact />
        <Route path={PAYMENT} component={Payment} exact />
        <Route
          path={APPOINTMENT_DETAILS}
          component={appointmentDetails}
          exact
        />
        <Route path={USER_LIST} component={UserList} exact />
        <Route
          path={ASSISTANTS_DOCTORS_APPOINTMENTS}
          component={AssistantDoctorsAppointment}
          exact
        />
        <Route path={APPOINTMENT_LIGHT} component={AppointmentLight} exact />
        <Route
          path={APPOINTMENT_VETERINARY}
          component={AppointmentVeterinary}
          exact
        />
        <Route
          path={APPOINTMENT_PROFICIENT}
          component={AppointmentProficient}
          exact
        />
        <Route path={VIDEO_CALL_TEST} component={VideoCallTest} exact />

        <Redirect to={HOMEPAGE} />
      </Switch>
    </>
  );
};
