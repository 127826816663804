import { proficientsActionsTypes } from './actions';

const PROFICIENTS_INITIAL_STATE = {
  proficients: [],
  numOfPages: 0,
  currentPage: 1,
  countries: [],
  specialities: [],
  error: null,
  success: null,
  loading: false,
  selectedProficient: null,
};

const proficientsReducer = (state = PROFICIENTS_INITIAL_STATE, actions) => {
  let new_state;
  switch (actions.type) {
    case proficientsActionsTypes.SUCCESS_GET_PROFICIENTS:
      let numOfPages = Math.ceil(actions.payload.count / 10);
      new_state = {
        ...state,
        error: null,
        doctors: actions.payload.results,
        numOfPages: numOfPages,
        currentPage: actions.payload.page,
        countries: actions.payload.countries || state.countries,
        specialities: actions.payload.specialities || state.specialities,
      };
      break;
    case proficientsActionsTypes.LOADING:
      new_state = {
        ...state,
        loading: actions.payload,
        error: actions.payload ? null : state.error,
      };
      break;
    case proficientsActionsTypes.ERROR:
      new_state = {
        ...state,
        error: actions.payload,
      };
      break;
    case proficientsActionsTypes.SET_PROFICIENT_BODY:
      new_state = {
        ...state,
        selectedProficient: actions.payload,
      };
      break;
    case proficientsActionsTypes.CLEAR_SELECT_PROFICIENT:
      new_state = {
        ...state,
        selectedProficient: null,
      };
      break;
    case proficientsActionsTypes.LOGOUT:
      new_state = PROFICIENTS_INITIAL_STATE;
      break;
    // case userActionsTypes.LOGOUT:
    //   new_state = DOCTORS_INITIAL_STATE;
    //   break;
    case proficientsActionsTypes.SUCCESS_UPDATE_PROFICIENT_ASSISTANT:
      new_state = {
        ...state,
        selectedProficient: actions.payload.data,
        success: actions.payload.message,
      };
      break;
    case proficientsActionsTypes.CLEAR_SUCCESS_MESSAGE:
      new_state = {
        ...state,
        success: null,
      };
      break;
    default:
      new_state = state;
  }
  return new_state;
};

export { proficientsReducer };
