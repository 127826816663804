import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import {
  Button,
  Link,
  MenuItem,
  Paper,
  Grid,
  Avatar,
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { useStyles } from './style';
import { connect } from 'react-redux';
import { APPOINTMENTS, HOMEPAGE } from '../../utils/urls';
import { userActionsTypes } from '../../redux/user/actions';

import { useHistory, useLocation, useParams } from 'react-router';
import cvvPath from '../../assets/payment/cvv.png';
import visaPath from '../../assets/payment/visa.png';
import masterPath from '../../assets/payment/master.png';
import ocaPath from '../../assets/payment/oca.png';
import mpPath from '../../assets/payment/MP_logo.png';
import axios from 'axios';
import moment from 'moment';
import Notification from '../../components/Notification';

function Payment({ userData, getMpPreference }) {
  const location = useLocation();
  const history = useHistory();

  const { appointmentId } = useParams();
  const [docType, setDocType] = useState('CI');
  const [paymentError, setPaymentError] = useState(false);
  let checkout = '';

  useEffect(() => {
    const cardForm = window.mp.cardForm({
      amount: '200', //TODO now we pass 200, we need to have an amount for every doctor specialty or something like that
      autoMount: true,
      form: {
        id: 'form-checkout',
        cardholderName: {
          id: 'form-checkout__cardholderName',
        },
        cardholderEmail: {
          id: 'form-checkout__cardholderEmail',
        },
        cardNumber: {
          id: 'form-checkout__cardNumber',
        },
        cardExpirationMonth: {
          id: 'form-checkout__cardExpirationMonth',
        },
        cardExpirationYear: {
          id: 'form-checkout__cardExpirationYear',
        },
        securityCode: {
          id: 'form-checkout__securityCode',
        },
        installments: {
          id: 'form-checkout__installments',
        },
        identificationType: {
          id: 'form-checkout__identificationType',
        },
        identificationNumber: {
          id: 'form-checkout__identificationNumber',
        },
        issuer: {
          id: 'form-checkout__issuer',
        },
      },
      callbacks: {
        onFormMounted: (error) => {
          if (error)
            return console.warn('Form Mounted handling error: ', error);
        },
        onFormUnmounted: (error) => {
          if (error)
            return console.warn('Form Unmounted handling error: ', error);
        },
        onSubmit: (event) => {
          event.preventDefault();

          let {
            paymentMethodId: payment_method_id,
            issuerId: issuer_id,
            cardholderEmail: email,
            amount,
            token,
            installments,
            identificationNumber,
            identificationType,
          } = cardForm.getCardFormData();
          identificationType = 'Otro';
          // TODO sobre escribir identification type con el valor mío

          axios
            .post(
              `${process.env.REACT_APP_API_URL}payments/process_payment/`,
              //TODO agarrar los errores y mostrar notificaciones correspondientes
              {
                token,
                issuer_id,
                payment_method_id,
                transaction_amount: Number(amount),
                installments: Number(installments),
                description: 'Descripción del producto',
                payer: {
                  email,
                  identification: {
                    type: window.localStorage.getItem('test'),
                    number: identificationNumber,
                  },
                },
              }
            )
            .then((resp) => {
              if (resp.data === 'approved') {
                history.push(APPOINTMENTS);
              }
            })
            .catch((err) => {
              setPaymentError(true);
              setLoading(false);
            });
        },
        onFetching: (resource) => {
          // Animate progress bar
          const progressBar = document.querySelector('.progress-bar');
          progressBar.removeAttribute('value');

          return () => {
            progressBar.setAttribute('value', '0');
          };
        },
      },
    });

    return () => {
      cardForm.unmount();
    };
  }, []);
  useEffect(() => {
    let date = moment(location.state.appointmentDate).format('MM-DD-YYYY');
    const body = {
      title: `Consulta Mapfre ${date}`,
      price: 200,
      appointment: appointmentId,
    };
    getMpPreference(body);
  }, []);

  const documentTypes = ['CI', 'Otro'];
  const styles = useStyles();
  const [state, setState] = useState(''); // this two states are used to read expire dates values in our form and pass it to MP form
  const [expireDates, setExpireDates] = useState({ month: '', year: '' });
  const [loading, setLoading] = useState(false);

  const handleMpModal = () => {
    // Now we gonna use mp redirect instead of modal (check paymentForm for more comments on this)

    window.location.replace(userData.mpPreference.init_point);
  };

  const handleDocTypeChange = (e) => {
    setDocType(e.target.value);
    window.localStorage.setItem('test', e.target.value);
  };

  const handleChangeTest = (event) => {
    setState(event.target.value);
    let str = event.target.value.replace(/\D/g, '');
    if (str.length == 4) {
      let exp = {
        month: str.substr(0, str.length / 2),
        year: str.substr(str.length / 2),
      };
      setExpireDates(exp);
    }
  };

  return (
    <>
      {paymentError && (
        <Notification
          type="error"
          message={'Pago rechazado, verifica los datos de tu tarjeta '}
          onCloseCallback={() => setPaymentError(false)}
        />
      )}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          md={12}
          justifyContent="space-between"
          className={styles.menuContainer}
        >
          <div>
            <h2 className={styles.title}>Pago de consulta </h2>
          </div>
          <div className={styles.mpContainer}>
            <p>Procesado por </p>
            <img src={mpPath} style={{ height: '70px', width: 'auto' }}></img>
          </div>
        </Grid>
        <Grid
          item
          className={styles.formConteiner}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Paper
            elevation={8}
            style={{ padding: '40px 40px', marginTop: '2%' }}
          >
            <form id="form-checkout" style={{ zIndex: '1' }}>
              <Grid
                item
                container
                spacing={2}
                className={styles.paperContainer}
              >
                <Grid className={styles.firstPaperContainer}>
                  <p>
                    <b>Fecha de consulta:</b>
                    {location &&
                      ` ${moment(location.state.appointmentDate)
                        .tz(moment.tz.guess())
                        .format('YYYY-MM-DD HH:mm [hs]')}`}
                  </p>
                </Grid>

                <Grid item md={12} xs={12} className={styles.paperGridItems}>
                  <InputMask
                    mask="9999 9999 9999 9999"
                    disabled={false}
                    maskChar=""
                  >
                    {() => (
                      <TextField
                        name="cardNumber"
                        id="form-checkout__cardNumber"
                        label="Número de tarjeta"
                        variant="standard"
                        autoComplete="off"
                        style={{ width: '100%' }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item md={5} xs={12} className={styles.paperGridItems}>
                  <InputMask
                    mask="99/99"
                    disabled={false}
                    maskChar=""
                    value={state}
                    onChange={handleChangeTest}
                  >
                    {() => (
                      <TextField
                        label="Fecha de vencimiento"
                        variant="standard"
                        style={{ width: '100%' }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                </Grid>

                <Grid item md={3} xs={12} className={styles.paperGridItems}>
                  <InputMask mask="999" disabled={false} maskChar="">
                    {() => (
                      <TextField
                        id="form-checkout__securityCode"
                        name="securityCode"
                        label="Código de seguridad"
                        variant="standard"
                        autoComplete="off"
                        style={{ width: '100%' }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                </Grid>

                <Grid item md={3} xs={12} className={styles.paperGridItems}>
                  <Avatar
                    style={{ width: '60px', height: '60px' }}
                    alt="Remy Sharp"
                    src={cvvPath}
                  />
                </Grid>

                <Grid item md={5} xs={12} className={styles.paperGridItems}>
                  <TextField
                    id="form-checkout__cardholderName"
                    name="cardholderName"
                    label="Nombre y apellido"
                    variant="standard"
                    autoComplete="off"
                    style={{ width: '100%' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={5} xs={12} className={styles.paperGridItems}>
                  <TextField
                    id="form-checkout__cardholderEmail"
                    name="cardholderEmail"
                    label="Email"
                    variant="standard"
                    autoComplete="off"
                    style={{ width: '100%' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {/* //TODO ver que agarre bien este valor y lo pase al form de MP, ahora esta en el valor por defecto CI */}
                <Grid item md={3} xs={12} className={styles.paperGridItems}>
                  <TextField
                    id="test"
                    name="identificationType"
                    label="Tipo de documento"
                    value={docType}
                    onChange={handleDocTypeChange}
                    style={{ width: '100%' }}
                    select
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                  >
                    {documentTypes.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={5} xs={12} className={styles.paperGridItems}>
                  <TextField
                    id="form-checkout__identificationNumber"
                    name="identificationNumber"
                    label="Número de documento"
                    variant="standard"
                    autoComplete="off"
                    style={{ width: '100%' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid container className={styles.firstPaperContainer}>
                  <Grid item className={styles.cardContainer}>
                    <img src={masterPath} style={{ marginRight: '15px' }}></img>
                    <img src={visaPath} style={{ marginRight: '15px' }}></img>
                    <img src={ocaPath} style={{ marginRight: '15px' }}></img>
                  </Grid>
                  <Grid item xs={12} md={6} container justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      color="primary"
                      className={styles.buttons}
                      onClick={() => history.push(HOMEPAGE)}
                    >
                      Cancelar
                    </Button>
                    <Button
                      id="form-checkout__submit"
                      variant="contained"
                      color="primary"
                      className={styles.buttons}
                      type="submit"
                      onClick={() => setLoading(true)}
                    >
                      {loading ? 'Cargando...' : 'Pagar'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Link
                  component="button"
                  onClick={handleMpModal}
                  variant="contained"
                  color="primary"
                >
                  <Typography>
                    {' '}
                    Paga con tu cuenta de Mercado pago aquí.
                  </Typography>
                </Link>
              </Grid>
              <div style={{ zIndex: '-1', position: 'absolute' }}>
                <select
                  name="issuer"
                  type="hidden"
                  id="form-checkout__issuer"
                ></select>
                <select
                  name="installments"
                  id="form-checkout__installments"
                ></select>
                <input id="MPHiddenInputToken" type="hidden"></input>
                <progress value="0" class="progress-bar">
                  Cargando...
                </progress>
                <select
                  name="identificationType"
                  id="form-checkout__identificationType"
                ></select>
                <input
                  // type="text"
                  type="hidden"
                  name="cardExpirationMonth"
                  id="form-checkout__cardExpirationMonth"
                  value={expireDates.month}
                />
                <input
                  // type="text"
                  type="hidden"
                  name="cardExpirationYear"
                  id="form-checkout__cardExpirationYear"
                  value={expireDates.year}
                />
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ user }) => {
  return {
    userData: user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMpPreference: (body) =>
    dispatch({
      type: userActionsTypes.GET_USER_MP_PREFERENCE,
      payload: { body },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
