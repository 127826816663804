import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  availabilityContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    '@media (max-width: 900px)': {
      marginTop: '1rem',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  availabilityItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
  },
  typeAvailability: {
    margin: '0 0.5rem 0 0.5rem',
    fontWeight: 300,
    fontSize: '15px',
  },
  chip: {
    width: '137px',
    height: '32px',
    margin: '1rem 1rem 0rem 1rem',
    '@media (max-width: 900px)': {
      width: '117px',
    },
  },
  dayName: {
    textAlign: 'center',
    width: '137px',
    height: '32px',
    margin: '1rem 1rem 0rem 1rem',
    '@media (max-width: 900px)': {
      width: '117px',
    },
  },
  hourContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: 'solid 1px #8080803b',
  },
  weekContainer: {
    '@media (max-width: 900px)': {
      overflow: 'scroll',
      width: '100%',
    },
  },
  hourList: {
    height: '32rem',
    overflow: 'scroll',
    '@media (max-width: 900px)': {
      overflow: 'initial',
      overflowY: 'scroll',
      width: '67rem',
    },
  },
  referenceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
    '@media (max-width: 900px)': {
      flexDirection: 'column-reverse',
      justifyContent: 'initial',
      alignItems: 'center',
    },
  },
}));
