import React, { useState, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import SignaturePad from 'react-signature-canvas';
import PasswordInput from '../../components/PasswordInput';
import { isValidPassword } from '../../utils/validators';
import { isProfessional } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';

function AccountData({
  classes,
  user,
  changeUserPassword,
  session,
  doctors,
  isProfileDoctor,
  isAssistant,
  updateSignature,
}) {
  const [t, i18n] = useTranslation('global');
  const sections = [
    {
      title: t('AccountData.signature'),
      editField: t('AccountData.change-signature'),
      description: t('AccountData.description'),
      onClick: () => setState({ ...state, showSignature: true }),
    },
    {
      title: t('AccountData.account-type'),
      editField: t('AccountData.price'),
      description: t('AccountData.description'),
      onClick: console.log('FIRMA'),
    },
    {
      title: t('AccountData.pass'),
      editField: 'Cambiar contraseña',
      onClick: console.log('FIRMA'),
    },
  ];

  const theme = useTheme();

  const [state, setState] = useState({
    showSignature: false,
    acceptSignature: false,
    emptyCanvas: true,
  });
  const { showSignature, acceptSignature, emptyCanvas } = state;

  //Signature
  const [trimmedDataURL, setImage] = useState(
    user.signature_picture && user.signature_picture.url
  );
  let padRef = useRef({});
  const trim = () => {
    setImage(padRef.current.getTrimmedCanvas().toDataURL('image/png'));
    padRef.current.getTrimmedCanvas().toBlob((blob) => {
      const formDataUser = new FormData();
      formDataUser.append('file', blob, 'signature.png');

      updateSignature(formDataUser);
    });
    setState({ ...state, showSignature: false });
  };
  const handleClear = () => {
    padRef.current.clear();
    setState({ ...state, emptyCanvas: true });
  };

  const ChangePassword = () => {
    const [state, setState] = useState({
      prevPassword: null,
      newPassword: null,
      newPassword2: null,
    });
    const { prevPassword, newPassword, newPassword2 } = state;

    const isDisabled = () => {
      return !(
        prevPassword &&
        newPassword &&
        isValidPassword(newPassword) &&
        newPassword === newPassword2
      );
    };

    return (
      <Box
        className={classes.changePassword}
        style={{
          pointerEvents: isProfileDoctor
            ? !doctors.selectedDoctor
              ? 'none'
              : ''
            : '',
        }}
      >
        {!isProfileDoctor && (
          <PasswordInput
            classes={classes}
            changePassword={(newPass) =>
              setState({ ...state, prevPassword: newPass })
            }
            password={prevPassword}
            isValidPassword={() => {
              return true;
            }}
            labelText={t('AccountData.current-pass')}
          />
        )}
        <PasswordInput
          classes={classes}
          changePassword={(newPass) =>
            setState({ ...state, newPassword: newPass })
          }
          password={newPassword}
          isValidPassword={isValidPassword}
          labelText={t('AccountData.new-pass')}
        />
        <PasswordInput
          classes={classes}
          changePassword={(newPass) =>
            setState({ ...state, newPassword2: newPass })
          }
          password={newPassword2}
          diffPassword={!newPassword || newPassword2 !== newPassword}
          isSecondPassword
          labelText={t('AccountData.confirm-new-pass')}
        />
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={
              isDisabled() || isProfileDoctor
                ? doctors.selectedDoctor === null
                : false
            }
            onClick={() => {
              if (isProfileDoctor) {
                changeUserPassword({
                  user_to_modify: doctors.selectedDoctor.id,
                  new_password: newPassword,
                });
              } else {
                changeUserPassword({
                  old_password: prevPassword,
                  new_password: newPassword,
                  assistant_self_password_change: isAssistant,
                });
              }
            }}
          >
            {t('AccountData.change-pass')}
          </Button>
        </Grid>
      </Box>
    );
  };

  const editSignatureComponent = () => {
    const getWidth = () => {
      if (window.screen.width < 900) {
        return window.screen.width * 0.9;
      }
      return window.screen.width * 0.4;
    };

    return (
      <>
        <div
          style={{
            border: `solid 1px ${theme.palette.primary.main}`,
          }}
        >
          <SignaturePad
            penColor={theme.palette.primary.main}
            canvasProps={{
              height: 200,
              width: getWidth(),
            }}
            ref={padRef}
            onEnd={() => setState({ ...state, emptyCanvas: false })}
          />
        </div>
        <Grid item className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptSignature}
                onChange={(event) =>
                  setState({
                    ...state,
                    acceptSignature: event.target.checked,
                  })
                }
                name="checkedB"
                color="primary"
              />
            }
            style={{ marginRight: '0px' }}
          />
          <span>{t('AccountData.accept-signature')}</span>
        </Grid>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setState({ ...state, showSignature: false })}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClear()}
            style={{ marginLeft: '1rem' }}
          >
            {t('AccountData.clean')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={trim}
            disabled={!acceptSignature || emptyCanvas}
            style={{ marginLeft: '1rem' }}
          >
            {t('AccountData.accept')}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {sections.map((section) => {
        return (
          <>
            {isProfessional(session.userType) ? (
              <Box className={classes.titleContainer}>
                <span className={classes.directionTitle}>{section.title}</span>
                {section.title !== t('AccountData.pass') && (
                  <span className={classes.editField} onClick={section.onClick}>
                    {section.editField}
                  </span>
                )}
              </Box>
            ) : (
              section.title === t('AccountData.pass') && (
                <Box className={classes.titleContainer}>
                  <span className={classes.directionTitle}>
                    {section.title}
                  </span>
                </Box>
              )
            )}

            {section.title === t('AccountData.account-type') &&
            isProfessional(session.userType) ? (
              <Box className={classes.extraData}>
                <span className={classes.fieldDescription}>
                  {t('AccountData.youre-account-type')}
                </span>
                <span
                  className={classes.fieldDescription}
                  style={{ marginLeft: '0px', fontWeight: 'bold' }}
                >
                  {t('AccountData.consulting-doctor')}
                </span>
                <br />
                <br />
                <span
                  className={classes.fieldDescription}
                  style={{ fontWeight: 'bold' }}
                >
                  {t('AccountData.standard-doctor')}
                </span>
                <span
                  className={classes.fieldDescription}
                  style={{ marginLeft: '0px' }}
                >
                  {t('AccountData.online')}
                </span>
                <br />
                <br />
                <span
                  className={classes.fieldDescription}
                  style={{ fontWeight: 'bold' }}
                >
                  {t('AccountData.consulting-doctor')}
                </span>
                <span
                  className={classes.fieldDescription}
                  style={{ marginLeft: '0px' }}
                >
                  {t('AccountData.online-2')}
                </span>
              </Box>
            ) : section.title === t('AccountData.pass') ? (
              <Box
                className={classes.extraData}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <ChangePassword />
              </Box>
            ) : (
              isProfessional(session.userType) && (
                <Box className={classes.extraData}>
                  <span className={classes.fieldDescription}>
                    {section.description}
                  </span>
                  {section.title === t('AccountData.signature') &&
                    showSignature &&
                    editSignatureComponent()}
                  {section.title === t('AccountData.signature') &&
                    !showSignature &&
                    trimmedDataURL && (
                      <div className={classes.signaturePicture}>
                        <img
                          src={trimmedDataURL}
                          alt="firma"
                          style={{
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </div>
                    )}
                </Box>
              )
            )}
          </>
        );
      })}

      {/* <Box display='flex' justifyContent='center' marginTop='2rem'>
        <Button variant='contained' color='primary'>
          Guardar
        </Button>
      </Box> */}
    </Box>
  );
}

export default AccountData;
