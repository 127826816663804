import { useEffect, useState } from 'react';
import { Box, Button, Paper, Avatar, CardHeader } from '@material-ui/core';
import moment from 'moment';
import { useStyles } from './style';
import { APPOINTMENT_DETAILS } from '../../utils/urls';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/helpers';

function AppointmentCard({
  theme,
  appointment,
  isProfessional,
  session,
  getNewAccessToken,
  userState,
}) {
  const styles = useStyles();
  const history = useHistory();
  const [actualDate, setActualDate] = useState(moment());
  const [t, i18n] = useTranslation('global');

  const handleSeeAppointment = () => {
    history.push({
      pathname: APPOINTMENT_DETAILS,
      state: {
        appointment,
      },
    });
  };

  useEffect(() => {
    let secTimer = setInterval(() => {
      setActualDate(moment());
    }, 5000); //every five seconds we get the actual Date time to update "IR"

    return () => clearInterval(secTimer);
  }, []);

  useEffect(() => {
    if (userState.success == 'Nuevo token obtenido con éxito') {
      let pageName =
        process.env.REACT_APP_VIDEO_URL +
        'appointment/' +
        appointment.id +
        '/?idUser=' +
        session.id +
        '&token=' +
        session.accessToken;
      window.open(pageName);
    }
  }, [userState.success]);

  return (
    <Paper
      style={{
        borderTop: `2px solid ${theme.palette.primary.main}`,
        width: 300,
        padding: '1.5rem',
        margin: '0.5rem',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <span>
          {'Consulta: '}
          <span style={{ fontWeight: 'bold' }}>{` ${moment(appointment.date)
            .tz(moment.tz.guess())
            .format('YYYY-MM-DD HH:mm [hs]')}`}</span>
        </span>
      </Box>
      <Box>
        <CardHeader
          style={{ paddingLeft: '0px' }}
          avatar={
            <Avatar
              alt="Foto de perfil"
              src={
                appointment.patient //TODO todos los chequeos de appointment.patient hay que sacarlos, quedaron appointment con patient= null por error, hay que limpiarlos
                  ? isProfessional
                    ? appointment.patient.profile_picture &&
                      appointment.patient.profile_picture.url
                    : appointment.doctor.profile_picture &&
                      appointment.doctor.profile_picture.url
                  : null
              }
              style={{
                height: '60px',
                width: '60px',
              }}
              className={styles.avatar}
            />
          }
          title={`${
            appointment.patient
              ? `${
                  isProfessional
                    ? appointment.patient.full_name
                    : appointment.doctor.full_name
                }`
              : appointment.client
              ? appointment.client.full_name
              : capitalize(t('word.client'))
          }`}
          subheader={
            isProfessional ? (
              <div>
                <span style={{ fontWeight: 'bold' }}>
                  {appointment.patient
                    ? `CI: ${appointment.patient.identity_document}`
                    : appointment.client
                    ? appointment.client.identity_document
                    : 'Sin datos de identidad'}
                </span>
                <br></br>{' '}
                <span>
                  {appointment.patient
                    ? `Nacimiento: ${appointment.patient.date_of_birth}`
                    : appointment.client
                    ? appointment.client.date_of_birth
                    : 'Sin datos de nacimiento'}
                </span>
              </div>
            ) : (
              <div>{appointment.doctor.specialty.name}</div>
            )
          }
        />
      </Box>
      <Box marginTop="1rem">
        <span style={{ fontWeight: 300 }}>
          Motivo {t('prep.of-the')} consulta:{' '}
        </span>
      </Box>
      <Box>
        <span style={{ fontWeight: 'bold' }}>
          {!appointment.appointment_type
            ? appointment.patient_appointment_reason
              ? appointment.patient_appointment_reason
              : '-'
            : appointment.client_reason_text
            ? appointment.client_reason_text
            : '-'}
        </span>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        style={{ margin: '10px' }}
      >
        <Button
          color="primary"
          variant="contained"
          style={{ marginRight: '2rem' }}
          //disabled={moment(appointment.date).diff(actualDate, 'minutes') > 5}
          onClick={handleSeeAppointment}
        >
          Ver
        </Button>

        <Button
          color="primary"
          variant="contained"
          //disabled={moment(appointment.date).diff(actualDate, 'minutes') > 5}
          onClick={() => {
            let pageName =
              process.env.REACT_APP_VIDEO_URL +
              'appointment/' +
              appointment.id +
              '/?idUser=' +
              session.id +
              '&token=' +
              session.accessToken +
              `${
                appointment.client_link
                  ? appointment.appointment_type == 'vehicle'
                    ? '&expertise=vehicle&light=1'
                    : '&expertise=home&light=1'
                  : appointment.is_light
                  ? '&light=1'
                  : ''
              }` +
              '&language=' +
              `${localStorage.getItem('language') == 'es' ? 'es' : 'pt-br'}`;
            window.location.href = pageName;
          }}
        >
          IR
        </Button>
      </Box>
    </Paper>
  );
}

export default AppointmentCard;
