const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

//Convert the crop image to file
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  imageType,
  imageName
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  let newPixelCrop = pixelCrop;
  let scale = 1;

  // set each dimensions to largest dimension to allow for a safe area for the
  // image to scale in without being clipped by canvas context
  canvas.width = maxSize;
  canvas.height = maxSize;

  // scale the image to limit the size.
  if (maxSize > 1024) {
    scale = 1024 / maxSize;
    newPixelCrop = {
      x: pixelCrop.x * scale,
      y: pixelCrop.y * scale,
      height: pixelCrop.height * scale,
      width: pixelCrop.width * scale,
    };
  }

  // draw scaled image and store data.
  ctx.drawImage(image, 0, 0, image.width * scale, image.height * scale);
  const data = ctx.getImageData(
    0,
    0,
    image.width * scale,
    image.height * scale
  );

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = newPixelCrop.width;
  canvas.height = newPixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(-newPixelCrop.x),
    Math.round(-newPixelCrop.y)
  );

  // As Base64 string
  return dataURLtoFile(canvas.toDataURL(imageType), imageName);

  // // As a blob
  // return new Promise((resolve) => {
  //   canvas.toBlob((file) => {
  //     resolve(URL.createObjectURL(file));
  //   }, imageType);
  // });
}
