import { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Box,
  Input,
  TextField,
  Select,
  Button,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next'

function ProfessionalData({
  classes,
  specialities,
  user,
  doctors,
  updateUser,
  isProfileDoctor,
  session,
}) {
  const [state, setState] = useState({
    specialty: null,
    graduationYear: null,
    specialtyGraduationYear: null,
    bio: null,
  });
  const [t, i18n] = useTranslation('global')
  const { specialty, graduationYear, specialtyGraduationYear, bio } = state;

  useEffect(() => {
    if (user || doctors.selectedDoctor) {
      setState({
        ...state,
        specialty:
          doctors.selectedDoctor && doctors.selectedDoctor.specialty
            ? doctors.selectedDoctor.specialty.id
            : user.specialty && user.specialty.id,
        graduationYear: doctors.selectedDoctor
          ? doctors.selectedDoctor.graduation_year
          : user.graduation_year,
        specialtyGraduationYear: doctors.selectedDoctor
          ? doctors.selectedDoctor.specialty_graduation_year
          : user.specialty_graduation_year,
        bio: doctors.selectedDoctor ? doctors.selectedDoctor.bio : user.bio,
      });
    }
  }, [user, doctors.selectedDoctor]);

  const onSave = () => {
    const body = {
      specialty,
      graduation_year: graduationYear,
      specialty_graduation_year: specialtyGraduationYear,
      bio,
    };
    updateUser(
      isProfileDoctor ? 'doctor' : session.userType,
      isProfileDoctor && doctors.selectedDoctor
        ? doctors.selectedDoctor.id
        : user.id,
      body,
      false,
      isProfileDoctor,
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="1rem"
      style={{
        pointerEvents: isProfileDoctor
          ? !doctors.selectedDoctor
            ? 'none'
            : ''
          : '',
      }}
    >
      <Box className={classes.formContainer} style={{ marginTop: '1rem' }}>
        <FormControl
          className={classes.formControlProf}
          error={graduationYear && typeof graduationYear !== 'number'}
        >
          <InputLabel htmlFor="my-input-graduationYear" shrink>
            {t('ProfessionalData.graduation-year')}
          </InputLabel>
          <Input
            id="my-input-graduationYear"
            onChange={(e) =>
              setState({
                ...state,
                graduationYear: parseInt(e.target.value, 10),
              })
            }
            value={graduationYear}
            required
            type="number"
            shrink
          />
        </FormControl>
        <FormControl
          className={classes.formControlProf}
          error={
            specialtyGraduationYear &&
            typeof specialtyGraduationYear !== 'number'
          }
        >
          <InputLabel htmlFor="my-input-specialtyGraduationYear" shrink>
          {t('ProfessionalData.specialty-year')}
          </InputLabel>
          <Input
            id="my-input-specialtyGraduationYear"
            onChange={(e) =>
              setState({
                ...state,
                specialtyGraduationYear: parseInt(e.target.value, 10),
              })
            }
            value={specialtyGraduationYear}
            required
            type="number"
          />
        </FormControl>
        <FormControl
          className={classes.formControlProf}
          error={specialty === ''}
        >
          <InputLabel id="specialty-simple-select-label" shrink>
          {t('ProfessionalData.select-specialty')}
          </InputLabel>
          <Select
            inputProps={{
              name: 'specialty',
              id: 'specialty-native-simple',
            }}
            native
            value={specialty}
            onChange={(e) => setState({ ...state, specialty: e.target.value })}
          >
            <option aria-label="None" value="" />
            {specialities &&
              specialities.map((specialty) => {
                return <option value={specialty.id}>{specialty.name}</option>;
              })}
          </Select>
        </FormControl>
        <FormControl
          className={classes.formControlProf}
          error={bio && bio.length > 1024}
        >
          <TextField
            id="standard-multiline-flexible"
            label={t('ProfessionalData.other-studies')}
            multiline
            maxRows={6}
            onChange={(e) => setState({ ...state, bio: e.target.value })}
            value={bio}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span style={{ fontSize: '0.9em', alignSelf: 'end' }}>
            {bio ? `${bio.length}/1024` : '0/1024'}
          </span>
        </FormControl>
      </Box>
      <Box display="flex" justifyContent="center" marginTop="1rem">
        <Button
          variant="contained"
          color="primary"
          disabled={!specialty}
          onClick={onSave}
        >
          {t('ProfessionalData.save')}
        </Button>
      </Box>
    </Box>
  );
}

export default ProfessionalData;
