import React from 'react';
import { Box, Button, Paper } from '@material-ui/core';
import moment from 'moment';
import { APPOINTMENT_DETAILS } from '../../utils/urls';
import { useHistory } from 'react-router-dom';

function AppointmentCard({ theme, classes, appointment }) {
  const history = useHistory();

  const handleSeeAppointment = () => {
    history.push({
      pathname: APPOINTMENT_DETAILS,
      state: {
        appointment,
      },
    });
  };

  return (
    <Paper
      style={{
        borderTop: `2px solid ${theme.palette.primary.main}`,
        width: 300,
        padding: '1.5rem',
        margin: '0.5rem',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <span>
          {'Consulta: '}
          <span style={{ fontWeight: 'bold' }}>
            {moment(appointment.schedule.start)
              .tz(moment.tz.guess())
              .format('DD-MM-YYYY h:mm a')}
          </span>
        </span>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSeeAppointment}
        >
          ver
        </Button>
      </Box>
      <Box marginTop="1rem">
        <span style={{ fontWeight: 300 }}>Motivo de la consulta</span>
      </Box>
      <Box>
        <span style={{ fontWeight: 'bold' }}>
          {appointment.patient_appointment_reason}
        </span>
      </Box>
    </Paper>
  );
}

export default AppointmentCard;
