import { doctorsActionsTypes } from './actions';

const DOCTORS_INITIAL_STATE = {
  doctors: [],
  numOfPages: 0,
  currentPage: 1,
  countries: [],
  specialities: [],
  error: null,
  success: null,
  loading: false,
  selectedDoctor: null,
};

const doctorsReducer = (state = DOCTORS_INITIAL_STATE, actions) => {
  let new_state;
  switch (actions.type) {
    case doctorsActionsTypes.SUCCESS_GET_DOCTORS:
      let numOfPages = Math.ceil(actions.payload.count / 10);
      new_state = {
        ...state,
        error: null,
        doctors: actions.payload.results,
        numOfPages: numOfPages,
        currentPage: actions.payload.page,
        countries: actions.payload.countries || state.countries,
        specialities: actions.payload.specialities || state.specialities,
      };
      break;
    case doctorsActionsTypes.LOADING:
      new_state = {
        ...state,
        loading: actions.payload,
        error: actions.payload ? null : state.error,
      };
      break;
    case doctorsActionsTypes.ERROR:
      new_state = {
        ...state,
        error: actions.payload,
      };
      break;
    case doctorsActionsTypes.SET_DOCTOR_BODY:
      new_state = {
        ...state,
        selectedDoctor: actions.payload,
      };
      break;
    case doctorsActionsTypes.CLEAR_SELECT_DOCTOR:
      new_state = {
        ...state,
        selectedDoctor: null,
      };
      break;
    case doctorsActionsTypes.LOGOUT:
      new_state = DOCTORS_INITIAL_STATE;
      break;
    // case userActionsTypes.LOGOUT:
    //   new_state = DOCTORS_INITIAL_STATE;
    //   break;
    case doctorsActionsTypes.SUCCESS_UPDATE_DOCTOR_ASSISTANT:
      new_state = {
        ...state,
        selectedDoctor: actions.payload.data,
        success: actions.payload.message,
      };
      break;
    case doctorsActionsTypes.DELETE_MESSAGES:
      new_state = {
        ...state,
        success: null,
        error: null,
      };
      break;
    case doctorsActionsTypes.SUCCESS_CREATE_ADDRESS_DOCTOR:
      new_state = {
        ...state,
        error: null,
        success: actions.payload.message,
        selectedDoctor: {
          ...state.selectedDoctor,
          address: [...state.selectedDoctor.address, actions.payload.address],
        },
      };
      break;
    case doctorsActionsTypes.SUCCESS_DELETE_ADDRESS_DOCTOR:
      new_state = {
        ...state,
        success: actions.payload.message,
        selectedDoctor: {
          ...state.selectedDoctor,
          address: state.selectedDoctor.address.filter(
            (val) => val.id !== actions.payload.address
          ),
        },
      };
      break;
    default:
      new_state = state;
  }
  return new_state;
};

export { doctorsReducer };
