import { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Grid,
  Avatar,
  TextField,
  FormControlLabel,
  Checkbox,
  CardHeader,
} from '@material-ui/core';
import { useStyles } from './style';
import DoctariModal from '../../components/Modal';
import { DropzoneArea } from 'material-ui-dropzone';
import { connect } from 'react-redux';
import { APPOINTMENTS, BASE_PAYMENT, AGENDA } from '../../utils/urls';
import { userActionsTypes } from '../../redux/user/actions';
import { appointmentActionsTypes } from '../../redux/appointment/actions';
import AutocompleteInfo from '../../components/AutocompleteInfo';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';

function Appointment({
  getUser,
  createAppointment,
  appointmentsData,
  session,
  userData,
  addUserInfo,
  deleteUserInfo,
  createInfo,
  uploadAppointmentFiles,
  deleteAttachmentMessages,
  getPatients,
  doctors,
  deleteSchedule,
}) {
  const history = useHistory();
  const location = useLocation();
  const styles = useStyles();
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [createAppointmentModalOpen, setCreateAppointmentModalOpen] =
    useState(false);
  const [checked, setChecked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [input, setInput] = useState('');
  const isAssistant = session.userType == 'assistant';
  const [t, i18n] = useTranslation('global');
  // const [stateAppointment, setStateAppointment] = useState({
  //   dateAppointment: !isAssistant
  //     ? location.state.schedule.start
  //     : appointmentsData.scheduleCreated.start,
  //   doctorFullName: !isAssistant
  //     ? location.state.schedule.doctor.full_name
  //     : doctors.selectDoctor.full_name,
  //   doctorPictureProfile: isAssistant
  //     ? doctors.selectDoctor.profile_picture
  //       ? doctors.selectDoctor.profile_picture.url
  //       : null
  //     : location.state.schedule.doctor.profile_picture
  //     ? location.state.schedule.doctor.profile_picture.url
  //     : null,
  //   doctorSpecialty: isAssistant
  //     ? doctors.selectDoctor.specialty.name
  //     : location.state.schedule.doctor.specialty.name,
  // });
  // const {
  //   dateAppointment,
  //   doctorFullName,
  //   doctorPictureProfile,
  //   doctorSpecialty,
  // } = stateAppointment;
  const [patientSelected, setPatient] = useState({
    patient: null,
    medications: null,
    allergies: null,
    diseases: null,
  });

  const goPayment = () => {
    history.push({
      pathname: `${BASE_PAYMENT}${appointmentsData.appointmentCreated.id}`,
      state: {
        appointmentDate: location.state.schedule.start,
        doctorName: location.state.schedule.doctor.full_name,
      },
    });
    return () => deleteAttachmentMessages();
  };
  // when the appointment files are added  we go to payment page and pass the appointment id
  useEffect(() => {
    if (appointmentsData.success.uploadFiles && buttonClicked && !isAssistant) {
      goPayment();
    } else if (isAssistant && buttonClicked) {
      history.push(AGENDA);
    }
  }, [appointmentsData.success.uploadFiles]);
  // when the appointment is created we upload the attachments
  useEffect(() => {
    if (
      appointmentsData.success.genericMessage ==
        'Appointment creado con exito' &&
      buttonClicked
    ) {
      if (attachments.length == 0 && !isAssistant) {
        goPayment();
      } else if (attachments.length > 0) {
        const formDataAppointment = new FormData();
        for (let i = 0; i < attachments.length; i++) {
          formDataAppointment.append(`file${i}`, attachments[i]);
        }
        const body = {
          files: formDataAppointment,
          appointmentId: appointmentsData.appointmentCreated.id,
        };
        uploadAppointmentFiles(body);
      } else if (isAssistant) {
        history.push(AGENDA);
      }
    }
  }, [appointmentsData.success.genericMessage]);

  useEffect(() => {
    getUser(session.userType, session.id);
    if (isAssistant) {
      getPatients([], []);
    }
  }, []);

  useEffect(() => {
    if (!isAssistant && userData.user) {
      setPatient({
        patient: userData.user,
        medications: userData.user.medications,
        allergies: userData.user.allergies,
        diseases: userData.user.diseases,
      });
    }
  }, [userData.user]);

  const { user, medications, allergies, diseases } = userData;

  const getUrlAndId = (type, isForDelete) => {
    let dataType = {
      id: 'medication_id',
      url: isForDelete ? 'delete_medication' : 'patient_medication',
    };
    if (type === 'diseases') {
      dataType = {
        id: 'disease_id',
        url: isForDelete ? 'delete_disease' : 'patient_disease',
      };
    } else if (type === 'allergies') {
      dataType = {
        id: 'allergie_id',
        url: isForDelete ? 'delete_allergie' : 'patient_allergie',
      };
    }
    return dataType;
  };

  const handleUserMedication = (isNew, med, isForDelete = false) => {
    const infoToUpdate = 'medications';
    const data = getUrlAndId(infoToUpdate, isForDelete);
    if (!isForDelete) {
      if (isNew) {
        createInfo(infoToUpdate, { name: med.name }, data);
      } else {
        addUserInfo(infoToUpdate, data, med);
      }
    } else {
      deleteUserInfo(infoToUpdate, data, med.id);
    }
  };

  const handleUserDisease = (isNew, disease, isForDelete = false) => {
    const infoToUpdate = 'diseases';
    const data = getUrlAndId(infoToUpdate, isForDelete);
    if (!isForDelete) {
      if (isNew) {
        createInfo(infoToUpdate, { name: disease.name }, data);
      } else {
        addUserInfo(infoToUpdate, data, disease);
      }
    } else {
      deleteUserInfo(infoToUpdate, data, disease.id);
    }
  };

  const handleUserAllergie = (isNew, allergie, isForDelete = false) => {
    const infoToUpdate = 'allergies';
    const data = getUrlAndId(infoToUpdate, isForDelete);
    if (!isForDelete) {
      if (isNew) {
        createInfo(infoToUpdate, { name: allergie.name }, data);
      } else {
        addUserInfo(infoToUpdate, data, allergie);
      }
    } else {
      deleteUserInfo(infoToUpdate, data, allergie.id);
    }
  };

  const addInformation = {
    handleUserMedication,
    handleUserAllergie,
    handleUserDisease,
  };

  const handleAttachmentsChange = (files) => {
    setAttachments(files);
  };

  const handleInputCHange = (e) => {
    setInput(e.target.value);
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleCreateAppointment = () => {
    setButtonClicked(true);
    createAppointment(
      location.state.schedule.id,
      input,
      location.state.schedule.doctor.id,
      patientSelected.patient.id,
      session.accessToken,
      patientSelected.patient.allergies,
      patientSelected.patient.medications,
      patientSelected.patient.diseases
    );
    setCreateAppointmentModalOpen(false);
  };

  const handleSetPatientInfo = (value) => {
    if (value == null) {
      setPatient({
        patient: null,
        medications: null,
        allergies: null,
        diseases: null,
      });
    } else {
      setPatient({
        patient: value,
        medications: value.medications,
        allergies: value.allergies,
        diseases: value.diseases,
      });
    }
  };

  const handleCancelAppointment = () => {
    if (isAssistant) {
      deleteSchedule('token', location.state.schedule.id, false);
      history.push(AGENDA);
    } else {
      history.push(APPOINTMENTS);
    }
  };

  return (
    <>
      <DoctariModal
        open={attachmentModalOpen}
        setOpen={(val) => setAttachmentModalOpen(val)}
        title={'Adjuntar estudios'}
      >
        <DropzoneArea
          useChipsForPreview={true}
          dropzoneText={
            'Haz click aquí o arrastra tus archivos para adjuntarlos'
          }
          onChange={(files) => handleAttachmentsChange(files)}
          onDelete={(file) => console.log(file)}
          clearOnUnmount={false}
          filesLimit={3}
          initialFiles={attachments}
          getFileAddedMessage={(file) => `${file} añadido`}
          getFileRemovedMessage={(file) => `${file} eliminado`}
          getFileLimitExceedMessage={(limit) =>
            `Limite de archivos excedido, el limite es de ${limit} archivos`
          }
        />
        <Button
          style={{ float: 'right', margin: '10px' }}
          variant="contained"
          color="primary"
          disabled={!attachments.length}
          onClick={() => setAttachmentModalOpen(false)}
        >
          Aceptar
        </Button>
        <Button
          style={{ float: 'right', margin: '10px' }}
          onClick={() => {
            setAttachments([]);
            setAttachmentModalOpen(false);
          }}
          variant="outlined"
          color="primary"
        >
          Cancelar
        </Button>
      </DoctariModal>

      <DoctariModal
        open={createAppointmentModalOpen}
        setOpen={(val) => setCreateAppointmentModalOpen(val)}
        title={'Importante'}
      >
        <p style={{ margin: '5%' }}>
          <strong>{t('Appointment.policy')}</strong>
          {t('Appointment.policy-body')}
        </p>
        <Button
          style={{ float: 'right', margin: '10px' }}
          variant="contained"
          color="primary"
          onClick={handleCreateAppointment}
        >
          {t('Appointment.accept')}
        </Button>
        <Button
          style={{ float: 'right', margin: '10px' }}
          onClick={() => setCreateAppointmentModalOpen(false)}
          variant="outlined"
          color="primary"
        >
          Cancelar
        </Button>
      </DoctariModal>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          md={12}
          justifyContent="space-between"
          className={styles.menuContainer}
        >
          <h2 className={styles.title}>
            {t('Appointment.create-appointment')}
          </h2>
          <CardHeader
            avatar={
              <Avatar
                alt="Foto de perfil"
                src={
                  location.state.schedule.doctor.profile_picture
                    ? location.state.schedule.doctor.profile_picture.url
                    : null
                }
                className={styles.avatar}
              />
            }
            title={location.state.schedule.doctor.full_name}
            subheader={location.state.schedule.doctor.specialty.name}
          />
        </Grid>
        <Grid item className={styles.formConteiner}>
          <Paper elevation={8} style={{ padding: '40px 40px' }}>
            <Grid item container spacing={2} className={styles.testContainer}>
              <Grid className={styles.firstPaperContainer}>
                <p>
                  <b>Fecha de consulta:</b>
                  {` ${moment(location.state.schedule.start)
                    .tz(moment.tz.guess())
                    .format('DD-MM-YYYY HH:mm [hs]')}`}
                </p>
                <p>
                  {t('Appointment.value')} <b>{t('Appointment.currency')}200</b>
                </p>
              </Grid>
              {isAssistant && (
                <Grid item md={12} xs={12}>
                  {/* <FormControl className={classes.formControl}> */}
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={userData.patients}
                    getOptionLabel={(option) => option.full_name}
                    sx={{ width: 300 }}
                    value={patientSelected.patient}
                    onChange={(e, value) => handleSetPatientInfo(value)}
                    renderInput={(params) => (
                      <TextField {...params} label="Seleccione un paciente" />
                    )}
                  />
                </Grid>
              )}
              <Grid item md={12} xs={12}>
                <TextField
                  id="standard-basic"
                  label={t('Appointment.appointment-reason')}
                  variant="standard"
                  style={{ width: '100%' }}
                  className={styles.paperGridItems}
                  onChange={handleInputCHange}
                />
              </Grid>
              <Grid item md={6} xs={12} className={styles.paperGridItems}>
                <AutocompleteInfo
                  label={'Alergias'}
                  optionList={allergies}
                  valuesSelected={
                    patientSelected.patient ? patientSelected.allergies : []
                  }
                  setNewValue={(allergie) => {
                    const isNew = true;
                    addInformation.handleUserAllergie(isNew, allergie);
                  }}
                  addValue={(allergie) => {
                    const isNew = false;
                    addInformation.handleUserAllergie(isNew, allergie);
                  }}
                  deleteValue={(allergie) => {
                    const isNew = false;
                    const isForDelete = true;
                    addInformation.handleUserAllergie(
                      isNew,
                      allergie,
                      isForDelete
                    );
                  }}
                  isAssistant={isAssistant}
                />
              </Grid>
              <Grid item md={6} xs={12} className={styles.paperGridItems}>
                <AutocompleteInfo
                  label={t('Appointment.diseases')}
                  optionList={diseases}
                  valuesSelected={
                    patientSelected.patient ? patientSelected.diseases : []
                  }
                  setNewValue={(disease) => {
                    const isNew = true;
                    addInformation.handleUserDisease(isNew, disease);
                  }}
                  addValue={(disease) => {
                    const isNew = false;
                    addInformation.handleUserDisease(isNew, disease);
                  }}
                  deleteValue={(disease) => {
                    const isNew = false;
                    const isForDelete = true;
                    addInformation.handleUserDisease(
                      isNew,
                      disease,
                      isForDelete
                    );
                  }}
                  isAssistant={isAssistant}
                />
              </Grid>
              <Grid item md={6} xs={12} className={styles.paperGridItems}>
                <AutocompleteInfo
                  label={'Medicamentos'}
                  optionList={medications}
                  valuesSelected={
                    patientSelected.patient ? patientSelected.medications : []
                  }
                  setNewValue={(med) => {
                    const isNew = true;
                    addInformation.handleUserMedication(isNew, med);
                  }}
                  addValue={(med) => {
                    const isNew = false;
                    addInformation.handleUserMedication(isNew, med);
                  }}
                  deleteValue={(med) => {
                    const isNew = false;
                    const isForDelete = true;
                    addInformation.handleUserMedication(
                      isNew,
                      med,
                      isForDelete
                    );
                  }}
                  isAssistant={isAssistant}
                />
              </Grid>

              <div className={styles.forthtPaperContainer}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={checked}
                        onChange={handleCheckChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={t('Appointment.agreement')}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    className={styles.buttons}
                    color="primary"
                    style={{ marginRight: '10px' }}
                    onClick={() => setAttachmentModalOpen(true)}
                  >
                    {t('Appointment.attached')}
                  </Button>
                  <Button
                    variant="outlined"
                    className={styles.buttons}
                    color="primary"
                    onClick={handleCancelAppointment}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    className={styles.buttons}
                    color="primary"
                    disabled={
                      isAssistant
                        ? !checked || !patientSelected.patient
                        : !checked
                    }
                    onClick={() => setCreateAppointmentModalOpen(true)}
                  >
                    {t('Appointment.create-appointment')}
                  </Button>
                </Grid>
              </div>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ session, user, appointments, doctors }) => {
  return {
    session,
    userData: user,
    appointmentsData: appointments,
    doctors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUser: (type, id, token) =>
    dispatch({
      type: userActionsTypes.GET_USER,
      payload: { type, id },
    }),
  addUserInfo: (type, data, newValue) =>
    dispatch({
      type: userActionsTypes.ADD_INFO_USER,
      payload: { type, data, newValue },
    }),
  createInfo: (type, body, data) =>
    dispatch({
      type: userActionsTypes.CREATE_INFORMATION,
      payload: { type, body, data },
    }),
  deleteUserInfo: (type, data, valueId) =>
    dispatch({
      type: userActionsTypes.DELETE_INFO_USER,
      payload: { type, data, valueId },
    }),
  createAppointment: (
    scheduleId,
    reason,
    doctor,
    patient,
    token,
    allergiesObjects,
    medicationsObjects,
    diseasesObjects
  ) =>
    dispatch({
      type: appointmentActionsTypes.CREATE_APPOINTMENT,
      payload: {
        scheduleId,
        reason,
        doctor,
        patient,
        token,
        allergiesObjects,
        medicationsObjects,
        diseasesObjects,
      },
    }),
  uploadAppointmentFiles: (body) =>
    dispatch({
      type: appointmentActionsTypes.UPLOAD_APPOINTMENT_FILES,
      payload: { body },
    }),
  deleteAttachmentMessages: () =>
    dispatch({
      type: appointmentActionsTypes.DELETE_ATTACHMENT_MESSAGES,
    }),
  getPatients: (queryParams, queryValues) =>
    dispatch({
      type: userActionsTypes.GET_PATIENTS,
      payload: { queryParams, queryValues },
    }),
  deleteSchedule: (token, scheduleId, isMultiple) =>
    dispatch({
      type: appointmentActionsTypes.DELETE_SCHEDULE,
      payload: {
        token,
        scheduleId,
        isMultiple,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
