import {
  PROFILE,
  HISTORIC_APPOINTMENTS,
  APPOINTMENTS,
  ASSISTANTS_DOCTORS_APPOINTMENTS,
  USER_LIST,
  VIDEO_CALL_TEST,
} from '../../utils/urls';

const desktopTabs = [
  {
    label: 'HISTÓRICO DE CONSULTA',
    href: HISTORIC_APPOINTMENTS,
  },
  {
    label: 'MIS CONSULTAS',
    href: APPOINTMENTS,
  },
  {
    label: 'PROBAR VIDEO',
    href: VIDEO_CALL_TEST,
  },
];

const mobileOptions = [
  {
    label: 'Mis consultas',
    href: APPOINTMENTS,
  },
  {
    label: 'Histórico de consulta',
    href: HISTORIC_APPOINTMENTS,
  },

  {
    label: 'Probar video',
    href: VIDEO_CALL_TEST,
  },
];

const mobileOptionsMenuAssistant = [
  {
    label: 'Agendas',
    href: APPOINTMENTS,
  },
  {
    label: 'Lista de consulta',
    href: ASSISTANTS_DOCTORS_APPOINTMENTS,
  },
  {
    label: 'Lista de doctores',
    href: USER_LIST,
  },
  {
    label: 'Perfil Doctor',
    href: PROFILE,
  },
];

const profileOptions = [
  {
    label: 'Mi perfil',
    href: PROFILE,
  },
];

export {
  desktopTabs,
  mobileOptions,
  profileOptions,
  mobileOptionsMenuAssistant,
};
