export const doctorsActionsTypes = {
  GET_DOCTORS: 'GET_DOCTORS',
  GET_DOCTOR: 'GET_DOCTOR',
  SUCCESS_GET_DOCTORS: 'SUCCESS_GET_DOCTORS',
  SUCCESS_GET_DOCTOR: 'SUCCESS_GET_DOCTOR',
  ERROR: 'ERROR_DOCTORS',
  LOADING: 'LOADING_DOCTORS',
  SET_DOCTOR_BODY: 'SET_DOCTOR_BODY',
  CLEAR_SELECT_DOCTOR: 'CLEAR_SELECT_DOCTOR',
  LOGOUT: 'LOGOUT',
  SUCCESS_UPDATE_DOCTOR_ASSISTANT: 'SUCCESS_UPDATE_DOCTOR_ASSISTANT',
  // CLEAR_SUCCESS_MESSAGE: 'CLEAR_SUCCESS_MESSAGE',
  DELETE_MESSAGES: 'DELETE_MESSAGES',
  MANEGE_ADDRESS_DOCTOR: 'MANEGE_ADDRESS_DOCTOR',
  SUCCESS_CREATE_ADDRESS_DOCTOR: 'SUCCESS_CREATE_ADDRESS_DOCTOR',
  SUCCESS_DELETE_ADDRESS_DOCTOR: 'SUCCESS_DELETE_ADDRESS_DOCTOR'
};

export const doctorsActions = {
  successGetDoctors: (doctorsData) => ({
    type: doctorsActionsTypes.SUCCESS_GET_DOCTORS,
    payload: doctorsData,
  }),
  successGetDoctor: (doctorData) => ({
    type: doctorsActionsTypes.SUCCESS_GET_DOCTOR,
    payload: doctorData,
  }),
  error: (message) => ({
    type: doctorsActionsTypes.ERROR,
    payload: message,
  }),
  loading: (value) => ({
    type: doctorsActionsTypes.LOADING,
    payload: value,
  }),
  successUpdateDoctorAssistant: (data) => ({
    type: doctorsActionsTypes.SUCCESS_UPDATE_DOCTOR_ASSISTANT,
    payload: data,
  }),
  successCreateAddressDoctor: (data) => ({
    type: doctorsActionsTypes.SUCCESS_CREATE_ADDRESS_DOCTOR,
    payload: data
  }),
  successDeleteAddressDoctor: (data) => ({
    type: doctorsActionsTypes.SUCCESS_DELETE_ADDRESS_DOCTOR,
    payload: data
  }),
};
