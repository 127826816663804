import { useState, useRef } from 'react';
import {
  IconButton,
  MenuItem,
  Grow,
  MenuList,
  Popper,
  Paper,
  ClickAwayListener,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import {
  PROFILE,
  APPOINTMENTS,
  USER_LIST,
  ASSISTANTS_DOCTORS_APPOINTMENTS,
} from '../../utils/urls';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MenuAssistant = ({
  openModal,
  logout,
  classes,
  //   userName,
  //   profilePic,
  //   goProfile,
}) => {
  const menuOpen = useRef(null);
  const [open, openMenu] = useState(null);
  const history = useHistory();
  const [t, i18n] = useTranslation('global');

  return (
    <div
      className={classes.avatarContainer}
      onClick={() => openMenu(menuOpen.current)}
      style={{ marginRight: '2rem' }}
    >
      {/* <Avatar alt='Remy Sharp' src={profilePic} className={classes.avatar} /> */}
      <span>MENU</span>
      <IconButton
        style={{ color: 'white' }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        ref={menuOpen}
      >
        <ArrowDropDown fontSize="inherit" />
      </IconButton>
      <Popper
        open={open}
        anchorEl={menuOpen.current}
        transition
        disablePortal
        style={{ marginTop: '0.8rem' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => openMenu(null)}>
                <MenuList id="menu-list-grow">
                  <MenuItem
                    style={{
                      height: '3rem',
                      // width: '10rem',
                    }}
                    onClick={() => {
                      history.push(ASSISTANTS_DOCTORS_APPOINTMENTS);
                    }}
                  >
                    Lista de consultas
                  </MenuItem>
                  <div
                    style={{
                      width: '100%',
                      borderBottom: 'solid 1px #8080803b',
                    }}
                  />

                  <MenuItem
                    style={{ height: '3rem' }}
                    onClick={() => {
                      history.push({
                        pathname: USER_LIST,
                        state: {
                          list: 'doctors',
                        },
                      });
                    }}
                  >
                    {t('header.doctors-list')}
                  </MenuItem>
                  <div
                    style={{
                      width: '100%',
                      borderBottom: 'solid 1px #8080803b',
                    }}
                  />

                  <MenuItem
                    style={{ height: '3rem' }}
                    onClick={() => {
                      history.push(APPOINTMENTS);
                    }}
                  >
                    Agendas
                  </MenuItem>
                  <div
                    style={{
                      width: '100%',
                      borderBottom: 'solid 1px #8080803b',
                    }}
                  />

                  <MenuItem
                    style={{
                      height: '3rem',
                    }}
                    onClick={() => {
                      history.push({
                        pathname: PROFILE,
                        state: {
                          profileType: 'profileDoctor',
                        },
                      });
                    }}
                  >
                    {t('header.doctor-profile')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export { MenuAssistant };
