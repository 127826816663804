import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const DoctariSpinner = React.memo(({ loading }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress disableShrink />
    </div>
  );
});

export default DoctariSpinner;
