import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  FormControl,
  InputLabel,
  Box,
  Paper,
  Avatar,
  FormHelperText,
  Select,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { StarBorder } from '@material-ui/icons';
import DoctariModal from '../Modal';
import { capitalize, getSlotsRange } from '../../utils/helpers';
import { BASE_PAYMENT } from '../../utils/urls';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  formControl: {
    fontFamily: theme.typography.fontFamily,
    marginBottom: '1rem',
    width: '48%',
    marginRight: '1rem',
    '@media (max-width: 900px)': {
      width: '100%',
    },
  },
  paperContainer: {
    fontFamily: theme.typography.fontFamily,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.5rem',
    cursor: 'context-menu',
    marginTop: '1rem',
    /* "@media (max-width: 1440px)": {
      alignItems: "start",
      flexDirection: "column",
    }, */
    '@media screen and ( max-width: 320px)': {
      padding: '0.5rem',
    },
  },
  avatar: {
    border: `solid 2px ${theme.palette.lightPrimary.main}`,
    marginRight: '1rem',
    width: '5rem',
    height: '5rem',
    '@media (max-width: 900px)': {
      width: '4rem',
      height: '4rem',
      marginRight: '1.5rem',
    },
  },
  name: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '15px',
    color: theme.palette.primary.main,
  },
  description: {
    color: 'black',
    marginTop: '5px',
    fontWeight: 300,
    fontSize: '14px',
  },
  descriptionContainer: {
    width: '19.5rem',
    textAlign: 'center',
    '@media screen and (min-width: 1440px)': {
      width: 'auto',
      marginLeft: '6.2rem',
    },
    '@media screen and (max-width: 768px)': {
      width: '18.5rem',
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '5rem',
    '@media (max-width: 900px)': {
      height: 'auto',
      width: '16rem',
      marginTop: '1rem',
      flexDirection: 'row',
    },
  },
  buttonsContainer2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '5rem',
    '@media (max-width: 900px)': {
      height: 'auto',
      width: '13rem',
      marginTop: '1rem',
      flexDirection: 'row',
    },
  },
}));

function AgendaRequestCard({
  scheduleRequest,
  isPatient,
  updateScheduleRequest,
  acceptScheduleRequest,
  appointmentsData,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [t, i18n] = useTranslation('global');
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    if (appointmentsData.success.scheduleRequests) {
      history.push({
        pathname: `${BASE_PAYMENT}${appointmentsData.success.scheduleRequests.id}`,
        state: {
          appointmentDate: appointmentsData.success.scheduleRequests.date,
          doctorName:
            appointmentsData.success.scheduleRequests.doctor.full_name,
        },
      });
    }
  }, [appointmentsData.success.scheduleRequests]);

  const SuggestDate = () => {
    const [state, setState] = useState({
      date: null,
      hour: null,
    });

    const isEnable = () => {
      let isEnable = true;
      let obj = null;
      for (const prop in state) {
        obj = state[prop];
        if (prop === 'date') {
          const diffInDays = moment(obj).diff(moment(), 'days');
          if (diffInDays < 1) {
            return false;
          }
        } else {
          isEnable = isEnable && obj;
        }
      }
      return isEnable;
    };

    const { date, hour } = state;
    return (
      <Box
        style={{
          padding: '2rem',
          overflow: 'auto',
        }}
      >
        <Box display="flex">
          <FormControl className={classes.formControl}>
            <TextField
              id="date"
              label="Fecha"
              type="date"
              value={date}
              onChange={(e) => {
                setState({ ...state, date: e.target.value });
              }}
              InputLabelProps={{
                shrink: true,
              }}
              // onFocus={(e) => (e.target.type = 'date')}
              // onBlur={(e) => (e.target.type = !date ? 'text' : 'date')}
            />
            {moment(date).diff(moment(), 'days') < 1 && (
              <FormHelperText id="standard-adornment-password-text" error>
                La fecha de sugerencia debe ser al menos dos días en adelante.
              </FormHelperText>
            )}
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Hora *</InputLabel>
            <Select
              native
              value={hour}
              onChange={(e) => setState({ ...state, hour: e.target.value })}
            >
              <option aria-label="None" value="" />
              {getSlotsRange(scheduleRequest.doctor.slot).map((hour) => {
                return <option value={hour}>{hour}</option>;
              })}
            </Select>
          </FormControl>
        </Box>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => setModalOpen(false)}
            style={{ marginRight: '1rem' }}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              const body = {
                date_suggested: moment(`${date} ${hour}`).format(
                  'YYYY-MM-DD HH:mm'
                ),
                doctor: scheduleRequest.doctor.id,
              };
              updateScheduleRequest(body);
              setModalOpen(false);
            }}
            disabled={!isEnable()}
          >
            Sugerir
          </Button>
        </Grid>
      </Box>
    );
  };

  const DoctorState = () => {
    return (
      <Box>
        <DoctariModal
          open={modalOpen}
          setOpen={setModalOpen}
          title={'Sugerir una fecha'}
          hideCross
        >
          <SuggestDate />
        </DoctariModal>
        {scheduleRequest.status === 'waiting_patient' && (
          <Box>
            <span
              className={classes.description}
              style={{ fontWeight: 'bold' }}
            >
              {`Esperando respuesta`}
            </span>
          </Box>
        )}
        {scheduleRequest.status === 'waiting_doctor' && (
          <Box className={classes.buttonsContainer}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setModalOpen(true)}
            >
              {t('AgendaRequestCard.suggest-date')}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                const body = {
                  rejected: true,
                };
                updateScheduleRequest(body);
              }}
            >
              {t('AgendaRequestCard.decline')}
            </Button>
          </Box>
        )}
      </Box>
    );
  };

  const PatientState = () => {
    return (
      <Box>
        {scheduleRequest.status === 'waiting_doctor' && (
          <Box>
            <span
              className={classes.description}
              style={{ fontWeight: 'bold' }}
            >
              {`Estado: `}
            </span>
            <span className={classes.description}>Pendiente</span>
          </Box>
        )}
        {scheduleRequest.status === 'waiting_patient' && (
          <Box className={classes.buttonsContainer2}>
            <Button
              color="primary"
              variant="contained"
              onClick={acceptScheduleRequest}
            >
              Aceptar
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                const body = {
                  rejected: true,
                };
                updateScheduleRequest(body);
              }}
            >
              Rechazar
            </Button>
          </Box>
        )}
      </Box>
    );
  };
  return (
    <Paper className={classes.paperContainer}>
      {/* <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      > */}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid
          item
          xs={12}
          lg={12}
          md={9}
          xl={9}
          sm={9}
          container
          direction="row"
        >
          <Avatar alt="Imagen" src={null} className={classes.avatar} />
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            {isPatient && (
              <Rating
                name="customized-empty"
                defaultValue={scheduleRequest.doctor.score || 0}
                precision={0.1}
                emptyIcon={<StarBorder fontSize="inherit" />}
                readOnly
              />
            )}
            <span className={classes.name}>
              {isPatient
                ? scheduleRequest.doctor.full_name
                : scheduleRequest.patient.full_name}
            </span>
            {isPatient ? (
              <span
                className={classes.description}
                style={{ fontWeight: 'bold' }}
              >
                {scheduleRequest.doctor.specialty &&
                  scheduleRequest.doctor.specialty.name}
              </span>
            ) : (
              <>
                <span
                  className={classes.description}
                  style={{ fontWeight: 'bold' }}
                >
                  {t('AgendaRequestCard.age')}
                  <span className={classes.description}>
                    {moment().diff(
                      scheduleRequest.patient.date_of_birth,
                      'years'
                    )}
                  </span>
                </span>
              </>
            )}
            {scheduleRequest.status === 'waiting_patient' && (
              <>
                <span
                  className={classes.description}
                  style={{ fontWeight: 'bold' }}
                >
                  {`${capitalize(t('word.date'))} sugerida: `}
                  <span className={classes.description}>
                    {moment(scheduleRequest.date_suggested).format(
                      'DD-MM-YYYY HH:mm'
                    )}
                  </span>
                </span>
              </>
            )}
          </Box>
        </Grid>

        {/* </Box> */}
        <Grid item xs={12} sm={9} md={3} lg={12} xl={3}>
          {isPatient ? <PatientState /> : <DoctorState />}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default AgendaRequestCard;
