import { useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  Paper,
  Grid,
  Avatar,
  Input,
  TextField,
  FormControlLabel,
  Checkbox,
  CardHeader,
  FormControl,
  InputLabel,
  FormHelperText,
  InputAdornment,
} from '@material-ui/core';
import { useStyles } from './style';
import DoctariModal from '../../components/Modal';
import { DropzoneArea } from 'material-ui-dropzone';
import { connect } from 'react-redux';
import { APPOINTMENTS, BASE_PAYMENT, AGENDA } from '../../utils/urls';
import { userActionsTypes } from '../../redux/user/actions';
import { appointmentActionsTypes } from '../../redux/appointment/actions';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SearchOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/helpers';

function AppointmentVeterinary({
  getUser,
  createAppointmentLight,
  appointmentsData,
  session,
  userData,
  addUserInfo,
  deleteUserInfo,
  createInfo,
  uploadAppointmentFiles,
  deleteAttachmentMessages,
  getPatients,
  doctors,
  deleteSchedule,
  getPatientAnonymous,
  createPatientAnonymous,
  clearInformationAppointment,
  clearPatientAnonymous,
  getPetsPatient,
  createPet,
  updatePatientAnonymous,
}) {
  const history = useHistory();
  const location = useLocation();
  const styles = useStyles();
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [createAppointmentModalOpen, setCreateAppointmentModalOpen] =
    useState(false);
  const [checked, setChecked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [input, setInput] = useState('');
  const isAssistant = session.userType == 'assistant';
  const isOperator = session.userType == 'operator';
  const [identity_document, setIdentityDocument] = useState(null);
  const [isExistPatient, setIsExistPatient] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dataPatient, setDataPatient] = useState({
    name: null,
    lastName: null,
    country: null,
    document: null,
    email: null,
    phone: null,
    password: null,
    password2: null,
    dateOfBirth: moment().add(-18, 'years').format('YYYY-MM-DD'),
    termsAndConditions: false,
    city: null,
    gender: null,
    petName: null,
    petDateOfBirth: null,
  });

  const {
    name,
    lastName,

    phone,

    email,

    petName,
    petDateOfBirth,
  } = dataPatient;

  const [patientSelected, setPatient] = useState({
    patient: null,
    medications: null,
    allergies: null,
    diseases: null,
  });

  const [patientAllergies, setPatientAllergies] = useState([]);
  const [t, i18n] = useTranslation('global');

  const [showPetForm, setShowPetForm] = useState(false);
  const [petData, setPetData] = useState({});
  const [isAddPet, setIsAddPet] = useState(false);

  const doctorsAvailableForAppointment =
    location.state.doctorsAvailableForAppointment;

  const { user, medications, allergies, diseases, petsPatient } = userData;

  const goPayment = () => {
    history.push({
      pathname: `${BASE_PAYMENT}${appointmentsData.appointmentCreated.id}`,
      state: {
        appointmentDate: location.state.schedule.start,
        doctorName: location.state.schedule.doctor.full_name,
      },
    });
    return () => deleteAttachmentMessages();
  };
  // when the appointment files are added  we go to payment page and pass the appointment id
  useEffect(() => {
    if (appointmentsData.success.uploadFiles && buttonClicked && !isAssistant) {
      goPayment();
    } else if (isAssistant && buttonClicked) {
      history.push(AGENDA);
    }
  }, [appointmentsData.success.uploadFiles]);
  // when the appointment is created we upload the attachments
  useEffect(() => {
    if (
      appointmentsData.success.genericMessage ==
        'Appointment creado con exito' &&
      buttonClicked
    ) {
      if (attachments.length == 0 && !isAssistant) {
        goPayment();
      } else if (attachments.length > 0) {
        const formDataAppointment = new FormData();
        for (let i = 0; i < attachments.length; i++) {
          formDataAppointment.append(`file${i}`, attachments[i]);
        }
        const body = {
          files: formDataAppointment,
          appointmentId: appointmentsData.appointmentCreated.id,
        };
        uploadAppointmentFiles(body);
      }
      setLoading(false);
      // setCreateAppointmentModalOpen(true);
      history.push(APPOINTMENTS);
    }
  }, [appointmentsData.success.genericMessage]);

  useEffect(() => {
    getUser(session.userType, session.id);

    return () => {
      clearInformationAppointment();
      clearPatientAnonymous();
    };
  }, []);

  useEffect(() => {
    if (!isAssistant && userData.user) {
      setPatient({
        patient: userData.user,
        medications: userData.user.medications,
        allergies: userData.user.allergies,
        diseases: userData.user.diseases,
      });
    }
  }, [userData.user]);

  useEffect(() => {
    if (userData.patient_anonymous) {
      setIsExistPatient(true);
      setDataPatient({
        name: userData.patient_anonymous.first_name,
        lastName: userData.patient_anonymous.last_name,
        phone: userData.patient_anonymous.telephone,
        email: userData.patient_anonymous.email,
        // dateOfBirth: moment(
        //   userData.patient_anonymous.date_of_birth
        // ).format('YYYY-MM-DD'),
        // gender: userData.patient_anonymous.gender,
      });
      // get pets according to patient selected
      getPetsPatient(userData.patient_anonymous.id);
    } else if (userData.patient_anonymous == null) {
      setIsExistPatient(false);
    }
  }, [userData.patient_anonymous]);

  useEffect(() => {
    if (userData.success === 'Paciente anonimo creado con exito') {
      if (petName && petDateOfBirth) {
        handleCreatePet();
      }
      handleCreateAppointmentLight();
    }
  }, [userData.success]);

  const getUrlAndId = (type, isForDelete) => {
    let dataType = {
      id: 'medication_id',
      url: isForDelete ? 'delete_medication' : 'patient_medication',
    };
    if (type === 'diseases') {
      dataType = {
        id: 'disease_id',
        url: isForDelete ? 'delete_disease' : 'patient_disease',
      };
    } else if (type === 'allergies') {
      dataType = {
        id: 'allergie_id',
        url: isForDelete ? 'delete_allergie' : 'patient_allergie',
      };
    }
    return dataType;
  };

  const handleUserMedication = (isNew, med, isForDelete = false) => {
    const infoToUpdate = 'medications';
    const data = getUrlAndId(infoToUpdate, isForDelete);
    if (!isForDelete) {
      if (isNew) {
        createInfo(infoToUpdate, { name: med.name }, data, isAssistant);
      } else {
        addUserInfo(infoToUpdate, med);
      }
    } else {
      deleteUserInfo(infoToUpdate, med.id);
    }
  };

  const handleUserDisease = (isNew, disease, isForDelete = false) => {
    const infoToUpdate = 'diseases';
    const data = getUrlAndId(infoToUpdate, isForDelete);
    if (!isForDelete) {
      if (isNew) {
        createInfo(infoToUpdate, { name: disease.name }, data, isAssistant);
      } else {
        addUserInfo(infoToUpdate, disease);
      }
    } else {
      deleteUserInfo(infoToUpdate, disease.id);
    }
  };

  const handleUserAllergie = (isNew, allergie, isForDelete = false) => {
    const infoToUpdate = 'allergies';
    const data = getUrlAndId(infoToUpdate, isForDelete);
    if (!isForDelete) {
      if (isNew) {
        createInfo(infoToUpdate, { name: allergie.name }, data, isAssistant);
      } else {
        addUserInfo(infoToUpdate, allergie);
      }
    } else {
      deleteUserInfo(infoToUpdate, allergie.id);
    }
  };

  const addInformation = {
    handleUserMedication,
    handleUserAllergie,
    handleUserDisease,
  };

  const handleAttachmentsChange = (files) => {
    setAttachments(files);
  };

  const handleInputCHange = (e) => {
    setInput(e.target.value);
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleCreateAppointmentLight = () => {
    let doctorId =
      isAssistant || isOperator
        ? doctors.selectedDoctor.id
        : location.state.schedule.doctor.id;
    let scheduleId = location.state.schedule.id;
    setButtonClicked(true);
    createAppointmentLight(
      scheduleId,
      input,
      doctorId,
      userData.patient_anonymous.id,
      session.accessToken,
      [], // these empty arrays are used to specify allergies,medications and diseases
      [],
      []
    );
  };

  const handleCreatePet = () => {
    let body = {
      full_name: petName,
      date_of_birth: petDateOfBirth,
      owner: userData.patient_anonymous.id,
    };
    createPet(body);
  };

  const handleSetPatientInfo = (value) => {
    if (value == null) {
      setPatient({
        patient: null,
        medications: null,
        allergies: null,
        diseases: null,
      });
    } else {
      setPatient({
        patient: value,
        medications: value.medications,
        allergies: value.allergies,
        diseases: value.diseases,
      });
    }
  };

  const handleCancelAppointment = () => {
    if (isAssistant || isOperator) {
      history.push({
        pathname: AGENDA,
        state: {
          specialtyType: location.state.appointmentType,
          specialtyId: location.state.specialtyId,
          doctorsSpecialty: location.state.professionals,
        },
      });
    } else {
      history.push(APPOINTMENTS);
    }
  };

  const handleGetPatientAnonymous = (e) => {
    e.preventDefault();
    if (identity_document) {
      getPatientAnonymous(identity_document);
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handlePatientAnonymous = (update = false) => {
    const body = {
      email,
      first_name: name,
      last_name: lastName,
      identity_document: identity_document,
      // date_of_birth: moment(dateOfBirth).format('YYYY-MM-DD'),
      // gender,
      telephone: phone,
    };

    if (update) {
      updatePatientAnonymous(dataPatient.id, body);
    } else {
      createPatientAnonymous(body);
    }
  };

  return (
    <>
      <DoctariModal
        open={attachmentModalOpen}
        setOpen={(val) => setAttachmentModalOpen(val)}
        title={`${capitalize(t('word.attach'))} ${t('word.studies')}`}
      >
        <DropzoneArea
          useChipsForPreview={true}
          dropzoneText={`${t('sentence.attachedFileModal')}`}
          onChange={(files) => handleAttachmentsChange(files)}
          onDelete={(file) => console.log(file)}
          clearOnUnmount={false}
          filesLimit={3}
          initialFiles={attachments}
          getFileAddedMessage={(file) => `${file} añadido`}
          getFileRemovedMessage={(file) => `${file} eliminado`}
          getFileLimitExceedMessage={(limit) =>
            `${t('sentence.attachedFileModal2')} ${limit} ${t('word.files')}`
          }
        />
        <Button
          style={{ float: 'right', margin: '10px' }}
          variant='contained'
          color='primary'
          disabled={!attachments.length}
          onClick={() => setAttachmentModalOpen(false)}
        >
          {`${capitalize(t('word.accept'))}`}
        </Button>
        <Button
          style={{ float: 'right', margin: '10px' }}
          onClick={() => {
            setAttachments([]);
            setAttachmentModalOpen(false);
          }}
          variant='outlined'
          color='primary'
        >
          Cancelar
        </Button>
      </DoctariModal>

      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        <Grid
          item
          xs={12}
          md={12}
          justifyContent='space-between'
          className={styles.menuContainer}
        >
          <h2 className={styles.title}>{`${capitalize(
            t('word.create')
          )} consulta`}</h2>
          {!isAssistant && !isOperator && doctors.selectedDoctor && (
            <CardHeader
              avatar={
                <Avatar
                  alt='Foto de perfil'
                  src={
                    location.state.schedule.doctor.profile_picture
                      ? location.state.schedule.doctor.profile_picture.url
                      : null
                  }
                  className={styles.avatar}
                />
              }
              title={location.state.schedule.doctor.full_name}
              subheader={location.state.schedule.doctor.specialty.name}
            />
          )}
        </Grid>
        <Grid item className={styles.formConteiner}>
          <Paper
            elevation={8}
            style={{ padding: '40px 40px', marginBottom: '5rem' }}
          >
            <Grid item container spacing={2} className={styles.testContainer}>
              <Grid className={styles.firstPaperContainer}>
                <p>
                  <b>{`${t('Appointment.appointment-date')}`}</b>
                  {` ${moment(location.state.schedule.start).format(
                    'DD-MM-YYYY HH:mm [hs]'
                  )}`}
                </p>
              </Grid>
              <Grid item md={12} xs={12} style={{ padding: '0' }}>
                <h3>{`${capitalize(t('word.data'))} ${t('prep.of-1')} ${t(
                  'word.owner'
                )}`}</h3>
              </Grid>

              <Grid
                item
                md={doctors.selectedDoctor ? 12 : 6}
                xs={12}
                className={styles.paperGridItems}
              >
                <form onSubmit={handleGetPatientAnonymous}>
                  <FormControl
                    className={styles.formControl}
                    error={identity_document === ''}
                  >
                    <TextField
                      id='my-input'
                      // label="Ingrese documento del paciente *"
                      // type="date"
                      placeholder={`${capitalize(
                        t('word.enter')
                      )} documento ${t('prep.of-1')} paciente *`}
                      variant='standard'
                      defaultValue={identity_document}
                      onChange={(e) => setIdentityDocument(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <IconButton
                              className={styles.iconSearchButton}
                              onClick={handleGetPatientAnonymous}
                            >
                              <SearchOutlined />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      // error={!isOlderThan18(dateOfBirth)}
                    />
                  </FormControl>
                </form>
              </Grid>

              <Grid item md={6} xs={12} className={styles.paperGridItems}>
                <FormControl
                  className={styles.formControl}
                  error={name === ''}
                  disabled={isExistPatient}
                >
                  <InputLabel htmlFor='my-input'>{`${t(
                    'PersonalData.name'
                  )}`}</InputLabel>
                  <Input
                    id='my-input'
                    aria-describedby='my-helper-text'
                    onChange={(e) =>
                      setDataPatient({ ...dataPatient, name: e.target.value })
                    }
                    value={name || ''}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12} className={styles.paperGridItems}>
                <FormControl
                  className={styles.formControl}
                  error={lastName === ''}
                  disabled={isExistPatient}
                >
                  <InputLabel htmlFor='my-input'>{`${t(
                    'PersonalData.last-name'
                  )}`}</InputLabel>
                  <Input
                    id='my-input'
                    aria-describedby='my-helper-text'
                    onChange={(e) =>
                      setDataPatient({
                        ...dataPatient,
                        lastName: e.target.value,
                      })
                    }
                    value={lastName || ''}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12} className={styles.paperGridItems}>
                <FormControl
                  className={styles.formControl}
                  disabled={isExistPatient}
                >
                  <InputLabel htmlFor='my-input-phone'>
                    {`${t('PersonalData.phone')}`}
                  </InputLabel>
                  <Input
                    id='my-input-phone'
                    aria-describedby='my-helper-text'
                    onChange={(e) =>
                      setDataPatient({ ...dataPatient, phone: e.target.value })
                    }
                    value={phone || ''}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12} className={styles.paperGridItems}>
                <FormControl
                  className={styles.formControl}
                  error={email && !validateEmail(email)}
                  disabled={isExistPatient}
                >
                  <InputLabel htmlFor='my-input'>Email *</InputLabel>
                  <Input
                    id='my-input'
                    aria-describedby='my-helper-text'
                    onChange={(e) =>
                      setDataPatient({ ...dataPatient, email: e.target.value })
                    }
                    value={email || ''}
                    required
                  />
                  {email && !validateEmail(email) && (
                    <FormHelperText id='standard-adornment-password-text' error>
                      Formato inválido
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12} style={{ padding: '0' }}>
                <h3>{`${capitalize(t('word.data'))} ${t('prep.of-the')} ${t(
                  'word.pet'
                )}`}</h3>
              </Grid>
              {!isAddPet && (
                <>
                  <Grid item md={6} xs={12} className={styles.paperGridItems}>
                    <FormControl
                      className={styles.formControl}
                      error={petData == null}
                    >
                      <Autocomplete
                        disablePortal
                        id='combo-box-demo'
                        noOptionsText={`${capitalize(t('word.no'))} ${t(
                          'word.there-are'
                        )} ${t('word.pet')} para este paciente`}
                        options={petsPatient ? petsPatient : []}
                        getOptionLabel={(option) => option.full_name}
                        sx={{ width: 300 }}
                        onChange={(event, value) => {
                          setShowPetForm(true);
                          setPetData(value);
                        }}
                        value={petData}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${capitalize(t('word.their'))} ${t(
                              'word.pet'
                            )}`}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    className={styles.paperGridItems}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Button
                      variant='contained'
                      className={styles.buttons}
                      color='primary'
                      onClick={() => {
                        setPetData(null);
                        setShowPetForm(true);
                        setIsAddPet(true);
                      }}
                      disabled={!identity_document}
                    >
                      {`${capitalize(t('word.add'))} ${t('word.pet')}`}
                    </Button>
                  </Grid>
                </>
              )}
              {showPetForm && (
                <>
                  <Grid item md={6} xs={12} className={styles.paperGridItems}>
                    <FormControl
                      className={styles.formControl}
                      error={petName === ''}
                      disabled={petData}
                    >
                      <InputLabel htmlFor='my-input'>
                        {`${capitalize(t('word.name'))} ${t('prep.of-the')} ${t(
                          'word.pet'
                        )} *`}
                      </InputLabel>
                      <Input
                        id='my-input'
                        aria-describedby='my-helper-text'
                        onChange={(e) =>
                          setDataPatient({
                            ...dataPatient,
                            petName: e.target.value,
                          })
                        }
                        value={petData ? petData.full_name : petName || ''}
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12} className={styles.paperGridItems}>
                    <FormControl className={styles.formControl}>
                      <TextField
                        id='date'
                        label={`${capitalize(t('word.date'))} de ${t(
                          'word.birth'
                        )} ${t('prep.of-the')} ${t('word.pet')}`}
                        type='date'
                        value={petData ? petData.date_of_birth : petDateOfBirth}
                        onChange={(e) =>
                          setDataPatient({
                            ...dataPatient,
                            petDateOfBirth: e.target.value,
                          })
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={moment().format('DD-MM-YYYY')}
                        inputFormat='DD-MM-YYYY'
                        disabled={petData}
                        // error={!isOlderThan18(dateOfBirth)}
                      />
                      {/* {!isOlderThan18(dateOfBirth) && (
                    <FormHelperText error>
                      Debe ser mayor de 18 años
                    </FormHelperText>
                  )} */}
                    </FormControl>
                  </Grid>
                </>
              )}

              <Grid item md={12} xs={12}>
                <TextField
                  id='standard-basic'
                  label={`${t('Appointment.appointment-reason')}`}
                  variant='standard'
                  style={{ width: '100%' }}
                  className={styles.paperGridItems}
                  onChange={handleInputCHange}
                  // placeholder="Escriba aquí los sintomas que tiene y el motivo de la consulta (Max: 500 caracteres)"
                />
              </Grid>

              <div className={styles.forthtPaperContainer}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        checked={checked}
                        onChange={handleCheckChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={`${t('Appointment.agreement')}`}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant='outlined'
                    className={styles.buttons}
                    color='primary'
                    onClick={handleCancelAppointment}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant='contained'
                    className={styles.buttons}
                    color='primary'
                    disabled={
                      !checked ||
                      !name ||
                      !lastName ||
                      !validateEmail(email) ||
                      !identity_document ||
                      !phone ||
                      ((isAssistant || isOperator) &&
                        !doctors.selectedDoctor) ||
                      (petData
                        ? !petData.full_name
                        : !petName || !petDateOfBirth)
                    }
                    onClick={() => {
                      setLoading(true);
                      handlePatientAnonymous();
                    }}
                  >
                    {loading
                      ? `${t('word.loading')}`
                      : `${t('word.create')} consulta`}
                  </Button>
                </Grid>
              </div>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ session, user, appointments, doctors }) => {
  return {
    session,
    userData: user,
    appointmentsData: appointments,
    doctors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUser: (type, id, token) =>
    dispatch({
      type: userActionsTypes.GET_USER,
      payload: { type, id },
    }),
  addUserInfo: (type, newValue) =>
    dispatch({
      type: appointmentActionsTypes.ADD_INFORMATION_APPOINTMENT,
      payload: { type, newValue },
    }),
  createInfo: (type, body, data, isAssistant) =>
    dispatch({
      type: userActionsTypes.CREATE_INFORMATION,
      payload: { type, body, data, isAssistant },
    }),
  deleteUserInfo: (type, id) =>
    dispatch({
      type: appointmentActionsTypes.DELETE_INFORMATION_APPOINTMENT,
      payload: { type, id },
    }),
  createAppointmentLight: (
    scheduleId,
    reason,
    doctor,
    patient,
    token,
    allergiesObjects,
    medicationsObjects,
    diseasesObjects
  ) =>
    dispatch({
      type: appointmentActionsTypes.CREATE_APPOINTMENT_LIGHT,
      payload: {
        scheduleId,
        reason,
        doctor,
        patient,
        token,
        allergiesObjects,
        medicationsObjects,
        diseasesObjects,
      },
    }),
  uploadAppointmentFiles: (body) =>
    dispatch({
      type: appointmentActionsTypes.UPLOAD_APPOINTMENT_FILES,
      payload: { body },
    }),
  deleteAttachmentMessages: () =>
    dispatch({
      type: appointmentActionsTypes.DELETE_ATTACHMENT_MESSAGES,
    }),
  getPatients: (currentPage) =>
    dispatch({ type: userActionsTypes.GET_PATIENTS, payload: { currentPage } }),
  deleteSchedule: (token, scheduleId, isMultiple) =>
    dispatch({
      type: appointmentActionsTypes.DELETE_SCHEDULE,
      payload: {
        token,
        scheduleId,
        isMultiple,
      },
    }),
  getPatientAnonymous: (id) =>
    dispatch({
      type: userActionsTypes.GET_PATIENT_ANONYMOUS,
      payload: { id },
    }),
  createPatientAnonymous: (body) =>
    dispatch({
      type: userActionsTypes.CREATE_PATIENT_ANONYMOUS,
      payload: { body },
    }),
  clearInformationAppointment: () =>
    dispatch({
      type: appointmentActionsTypes.CLEAR_INFORMATIONS_APPOINTMENT,
    }),
  clearPatientAnonymous: () =>
    dispatch({
      type: userActionsTypes.CLEAR_PATIENT_ANONYMOUS,
    }),
  getPetsPatient: (idPatient) =>
    dispatch({
      type: userActionsTypes.GET_PETS_PATIENT,
      payload: { idPatient },
    }),

  createPet: (body) =>
    dispatch({
      type: userActionsTypes.CREATE_PETS_PATIENT,
      payload: { body },
    }),

  updatePatientAnonymous: (id, body) => {
    dispatch({
      type: userActionsTypes.UPDATE_PATIENT_ANONYMOUS,
      payload: { id, body },
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentVeterinary);
